import { Menu } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const AntMenu = styled(Menu)`
  display: block;
  height: 65px;
  border-right: 0;
  text-align: right;
  line-height: 40px;
  width: 100%;
`;

export const CustomAvatar = styled.ul`
  li {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const CreditsQtd = styled.span`
  color: ${props => props.theme.color2};
  font-size: 12px;
`;

export const MenuGlobalStyle = createGlobalStyle`
  
  .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    margin-right: 0px;
  }

  .ant-layout-header .ant-menu {
    line-height: normal;
  }

  .ant-menu-horizontal>.ant-menu-item, .ant-menu-horizontal>.ant-menu-submenu {
    top: 15px;
  }

  #menu-notifications {
    padding: 0;
  }

  #btnLogout,
  #btnProfile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: white;

    &:hover {
      background-color: transparent;
    }

    svg {
      &:first-child {
        margin-left: 0px;
      }
    }

    span.btn-text  {
      color: rgba(0,0,0,.85);
  
      span {
        &:first-child {
          margin-left: 0px;
          margin-right: 14px;
        }
      }
    }
  }

  #headerMenu {
    background: transparent;
  }

  .ant-menu-item > button  {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;

    &:hover {
      background-color: transparent;
    }

    svg {
      &:first-child {
        margin-left: 0px;
      }
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d7e0d3;
  }

  .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
    border-right: 3px solid ${props => props.theme.color2};
  }

  .ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: ${props => props.theme.color2};
  }

  ::selection {
    color: #fff;
    background: ${props => props.theme.color2};
  }

  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: ${props => props.theme.color2};
  }
`;