import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(90deg, #3F3F3F 0%, #4F4F4F 50%, #3F3F3F 100%);
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
`;
