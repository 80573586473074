import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Formik } from 'formik';
import { Select, Input, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import { useSelector } from 'react-redux';
import { FormFilter } from './styles';
import { format } from 'date-fns';
import { paginationPlans } from '~/services/hooks/plans';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;
  // if is not possible to use useSelector from redux, use '' as default value
  const { type } = useSelector(state => state.user.profile) || '';
  const [packages, setPackages] = useState([]);

  const handleSearch = values => {
    const filter = {};
    try {
      if (values.statusFilter !== undefined && values.statusFilter !== null && values.statusFilter !== '') {
        filter.status = values.statusFilter;
      }

      if (values.name !== undefined && values.name !== null && values.name !== '') {
        filter.userName = values.name;
      }

      if (values.nameFilter !== undefined && values.nameFilter !== null && values.nameFilter !== '') {
        filter.packageName = values.nameFilter;
      }
      if (values.dataFilter !== undefined && values.dataFilter !== null && values.dataFilter !== '') {
        filter.data = format(new Date(values.dataFilter), 'yyyy-MM-dd');
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  const status = [
    {
      id: 0,
      code: 'approved',
      description: 'Concluído',
    },
    {
      id: 1,
      code: 'expired',
      description: 'Expirado',
    },
    {
      id: 2,
      code: 'pending',
      description: 'Pendente',
    },
    {
      id: 3,
      code: 'cancelled',
      description: 'Cancelado',
    },
  ];

  const fetchData = async page => {
    const data = await paginationPlans(page, {});
    setPackages(data.data);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <Box>
      <Formik
        initialValues={{ nameFilter: '', name: '', dataFilter: '', statusFilter: 'Todos', valueFilter: '0' }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <FormFilter>
              <Row>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 12, lg: 9, xl: 6, xxl: 5 }}
                  error={errors.nameFilter}
                  field="nameFilter"
                  label={t('screens:finances.data.name')}
                >
                  <Select name="nameFilter">
                    {packages.map(item => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                {type === 'ADMIN' && (
                  <FormControl
                    cols={{ xs: 24, sm: 24, md: 12, lg: 10, xl: 8, xxl: 5 }}
                    error={errors.name}
                    field="name"
                    label={t('screens:finances.data.nameUser')}
                  >
                    <Input name="name" placeholder={t('screens:finances.name.placeholder')} />
                  </FormControl>
                )}
                <FormControl
                  cols={{ xs: 24, sm: 12, md: 8, lg: 5, xl: 4, xxl: 3 }}
                  error={errors.dataFilter}
                  field="dataFilter"
                  label={t('screens:finances.data.date')}
                  placeholder="Seleciona a data"
                >
                  <DatePicker
                    name="dataFilter"
                    format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']}
                    placeholder="Seleciona a data"
                  />
                </FormControl>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 16, lg: 8, xl: 6, xxl: 4 }}
                  error={errors.statusFilter}
                  field="statusFilter"
                  label={t('screens:finances.data.status')}
                >
                  <Select name="statusFilter">
                    {status.map(item => {
                      return (
                        <Option key={item.id} value={item.code}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>

                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </FormFilter>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
