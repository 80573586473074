import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'package';

// PAGINATION
export const paginationPlans = async (page, filters) => {
  try {
    const { data } = await api.get(`/${endPoint}`, {
      params: {
        ...{ page },
        ...filters,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findByIdPlan = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/${id}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createPlan = async values => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success(i18next.t(`messages:plans.success`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicatePlans') {
      message.error(i18next.t('messages:duplicatedPlan'));
    } else {
      errorHandler(error);
    }
  }
};

// UPDATE
export const updatePlan = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/${values.id}`, values);
    message.success(i18next.t(`messages:plans.successEdit`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicatePlans') {
      message.error(i18next.t('messages:duplicatedPlan'));
    } else {
      errorHandler(error);
    }
  }
};

// DELETE
export const deletePlan = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:plans.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationPlans.propTypes = {
  page: PropTypes.number.isRequired,
  filters: PropTypes.object,
};

findByIdPlan.propTypes = {
  id: PropTypes.string.isRequired,
};

createPlan.propTypes = {
  values: PropTypes.object.isRequired,
};

updatePlan.propTypes = {
  values: PropTypes.object.isRequired,
};

deletePlan.propTypes = {
  id: PropTypes.string.isRequired,
};
