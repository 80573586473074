import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Breadcrumb, Tooltip, Col } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import UserInfo from '~/components/UserInfo';
import { Table, TableActions } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';
import { deleteCustomer, exportCustomer, paginationCustomers, updateCustomer } from '~/services/hooks/customers';
import Filter from './filter';
import history from '~/services/history';
import { Switch } from 'antd';
import Row from '~/components/Row';

export default function Customers() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationCustomers(page, filters);
    setMeta(data.meta);
    data.data.map(item => (item.cadastro = new Date(item.address.created_at).toLocaleDateString()));
    setTableData(data.data);
    setLoading(false);
  };

  const handleDelete = async id => {
    await deleteCustomer(id);
    fetchData(1);
  };

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    if (sorter.field !== sortBy || sortOrder !== sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const handlePause = async record => {
    record.status = !record.active;
    record.status = record.status ? 1 : 0;
    const data = await updateCustomer(record);
    onUpdateItem(data);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortBy]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { backgroundColor: '#52c41a' },
    inactive: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
    switch: { marginTop: '8px' }
  };

  const tableColumns = [
    {
      title: t('screens:customers.data.id'),
      dataIndex: 'id',
      key: 'id',
      responsive: ['lg', 'xl', 'xxl'],
      width: '50px',
      align: 'center',
      className: 'table-id',
    },
    {
      title: t('screens:finances.data.user'),
      key: 'user',
      align: 'left',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      ellipsis: { showTitle: true },
      render: record => {
        return <UserInfo user={record} />;
      },
    },
    {
      title: t('screens:customers.data.cpfCnpj'),
      key: 'cpfCnpj',
      responsive: ['lg', 'xl', 'xxl'],
      width: '150px',
      render: record => {
        if (record.user_type === 'pf') {
          return <p>{record?.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</p>;
        } else {
          return <p>{record?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}</p>;
        }
      },
    },
    {
      title: t('screens:customers.data.cadastro'),
      dataIndex: 'cadastro',
      key: 'cadastro',
      align: 'center',
      responsive: ['lg', 'xl', 'xxl'],
      width: '130px',
    },
    {
      title: t('screens:customers.data.actions'),
      key: 'actions',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '110px',
      render: (text, record) => (
        <TableActions>
          <Switch size="small" style={style.switch} checked={record.active} onChange={() => handlePause(record)} />
          <Button
            style={style.tableAction}
            onClick={() => history.push(`/customers/${record.id}`)}
            title={t('messages:edit')}
          >
            <EditOutlined />
          </Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button style={style.tableAction} title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
    //Mobile
    {
      title: t('screens:customers.title'),
      key: 'actions',
      align: 'center',
      width: '10px',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <p>
            {record.name}
            <br />
            <Tooltip placement="top" title={record.user_type === 'pf' ? t('screens:customers.data.cpf') : t('screens:customers.data.cnpj')}>
              <span style={{ color: 'gray' }}>
                { record.user_type === 'pf' ?
                  <p>{record?.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</p>
                :
                  <p>{record?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}</p>
                }
              </span>
            </Tooltip>
            <Tooltip placement="top" title={t('screens:customers.data.cadastro')}>
              <span style={{ color: 'gray' }}>{record.cadastro}</span>
            </Tooltip>
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              <Switch size="small" style={style.switch} checked={record.active} onChange={() => handlePause(record)} />
              <Button
                style={style.tableAction}
                onClick={() => history.push(`/customers/${record.id}`)}
                title={t('messages:edit')}
              >
                <EditOutlined />
              </Button>

              <Popconfirm
                icon={false}
                title={t('messages:confirmDelete')}
                onConfirm={() => handleDelete(record.id)}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button style={style.tableAction} title={t('messages:delete')}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </TableActions>
          </div>
        </div>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:customers')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:customers.title')} style={style.pageTitle} />
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                exportCustomer(filters);
              }}
              style={style.btnNew.btn}
            >
              <DownloadOutlined />
              {t('screens:customers.btnExport')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
    </DefautLayout>
  );
}
