import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import {
  Spin,
  Col,
  Typography,
  Breadcrumb,
  Card,
  List,
  Descriptions,
  Image,
  message,
  Table,
  Alert,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import './style.css';
import FormControl from '~/components/Form/FormControl';
import DefaultLayout from '../_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import { BreadcrumbCustom } from '~/styles/global';
import Button from '~/components/Button';
import { createConsulta } from '~/services/hooks/consultas';
import { useDispatch, useSelector } from 'react-redux';
import { findUser } from '~/services/hooks/users';
import { updateProfile } from '~/store/modules/user/actions';
import { Container } from './styles';
import { PlusOutlined } from '@ant-design/icons';

export default function ConsultasNew() {
  const { Title } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);
  const [credits, setCredits] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState();
  const [expired, setExpired] = useState(false);
  const options = [
    'Morfologia Nr Chassi 1',
    'Morfologia Nr Chassi 2',
    'Morfologia Nr Motor',
    'Plaqueta Chassi 1',
    'Plaqueta Chassi 2',
    'Morfologia Nr Cambio',
    'Morfologia Gravacao Vidros',
    'Etiquetas destrutiveis',
    'Etiquetas destrutiveis',
    'Morfologia Gravacao Vidros',
  ];
  const [selectedImages, setSelectedImages] = useState({});
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fullScreen, setFullScreen] = useState('');

  const handleImageChange = (event, item) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImages(prevState => ({
        ...prevState,
        [item.ParteVeiculo]: reader.result,
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const [resultData, setResultData] = useState({
    id: null,
    user_id: '',
    order_id: '',
    license_plate: '',
    chassis: '',
    credits_used: 0,
    status: '',
    result: {
      Identificacao: {
        NomeDaTransacao: '',
        DescricaoDaTransacao: '',
        VersaoDaTransacao: '',
        DataDaPublicacao: '',
        Autor: '',
      },
      Controle: {
        Cliente: [],
        Login: [],
        PlacaDeEntrada: '',
        ChassiDeEntrada: '',
        IPdoComputadorConsumidor: '',
        NomeDoComputadorProvedor: '',
        CodigoDeControle: '',
        Severidade: '',
        Descricao: '',
        AcaoAdotada: '',
        ResultadoDaPesquisa: '',
        GravacaoDoLog: '',
        DataExecucao: '',
        NrTransacao: '',
        Observacao: [],
      },
      Resposta: [],
    },
    updated_at: '',
    created_at: '',
  });

  const fetchCredits = async () => {
    setLoading(true);

    let data = await findUser();
    setCredits(
      data?.calculo_dias['totals']?.creditsRemainAllPackages
        ? data?.calculo_dias['totals']?.creditsRemainAllPackages
        : 0
    );

    dispatch(updateProfile(data));
    data = data?.calculo_dias[0];
    profile.calculo_dias = data;

    if (data?.orderStatus === 'expired') {
      setExpired(true);
    }

    setRecordData(data);
    setLoading(false);
  };
  
  const handleSave = async values => {
    try {
      setLoading(true);
      values.orderId = recordData.orderId;
      const data = await createConsulta(values);
      const list = [];

      list.push(data.result.Resposta.struct_Resposta[0]);
      let i = 1;
      while (
        data.result.Resposta.struct_Resposta[i].ParteVeiculo !== data.result.Resposta.struct_Resposta[0].ParteVeiculo
      ) {
        list.push(data.result.Resposta.struct_Resposta[i]);
        i++;
        if (i === data.result.Resposta.struct_Resposta.length) break;
      }
      data.result.Resposta.struct_Resposta = list;

      setCredits(credits - data.credits_used);

      setResultData(data);
      setIsVisible(false);
    } catch (error) {
      console.error('Error while saving:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userSchema = Yup.object()
    .shape({
      licensePlate: Yup.string(),
      motor: Yup.string(),
      chassis: Yup.string()
        .min(10, 'O chassis deve ter no mínimo 10 caracteres')
        .max(17, 'O chassis deve ter no máximo 17 caracteres'),
    })
    .test({
      test: value => {
        const { licensePlate, chassis, motor } = value;
        // Verifica se pelo menos um dos campos está preenchido
        if (!licensePlate && !chassis && !motor) {
          message.error('Pelo menos um dos campos (placa ou chassi) deve ser preenchido');
          throw new Yup.ValidationError(
            'Pelo menos um dos campos (placa ou chassi) deve ser preenchido',
            null,
            'userSchema' // Caminho do erro (opcional)
          );
        }
        return true;
      },
    });

  const showInfoTable = responseList => {
    const data = [
      {
        key: responseList[0].Modelo,
        TipoVeiculo: responseList[0].TipoVeiculo,
        Marca: responseList[0].Marca,
        Modelo: responseList[0].Modelo,
        AnoFabricacao: responseList[0].AnoFabricacao,
        AnoModelo: responseList[0].AnoModelo,
      },
    ];
    const columns = [
      {
        title: <strong>Tipo de Veículo</strong>,
        dataIndex: 'TipoVeiculo',
        key: 'TipoVeiculo',
        responsive: ['sm', 'md', 'lg', 'xl'],
      },
      { title: <strong>Marca</strong>, dataIndex: 'Marca', key: 'Marca', responsive: ['sm', 'md', 'lg', 'xl'] },
      { title: <strong>Modelo</strong>, dataIndex: 'Modelo', key: 'Modelo', responsive: ['sm', 'md', 'lg', 'xl'] },
      {
        title: <strong>Ano de Fabricação</strong>,
        dataIndex: 'AnoFabricacao',
        key: 'AnoFabricacao',
        responsive: ['sm', 'md', 'lg', 'xl'],
      },
      {
        title: <strong>Ano do Modelo</strong>,
        dataIndex: 'AnoModelo',
        key: 'AnoModelo',
        responsive: ['sm', 'md', 'lg', 'xl'],
      },
      {
        responsive: ['xs'],
        render: (text, record) => (
          <div>
            <p>
              <strong>{record.TipoVeiculo}</strong>
            </p>
            <p>Marca: {record.Marca}</p>
            <p>Modelo: {record.Modelo}</p>
            <p>Fabricação: {record.AnoFabricacao}</p>
            <p>Ano modelo: {record.AnoModelo}</p>
          </div>
        ),
      },
    ];
    return (
      <>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
          }}
        >
          <Table dataSource={data} pagination={false} columns={columns} />
        </List>
      </>
    );
  };

  const showPictures = responseList => {
    return (
      <>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={responseList}
          renderItem={item => (
            <List.Item>
              <Card
                title={
                  <Tooltip title={item.ParteVeiculo}>
                    <span>{item.ParteVeiculo}</span>
                  </Tooltip>
                }
              >
                <>
                  <>
                    <Image
                      width={'100%'}
                      className="principal-img"
                      src={
                        item.ArquivoImagem +
                        '?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_1000,w_1000'
                      }
                      preview={{
                        src: item.ArquivoImagem,
                        width: '60%',
                      }}
                    />
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={event => handleImageChange(event, item)}
                      id={`input-file-${item.ParteVeiculo}`}
                      key={`input-file-${item.ParteVeiculo}`} // Adicione uma chave única para o input
                    />
                  </>
                  {selectedImages[item.ParteVeiculo] && (
                    <>
                      <Image
                        width={448}
                        src={selectedImages[item.ParteVeiculo]}
                        preview={false}
                        onClick={() => {
                          setFullScreen(item.ParteVeiculo);
                          toggleFullscreen();
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      {isFullscreen && fullScreen === item.ParteVeiculo && (
                        <div
                          className="fullscreen-container"
                          id="container-images"
                          onClick={() => {
                            setFullScreen('');
                            toggleFullscreen();
                          }}
                        >
                          <div className="fullscreen-image" id="container-image-original">
                            <img src={item.ArquivoImagem} alt="Imagem 1" />
                          </div>
                          <div className="fullscreen-image" id="container-image-add">
                            <img src={selectedImages[item.ParteVeiculo]} alt="Imagem 2" />
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {options.includes(item.ParteVeiculo) && (
                    <Button
                      style={{ marginTop: '5px', marginLeft: '0' }}
                      loading={loading}
                      color="primary"
                      className="add-images-button"
                      id={`button-${item.ParteVeiculo}`}
                      onClick={() => document.getElementById(`input-file-${item.ParteVeiculo}`).click()} // Use getElementById
                    >
                      Selecionar Imagem
                    </Button>
                  )}
                </>
                {item?.Observacao.length !== 0 && (
                  <>
                    <b>Observação</b>: {item.Observacao}
                    <br />
                  </>
                )}
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/consultas">{t('menus:consultas')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:new-consulta')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:new-consulta')} />
      <Box>
        <Title level={4} style={{ color: '#4b4b4b' }}>
          {t('screens:consultas.data.remaining')} {credits}
        </Title>
        <Row>
          <p style={{ color: 'green' }}>Essa consulta: 1 crédito</p>
          {!isVisible && resultData.id && (
            <Button
              style={{ marginLeft: 'auto' }}
              loading={loading}
              size="large"
              color="primary"
              onClick={() => window.location.reload()}
            >
              <PlusOutlined />
              {t('screens:consultas.btnNew')}
            </Button>
          )}
        </Row>
        <Row>
          {isVisible && (
            <Formik
              initialValues={{
                userId: profile.id,
                orderId: 0,
                licensePlate: '',
                chassis: '',
                motor: '',
                creditsUsed: 0,
              }}
              enableReinitialize
              onSubmit={handleSave}
              validationSchema={userSchema}
            >
              {({ errors, isSubmitting, touched }) => (
                <Spin spinning={loading || isSubmitting}>
                  <Container>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <FormControl
                            cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                            field="licensePlate"
                            label={t('screens:consultas.data.licensePlate')}
                            error={(touched.licensePlate && errors.licensePlate) || errors.licensePlate}
                          >
                            <Input name="licensePlate" />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                            field="chassis"
                            label={t('screens:consultas.data.chassis')}
                            error={(touched.chassis && errors.chassis) || errors.chassis}
                          >
                            <Input name="chassis" />
                          </FormControl>
                        </Row>
                        {/* <Row>
                          <FormControl
                            cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                            field="motor"
                            label={t('screens:consultas.data.motor')}
                            error={(touched.motor && errors.motor) || errors.motor}
                          >
                            <Input name="motor" />
                          </FormControl>
                        </Row> */}
                      </Col>
                    </Row>
                    <Button
                      disabled={expired || credits === 0}
                      type="submit"
                      loading={loading}
                      size="large"
                      color="primary"
                    >
                      Enviar
                    </Button>
                  </Container>
                </Spin>
              )}
            </Formik>
          )}

          {/* verify if the result is visible and if reaultData.id is not null */}

          {!isVisible && (
            <>
              {resultData.id ? (
                <>
                  <Descriptions
                    title={<Title level={5}>Resultados da Busca</Title>}
                    layout="vertical"
                    bordered={true}
                    colon={false}
                    size="medium"
                    column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
                    style={{ width: '100%' }}
                  >
                    <Descriptions.Item label="Informações">
                      {showInfoTable(resultData?.result?.Resposta?.struct_Resposta)}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions
                    layout="vertical"
                    bordered={true}
                    colon={false}
                    size="small"
                    column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                  >
                    <Descriptions.Item label="Fotos">
                      {showPictures(resultData?.result?.Resposta?.struct_Resposta)}
                    </Descriptions.Item>
                  </Descriptions>
                  <Button
                    style={{ marginTop: '5px' }}
                    loading={loading}
                    size="large"
                    color="primary"
                    onClick={() => window.location.reload()}
                  >
                    Voltar
                  </Button>
                </>
              ) : (
                <>
                  <Descriptions
                    title={<Title level={5}>Resultados da Busca</Title>}
                    layout="vertical"
                    bordered={true}
                    colon={false}
                    size="medium"
                    column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
                    style={{ width: '100%' }}
                  ></Descriptions>
                  <Alert
                    message="Aviso"
                    description="Não foram encontrados registros correspondentes. Insira um número de placa ou chassi diferente."
                    type="warning"
                    banner={true}
                    showIcon
                    style={{ width: '100%', marginTop: '30px' }}
                  />
                </>
              )}
            </>
          )}
        </Row>
      </Box>
    </DefaultLayout>
  );
}
