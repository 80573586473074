import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Breadcrumb, Descriptions, Typography, Space } from 'antd';
import DefaultLayout from '../_layouts/full';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { Table } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';
import { findAllPersonConsultas, findAllPersonIdConsultas } from '~/services/hooks/consultas';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const { Title } = Typography;

export default function Consultas() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('ASC');
  const { id } = useParams();

  const fetchData = async page => {
    setLoading(true);

    const { data, meta } = isNaN(id) ? await findAllPersonConsultas(page) : await findAllPersonIdConsultas(page, id);
    console.log("Vindo de Consultas/index -> ln 33", data);

    setMeta(meta);
    setTableData(data);
    setLoading(false);
  };

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    if (sorter.field !== sortBy || sortOrder !== sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
  };

  const tableColumns = [
    {
      title: t('screens:consultas.data.nr'),
      align: 'center',
      width: '10%',
      responsive: ['sm', 'md', 'lg', 'xl'],
      className: 'table-id',
      dataIndex: 'id',
      ellipsis: { showTitle: false },
      key: 'id',
    },
    {
      title: t('screens:consultas.data.user'),
      dataIndex: ['order', 'user', 'name'],
      align: 'center',
      width: '20%',
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:consultas.data.package'),
      dataIndex: ['order', 'package_data', 'name'],
      align: 'center',
      width: '20%',
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:consultas.data.license_plate_and_chassi'),
      dataIndex: 'license_plate',
      align: 'center',
      width: '25%',
      ellipsis: { showTitle: true },
      render: (license_plate, record) => {
        if (license_plate) {
          return (
            <span>
              {license_plate} <br /> <span style={{ color: '#999' }}>{record.chassis}</span>
            </span>
          );
        } else {
          return (
            <span>
              {record.chassis} <br /> <span style={{ color: '#999' }}>{record.license_plate}</span>
            </span>
          );
        }
      },
    },
    {
      title: t('screens:consultas.data.data'),
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: '10%',
      ellipsis: { showTitle: true },
      render: text => (
        <span>
          {moment(text).format('DD/MM/YYYY')} <br />{' '}
          <span style={{ color: '#999' }}>{moment(text).format('HH:mm:ss')}</span>
        </span>
      ),
    },
    {
      title: t('screens:consultas.data.credits'),
      dataIndex: 'credits_used',
      key: 'credits_used',
      width: '15%',
    },
  ];

  const expandedRowRender = record => (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Row gutter={16}>
        <Col span={24}>
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Descriptions
              title={<Title level={5}>DETALHES DA CONSULTA</Title>}
              layout="vertical"
              bordered={true}
              colon={false}
              size="small"
              column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="RESULTADO DA PESQUISA">
                <p>
                  <b>Nome da Transação</b>: {record?.result?.Identificacao?.NomeDaTransacao}
                </p>
                <p>
                  <b>Descrição da Transação</b>: {record?.result?.Identificacao?.DescricaoDaTransacao}
                </p>
                <p>
                  <b>Versão da Transação</b>: {record?.result?.Identificacao?.VersaoDaTransacao}
                </p>
                <p>
                  <b>Data da Publicação</b>: {record?.result?.Identificacao?.DataDaPublicacao}
                </p>
                <p>
                  <b>Autor</b>: {record?.result?.Identificacao?.Autor}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="CONTROLE DA PESQUISA">
                <p>
                  <b>Cliente</b>: {record?.result?.Controle?.Cliente}
                </p>
                <p>
                  <b>Login</b>: {record?.result?.Controle?.Login}
                </p>
                <p>
                  <b>Placa de Entrada</b>: {record?.result?.Controle?.PlacaDeEntrada}
                </p>
                <p>
                  <b>Chassi de Entrada</b>: {record?.result?.Controle?.ChassiDeEntrada}
                </p>
                <p>
                  <b>IP do Computador Consumidor</b>: {record?.result?.Controle?.IPdoComputadorConsumidor}
                </p>
                <p>
                  <b>Nome do Computador Provedor</b>: {record?.result?.Controle?.NomeDoComputadorProvedor}
                </p>
                <p>
                  <b>Código de Controle</b>: {record?.result?.Controle?.CodigoDeControle}
                </p>
                <p>
                  <b>Severidade</b>: {record?.result?.Controle?.Severidade}
                </p>
                <p>
                  <b>Descrição</b>: {record?.result?.Controle?.Descricao}
                </p>
                <p>
                  <b>Ação Adotada</b>: {record?.result?.Controle?.AcaoAdotada}
                </p>
                <p>
                  <b>Resultado da Pesquisa</b>: {record?.result?.Controle?.ResultadoDaPesquisa}
                </p>
                <p>
                  <b>Gravação do Log</b>: {record?.result?.Controle?.GravacaoDoLog}
                </p>
                <p>
                  <b>Data de Execução</b>: {record?.result?.Controle?.DataExecucao}
                </p>
                <p>
                  <b>Número da Transação</b>: {record?.result?.Controle?.NrTransacao}
                </p>
                <p>
                  <b>Observação</b>:
                  {record?.result?.Controle?.Observacao?.join(', ')}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="CLIENTE">
                <b>Nome: </b> {record?.order?.user?.name}
                <br />
                <b>Tipo: </b> {record?.order?.user?.type}
                <br />
                <b>E-mail: </b> {record?.order?.user?.email}
                <br />
                <b>CPF/CNPJ: </b> {record?.order?.user?.cpf}
              </Descriptions.Item>
              {record?.order?.user?.type !== 'ADMIN' ? (
                <Descriptions.Item label="ENDEREÇO E TELEFONE">
                  <b>Telefone: </b> {record.order?.user?.address?.telefone} <br />
                  <b>Logradouro: </b>
                  {record.order?.user?.address?.endereco} <br />
                  <b>Número: </b> {record.order?.user?.address?.numero} <br />
                  <b>Localidade: </b> {record.order?.user?.address?.bairro} <br />
                  <b>Cidade: </b> {record.order?.user?.address?.cidade}-{record.order?.user?.address?.estado} <br />
                  <b>CEP: </b> {record.order?.user?.address?.cep}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </Space>
        </Col>
      </Row>
    </Space>
  );

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:consultas')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:consultas')} />
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Link to="/consultas/new">
              <Button loading={loading} color="primary" style={style.btnNew.btn}>
                <PlusOutlined />
                {t('screens:consultas.btnNew')}
              </Button>
            </Link>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}
          expandable={{ expandedRowRender }}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
    </DefaultLayout>
  );
}
