import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { Modal, Spin, Col, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { useDispatch } from 'react-redux';
import { createCoupon, updateCoupons } from '~/services/hooks/coupons';
import { Container } from './styles';

const initialValues = {
  id: null,
  user_id: null,
  package_id: null,
  code: '',
  discount: '',
  package_disconted_value: null,
  active: null,
};

export default function CouponForm({ selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);
    setRecordData({ ...initialValues, ...selectedRecord });
    setLoading(false);
  };

  const handleSave = async values => {
    setLoading(true);

    let newCoupon = {
      id: values.id,
      discount: values.discount,
    };

    let updatedCoupon = {
      id: values.id,
      user_id: values.user_id,
      package_id: values.package_id,
      code: values.code,
      discount: values.discount,
      package_disconted_value: values.package_disconted_value,
      active: values.active,
    };

    if (values.id && values.id !== null) {
      const data = await updateCoupons(updatedCoupon);
      onUpdateItem(data);
    } else {
      const data = await createCoupon(newCoupon);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    discount: Yup.number()
      .typeError('O desconto deve ser um número')
      .required('O desconto é obrigatório')
      .min(1, 'O desconto deve ser maior que 0')
      .max(100, 'O desconto deve ser menor ou igual a 100'),
  });

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values }) => (
        <Modal
          width="580px"
          title={selectedRecord !== null ? t('screens:coupons.updateCoupon') : t('screens:coupons.newCoupon')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Container>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}
                      field="discount"
                      label={t('screens:coupons.data.discountForm')}
                      required
                      error={(touched.discount && errors.discount) || errors.discount}
                    >
                      <Input name="discount" type="number" />
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
