import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Row, Divider, Spin } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { BreadcrumbCustom } from '~/styles/global';
import { paginationPlans } from '~/services/hooks/plans';
import { formatPrice } from '~/Utils/format';
import history from '~/services/history';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { ButtonBuy, CardCredits, ColCredits, DivBuy, SpanCredits, Title } from './styles';

export default function Credits() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationPlans(page, {});
    setMeta(data.meta);
    setTableData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const style = {
    pageTitle: { marginRight: 'auto', width: '100%' },
    pcenter: { textAlign: 'center' },
    gray: { color: 'gray' },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:credits')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:credits.title')} style={style.pageTitle} />
      <Box>
        <Spin spinning={loading}>
          <Row>
            {tableData &&
              tableData.map(record => (
                <ColCredits key={record.id} xxl={5} xl={5} lg={7} md={11} sm={24} xs={24}>
                  <CardCredits>
                    <Title>{record.name.toUpperCase()}</Title>
                    <p style={style.pcenter} />
                    <br />
                    <br />
                    <SpanCredits>
                      {record.credits} {t('screens:credits.credits')}
                    </SpanCredits>
                    <br />
                    <Divider />
                    <br />
                    <span>{formatPrice(record.price)}</span>
                    <br />
                    <span style={style.gray}>({formatPrice(record.price / record.credits)} / C)</span>
                    <br />
                    <br />
                    <span>
                      {t('screens:credits.availableBy')} {record.days} {t('screens:credits.daysAfterPurchase')}
                    </span>
                    <br />
                    <br />
                    <br />
                    <DivBuy>
                      <ButtonBuy
                        loading={loading}
                        color="primary"
                        onClick={() => {
                          history.push(`credits/${record.id}`);
                        }}
                      >
                        <ShoppingCartOutlined />
                        {t('screens:credits.btnNew')}
                      </ButtonBuy>
                    </DivBuy>
                  </CardCredits>
                </ColCredits>
              ))}
          </Row>

          {meta && (
            <Pagination
              onChange={value => {
                fetchData(value);
              }}
              meta={meta}
            />
          )}
        </Spin>
      </Box>
    </DefautLayout>
  );
}
