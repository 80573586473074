const defaultTheme = {

  color1: '#3d3d3d',
  color2: '#62a159',
  color3: '#092F4F',
  color4: '#888',
  color5: '#efefef',
  color6: '#aaa',

  button: {
    colors: {
      primary: {
        bg: '#62a159',
        font: '#fff',
        hoverColor: '#84ad7b',
      },
      default: {
        bg: '#d6d6d6',
        font: '#6B6B6B',
        hoverColor: '#A1A1A1',
        hoverFontColor: '#fff',
      },
      clear: {
        bg: '#d6d6d6',
        font: '#437a3d',
      },
      success: {
        bg: '#0a0',
        font: '#fff',
      },
      danger: {
        bg: '#a00',
        font: '#fff',
      },
      warning: {
        bg: '#aa0',
        font: '#fff',
      },
      info: {
        bg: '#69f',
        font: '#fff',
      },
    },
    sizes: {
      small: {
        padding: '0 10px',
        fontSize: '13px',
        height: '24px',
      },
      default: {
        padding: '0 15px',
        fontSize: '14px',
        height: '32px',
      },
      large: {
        padding: '0 20px',
        fontSize: '15px',
        height: '40px',
      },
    },
  },

  colors: {
    primary: {
      hover: {
        borderColor: '#84AD7B'
      },
      focus: {
        borderColor: '#62a159',
        boxShadow: 'rgba(98,161,89,.2)'
      },
      checked: {
        bg: '#62a159',
        borderColor: '#62a159',
        after: '#62a159'
      }
    },
    error: {
      labelColor: '#f55',
      borderColor: '#ffa39e',
      hover: {
        borderColor: '#f55'
      },
      focus: {
        borderColor: '#f55',
        boxShadow: 'rgba(255,85,85,.2)'
      }
    }
  }
};

export default defaultTheme;
