import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breadcrumb, Input, Space, Tooltip, Typography, message } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import { useParams } from 'react-router-dom';
import Row from '~/components/Row';
import { findByIdPlan } from '~/services/hooks/plans';
import { useDispatch, useSelector } from 'react-redux';
import { formatPrice } from '~/Utils/format';
import Button from '~/components/Button';
import history from '~/services/history';
import { createVenda } from '~/services/hooks/finances';
import QRCode from 'react-qr-code';
import { FaCopy } from 'react-icons/fa';
import { findUser } from '~/services/hooks/users';
import { updateProfile } from '~/store/modules/user/actions';
import FormControl from '~/components/Form/FormControl';
import { findByCodeCoupon } from '~/services/hooks/coupons';
import i18next from 'i18next';

export default function CredDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { TextArea } = Input;
  const { Title } = Typography;
  const { id } = useParams();
  const { profile } = useSelector(state => state.user);
  const [textCopy, setTextCopy] = useState(false);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    address_id: 1,
    id: 1,
    price: 0,
    credits: 0,
  });
  const [user, setUser] = useState(profile);
  const [codeValue, setCodeValue] = useState('');
  const [couponData, setCouponData] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);

  const copyToClipboard = useCallback(async (text, setTextCopy) => {
    try {
      await navigator.clipboard.writeText(text);
      setTextCopy(true);
      console.log('Texto copiado para a área de transferência');
    } catch (err) {
      setTextCopy(false);
      console.log('Falha ao copiar o texto', err);
    }
  }, []);

  const showAlert = (type, message) => <Alert message={message} type={type} showIcon />;

  const fetchPaymentData = async () => {
    setVisible(false);
    setLoading(true);

    const response = await findByIdPlan(id);

    let data = {};
    data = await createVenda({ packageId: response?.id, coupon_id: couponData?.id, discountedPrice: discountedPrice });

    data.pix = data?.order_data?.point_of_interaction.transaction_data.qr_code;

    setData(data);
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);

    let data = await findByIdPlan(id);
    setData(data);
    setUser(profile);
    setLoading(false);
  };

  const fetchCoupon = async () => {
    setLoading(true);

    let dataCoupon = await findByCodeCoupon(codeValue);

    if (dataCoupon === 417) {
      message.error(i18next.t('messages:coupons.errorApply'));
    } else {
      setCouponData(dataCoupon);
      setDiscountedPrice(data.price - data.price * (dataCoupon.discount / 100));
    }

    setLoading(false);
  };

  const handleInputChange = e => {
    setCodeValue(e.target.value);
  };

  const verifyDataResponse = data => {
    console.log('Credits/details.js - verifyDataResponse => ', data);
    let dataVerifyJson =
      data.order_data &&
      typeof data.order_data === 'string' &&
      JSON.parse(data.order_data).response &&
      JSON.parse(data.order_data).response === 200;
    let dataVerifyint = data.order_data && data.order_data === 200;

    if (dataVerifyJson) {
      return true;
    }

    if (dataVerifyint) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { color: '#52c41a' },
    inactive: { color: 'red' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/credits">{t('menus:credits')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:payment')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:credits.payment.title')} style={style.pageTitle} />
      {visible && (
        <Box>
          <Title level={5} style={{ color: '#4b4b4b' }}>
            Dados do item
          </Title>
          <Row>
            <p style={{ fontSize: '1rem', marginLeft: '5%' }}>{data.name.toUpperCase()}</p>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{data.credits} CRÉDITOS</p>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{formatPrice(data.price)}</p>
          </Row>
          <Title level={5} style={{ color: '#4b4b4b' }}>
            Confira seus dados
          </Title>
          <Row>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.email}</p>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.name}</p>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.cpf}</p>
          </Row>
          <Title level={5} style={{ color: '#4b4b4b' }}>
            Telefone
          </Title>
          <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.address?.telefone}</p>
          <Title level={5} style={{ color: '#4b4b4b' }}>
            Endereço
          </Title>
          <Row>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.address?.cep}</p>
          </Row>
          <Row>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.address?.endereco}, </p>
            <p style={{ fontSize: '0.8rem' }}>{user?.address?.numero}</p>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.address?.bairro}</p>
            <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{user?.address?.cidade}, </p>
            <p style={{ fontSize: '0.8rem' }}>{user?.address?.estado}</p>
          </Row>
          <FormControl
            cols={{ xs: 14, sm: 14, md: 6, lg: 7, xl: 6, xxl: 4 }}
            field="codeFilter"
            label={t('screens:coupons.data.code')}
            style={{ marginTop: '30px' }}
          >
            <Input
              name="codeFilter"
              placeholder={t('screens:coupons.data.codePlaceholder')}
              value={codeValue}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button loading={loading} color="primary" onClick={fetchCoupon}>
            {t('screens:credits.btnCoupon')}
          </Button>
          {couponData !== null && (
            <>
              <Title level={5} style={{ color: '#4b4b4b', marginTop: '20px' }}>
                Cupom aplicado com sucesso!
              </Title>
              <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>
                {formatPrice(data.price)} - {couponData.discount}%
              </p>
              <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>Valor com desconto:</p>
              <p style={{ fontSize: '0.8rem', marginLeft: '5%' }}>{formatPrice(discountedPrice)}</p>
            </>
          )}
          <Button style={{ marginTop: '5%' }} loading={loading} color="primary" onClick={fetchPaymentData}>
            {couponData !== null && couponData.discount === 100 ? (
              'Resgatar créditos'
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="14"
                  width="14"
                  viewBox="0 0 512 512"
                  focusable="false"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.8C231.1-7.6 280.3-7.6 310.6 22.8L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.8L22.8 310.6C-7.6 280.3-7.6 231.1 22.8 200.8L80.8 142.7H112.6z" />
                </svg>
                {t('screens:credits.btnCompra')}
              </>
            )}
          </Button>
        </Box>
      )}
      {!visible && (
        <Box>
          <Title level={5} style={{ color: '#4b4b4b' }}>
            Valor: {data.request_data && formatPrice(data.request_data.valor.original)}
          </Title>
          <p style={{ fontSize: '0.8rem' }}>Instruções para escanear o QR Code:</p>
          <p style={{ fontSize: '0.8rem' }}>- Abra o aplicativo do seu banco</p>
          <p style={{ fontSize: '0.8rem' }}>- Selecione a opção PIX</p>
          <p style={{ fontSize: '0.8rem' }}>- Selecione a opção PAGAR</p>
          <p style={{ fontSize: '0.8rem' }}>- Aponte a câmera do seu celular para o QR Code</p>
          {data.pix && (
            <Space direction="vertical" align="center">
              <QRCode size={256} value={data.pix} viewBox="0 0 256 256" />
              <p style={{ fontSize: '0.8rem' }}>
                Ou copiar o código abaixo para realizar o pagamento. Selecione a opção PIX copia e cola do seu banco.
              </p>
              <Input.Group compact>
                <TextArea
                  rows={2}
                  disabled
                  style={{
                    width: 'calc(100% - 32px)',
                  }}
                  value={data.pix}
                />
                <Tooltip title="copiar">
                  <Button onClick={() => copyToClipboard(data.pix, setTextCopy)}>
                    <FaCopy />
                  </Button>
                </Tooltip>
              </Input.Group>
              {textCopy && showAlert('success', 'Texto copiado para área de transferência')}
            </Space>
          )}{' '}
          {verifyDataResponse(data) && (
            <Space direction="vertical" align="center" style={{ marginTop: '30px' }}>
              <Alert
                message="Resgatado com sucesso!"
                description="Parabéns! Você conseguiu um desconto de 100%! Aproveite bem os créditos liberados para você."
                type="success"
                showIcon
              />
            </Space>
          )}
          <Button
            style={{ marginTop: '5%' }}
            loading={loading}
            color="primary"
            onClick={async () => {
              const user = await findUser();
              dispatch(updateProfile(user));
              history.push('/credits/message');
            }}
          >
            {t('screens:credits.btnClose')}
          </Button>
        </Box>
      )}
    </DefautLayout>
  );
}
