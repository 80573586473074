import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Breadcrumb, Spin, Typography, message } from 'antd';
import DefaultLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import { Formik, isEmptyArray } from 'formik';
import FormControl from '~/components/Form/FormControl';
import { Input, Radio, Select } from 'formik-antd';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro/dist/TelefoneBrasileiroInput';
import ReactInputMask from 'react-input-mask';
import * as Yup from 'yup';
import { validateCnpj, validateCpf, validatePhone } from '~/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '~/services/hooks/users';
import { updateProfile } from '~/store/modules/user/actions';
import cep from 'cep-promise';
import cities from '~/data/cities';
import { Container } from './styles';

const initialValues = {
  id: null,
  name: '',
  email: '',
  status: true,
  cpf: '',
};

export default function Profile() {
  const { Title } = Typography;
  const { Option } = Select;
  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState(initialValues);
  const [telefone, setTelefone] = useState('');
  const [states, setStates] = useState({});
  const [cidades, setCidades] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const x = JSON.parse(JSON.stringify(profile));
    x.addressid = profile.address.id;
    x.cep = profile.address.cep;
    x.neighborhood = profile.address.bairro;
    x.address_number = profile.address.numero;
    x.city = profile.address.cidade;
    x.state = profile.address.estado;
    setTelefone(profile.address.telefone);
    x.address = profile.address.endereco;
    setRecordData(x);

    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    let result = JSON.parse(JSON.stringify(values));

    if (values.type === 'CLIENT') {
      if (values.user_type === 'pf' ? validateCpf(values.cpf) : validateCnpj(values.cnpj)) {
        if (validatePhone(telefone)) {
          result.address = {};
          result.address.id = values.addressid;
          result.address.telefone = telefone;
          result.address.cep = values.cep;
          result.address.bairro = values.neighborhood;
          result.address.numero = values.address_number;
          result.address.cidade = values.city;
          result.address.estado = values.state;
          result.address.endereco = values.address;

          await updateUser(result);
          dispatch(updateProfile(result));

          setLoading(false);
        } else {
          setErrors({ telephone: 'Telefone inválido!' });
          setLoading(false);
          return;
        }
      } else {
        setErrors(values.user_type === 'pf' ? { cpf: 'CPF inválido!' } : { cnpj: 'CNPJ inválido!' });
        setLoading(false);
        return;
      }
    }
  };

  const handleFindAddress = async (value, values) => {
    setLoading(true);
    try {
      const zipcode = value.replace(/[^0-9,]*/g, '');
      const address = await cep(zipcode);
      setRecordData({
        ...values,
        cep: zipcode,
        city: address.city,
        state: address.state,
        neighborhood: address.neighborhood,
        address: address.street,
      });
    } catch (error) {
      message.error('Endereço não localizado!');
    }
    setLoading(false);
  };

  const selecionaCidade = estado => {
    states.map(item => {
      if (item.sigla === estado) {
        setCidades(item.cidades);
      }
      return null;
    });
  };

  useEffect(() => {
    fetchData();
    setStates(cities.estados);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:profile')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().when('id', { is: null, then: Yup.string().required() }),
    confirmPassword: Yup.string().when('id', {
      is: null,
      then: Yup.string()
        .oneOf([Yup.ref('password'), null], t('differentPasswords'))
        .required(),
    }),
    password_confirmation: Yup.string().when('newPasswordConfirmReq', {
      is: true,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('differentPasswords'))
        .required(),
    }),
    user_type: Yup.string().required(),
    type: Yup.string().required(),
    // active: Yup.bool().required(),
    cpf: Yup.string().when('type', {
      is: 'CLIENT',
      then: Yup.string().when('user_type', { is: 'pf', then: Yup.string().required() }),
    }),
    cnpj: Yup.string().when('type', {
      is: 'CLIENT',
      then: Yup.string().when('user_type', { is: 'pj', then: Yup.string().required() }),
    }),
    // telephone: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    cep: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    neighborhood: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    address: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    address_number: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    state: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    city: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
  });

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:personal')} />
      <Box>
        <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={userSchema}>
          {({ errors, isSubmitting, touched, setFieldValue, values }) => (
            <Spin spinning={loading || isSubmitting}>
              <Container>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Title level={4} style={{ color: '#4b4b4b' }}>
                      {t('screens:users.data.personalInfo')}
                    </Title>
                    <Row>
                      <Radio.Group
                        name="user_type"
                        onChange={() => {}}
                        style={{
                          marginBottom: 16,
                        }}
                      >
                        <Radio.Button value="pf">Física</Radio.Button>
                        <Radio.Button value="pj">Jurídica</Radio.Button>
                      </Radio.Group>
                    </Row>
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                        field="name"
                        label={
                          values.user_type === 'pf' ? t('screens:users.data.name') : t('screens:users.data.companyName')
                        }
                        required
                        error={(touched.name && errors.name) || errors.name}
                      >
                        <Input name="name" />
                      </FormControl>
                    </Row>
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="email"
                        label={t('screens:users.data.email')}
                        required
                        error={(touched.email && errors.email) || errors.email}
                      >
                        <Input type="email" name="email" disabled={!values.isAdmin} />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="type"
                        label={t('screens:users.data.role')}
                        required
                        error={(touched.type && errors.type) || errors.type}
                      >
                        <Select disabled={!values.isAdmin} showSearch name="type" label={t('screens:users.data.role')}>
                          <Option key={0} value={'ADMIN'}>
                            Administrador
                          </Option>
                          <Option key={1} value={'CLIENT'}>
                            Cliente
                          </Option>
                        </Select>
                      </FormControl>
                    </Row>
                    {values.type === 'CLIENT' && (
                      <>
                        <Row>
                          {values.user_type === 'pf' ? (
                            <FormControl
                              cols={{ xs: 13, sm: 9, md: 8, lg: 8, xl: 8 }}
                              field="cpf"
                              required
                              error={(touched.cpf && errors.cpf) || errors.cpf}
                              label={t('screens:signUp.data.cpf')}
                            >
                              <ReactInputMask
                                className="ant-input"
                                mask={'999.999.999-99'}
                                name="cpf"
                                value={values.cpf}
                                onChange={e => {
                                  const formattedCpf = e.target.value.replace(/\D/g, '');
                                  setFieldValue('cpf', formattedCpf);
                                }}
                                placeholder={'___.___.___-__'}
                                id="cpf"
                              />
                            </FormControl>
                          ) : (
                            <FormControl
                              cols={{ xs: 13, sm: 9, md: 8, lg: 8, xl: 8 }}
                              field="cnpj"
                              required
                              error={(touched.cnpj && errors.cnpj) || errors.cnpj}
                              label={t('screens:signUp.data.cnpj')}
                            >
                              <ReactInputMask
                                className="ant-input"
                                mask={'99.999.999/9999-99'}
                                name="cnpj"
                                value={values.cnpj}
                                onChange={e => {
                                  const formattedcnpj = e.target.value.replace(/\D/g, '');
                                  setFieldValue('cnpj', formattedcnpj);
                                }}
                                placeholder={'__.___.___/____-__'}
                                id="cnpj"
                              />
                            </FormControl>
                          )}
                          <FormControl
                            required
                            cols={{ xs: 12 }}
                            field="telephone"
                            error={(touched.telephone && errors.telephone) || errors.telephone}
                            label={t('screens:customers.data.telephone')}
                          >
                            <TelefoneBrasileiroInput
                              temDDD
                              value={telefone}
                              onChange={event => setTelefone(event.target.value)}
                              className="ant-input"
                            />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            required
                            cols={{ xs: 12 }}
                            field="cep"
                            error={(touched.cep && errors.cep) || errors.cep}
                            label={t('screens:customers.data.cep')}
                          >
                            <ReactInputMask
                              className="ant-input"
                              mask="99.999-999"
                              name="cep"
                              onChange={e => setFieldValue('cep', e.target.value)}
                              value={values.cep}
                              onBlur={e => {
                                handleFindAddress(e.target.value, values);
                              }}
                              placeholder="__.___-___"
                              id="cep"
                            />
                          </FormControl>

                          <FormControl
                            required
                            cols={{ xs: 12 }}
                            field="neighborhood"
                            error={(touched.neighborhood && errors.neighborhood) || errors.neighborhood}
                            label={t('screens:customers.data.neighborhood')}
                          >
                            <Input name="neighborhood" value={values.neighborhood} />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            required
                            cols={{ xs: 18 }}
                            field="address"
                            error={(touched.address && errors.address) || errors.address}
                            label={t('screens:customers.data.address')}
                          >
                            <Input name="address" style={{ textTransform: 'uppercase' }} value={values.address} />
                          </FormControl>
                          <FormControl
                            required
                            error={errors.address_number}
                            cols={{ xs: 6 }}
                            field="address_number"
                            label={t('screens:customers.data.number')}
                          >
                            <Input name="address_number" type="number" style={{ textTransform: 'uppercase' }} />
                          </FormControl>
                        </Row>
                        <Row>
                          <FormControl
                            required
                            cols={{ xs: 12 }}
                            field="state"
                            error={(touched.state && errors.state) || errors.state}
                            label={t('screens:customers.data.state')}
                          >
                            <Select style={{ width: '90%' }} id="state" name="state" onChange={selecionaCidade}>
                              {states &&
                                states.map(item => (
                                  <Select.Option key={item.sigla} value={item.sigla}>
                                    {item.nome}
                                  </Select.Option>
                                ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            cols={{ xs: 12 }}
                            required
                            field="city"
                            error={(touched.city && errors.city) || errors.city}
                            label={t('screens:customers.data.city')}
                          >
                            <Select
                              style={{ width: '90%' }}
                              id="city"
                              name="city"
                              component="select"
                              disabled={isEmptyArray(cidades)}
                            >
                              {cidades.map(item => (
                                <Select.Option key={item} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormControl>
                        </Row>
                        <Button loading={loading} color="primary" type="submit" style={style.btnNew.btn}>
                          <PlusOutlined />
                          {t('screens:profile.btnEdit')}
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </Spin>
          )}
        </Formik>
      </Box>
    </DefaultLayout>
  );
}
