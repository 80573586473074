import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClockCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Tag, Col, Row, Descriptions, Typography, Space, Card } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { Table } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';
import Filter from './filter';
import { exportVenda, paginationVendas } from '~/services/hooks/finances';
import { formatPrice } from '~/Utils/format';
import UsersInfo from '~/components/UsersInfo';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { isString } from 'highcharts';
import Button from '~/components/Button';

const { Text, Title } = Typography;

export default function Finances() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('ASC');
  const { name } = useParams();
  const filter = {};
  filter.userName = name;
  const [filters, setFilters] = useState(name !== null ? filter : {});

  const getProjectStatusTag = status => {
    switch (status) {
      case 'authorized':
      case 'in_process':
      case 'in_mediation':
        return (
          <Tag icon={<ClockCircleOutlined />} color="#FAAD14">
            Ativa
          </Tag>
        );

      case 'approved':
        return (
          <Tag icon={<CheckOutlined />} color="#A0D911">
            Concluída
          </Tag>
        );

      case 'rejected':
        return (
          <Tag icon={<CloseOutlined />} color="#F5222D">
            Pagamento Não Funcionou
          </Tag>
        );

      case 'cancelled':
        return (
          <Tag icon={<CloseOutlined />} color="#F5222D">
            Cancelado
          </Tag>
        );
      case 'expired':
        return (
          <Tag icon={<CloseOutlined />} color="#F5222D">
            Expirado
          </Tag>
        );
      case 'refunded':
        return (
          <Tag icon={<CloseOutlined />} color="#F5222D">
            Pagamento Devolvido
          </Tag>
        );

      case 'pending':
        return (
          <Tag icon={<ExclamationOutlined />} color="#1890FF">
            Pendente
          </Tag>
        );
      default:
        return (
          <Tag icon={<ExclamationOutlined />} color="#1890FF">
            Pendente
          </Tag>
        );
    }
  };

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationVendas(page, filters);
    setMeta(data.meta);
    data.data.map(item => {
      if (isString(item.order_data)) item.order_data = JSON.parse(item.order_data);
      return null;
    });

    setTableData(data.data);

    setLoading(false);
  };

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    if (sorter.field !== sortBy || sortOrder !== sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortBy]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { color: '#52c41a' },
    inactive: { color: 'red' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
  };

  const tableColumns = [
    {
      title: t('screens:finances.data.id'),
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '60px',
      className: 'table-id',
      dataIndex: 'id',
      ellipsis: { showTitle: false },
      key: 'id',
    },
    {
      title: t('screens:finances.data.package_data'),
      dataIndex: ['package_data', 'name'],
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:finances.data.status'),
      dataIndex: ['status'],
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '190px',
      ellipsis: { showTitle: true },
      render: text => getProjectStatusTag(text),
    },
    {
      title: t('screens:finances.data.statusPayment'),
      dataIndex: ['mp_status'],
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '190px',
      ellipsis: { showTitle: true },
      render: text => getProjectStatusTag(text),
    },
    {
      title: t('screens:finances.data.credits'),
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '140px',
      ellipsis: { showTitle: true },
      render: (text, record) => (
        <span>
          {record.credits_used} / {record.credits}
        </span>
      ),
    },
    {
      title: t('screens:finances.data.user'),
      dataIndex: 'user',
      align: 'left',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '210px',
      ellipsis: { showTitle: true },
      render: user => <UsersInfo users={[user]} />,
    },
    {
      title: t('screens:finances.data.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '100px',
      ellipsis: { showTitle: true },
      // format date and time to pt-br, using moment library, put data in a span tag and time in another span tag below it
      render: text => (
        <span>
          {moment(text).format('DD/MM/YYYY')} <br />{' '}
          <span style={{ color: '#999' }}>{moment(text).format('HH:mm:ss')}</span>
        </span>
      ),
    },

    {
      // Mobile',
      dataIndex: 'id',
      align: 'left',
      key: 'id',
      responsive: ['xs'],
      render: (text, record) => (
        <Space size={0.5} direction="vertical" style={{ display: 'flex' }}>
          <UsersInfo users={[record.user]} />
          <span>
            <b>
              {t('screens:finances.data.package_data')}
              {': '}
            </b>
            <span>{record.package_data.name}</span>
          </span>
          <span>
            <b>
              {t('screens:finances.data.credits')}
              {': '}
            </b>
            <span>
              {record.credits_used} / {record.credits}
            </span>
          </span>
          <span>
            <b>
              {t('screens:finances.data.created_at')}
              {': '}
            </b>
            <span>{moment(record.created_at).format('DD/MM/YYYY HH:mm:ss')} </span>
          </span>
          <span>
            <b>
              {t('screens:finances.data.status')}
              {': '}
            </b>{' '}
            <span>{getProjectStatusTag(record.status)}</span>
          </span>
        </Space>
      ),
    },
  ];

  const expandedRowRender = record => {
    console.log('Finances/index.js => expandedRowRender', record);
    return (
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Row gutter={16}>
          <Col span={24}>
            {/* <Space direction="vertical" style={{ display: 'flex' }}> */}
            <Descriptions
              title={<Title level={5}>DETALHES DA TRANSAÇÃO</Title>}
              layout="vertical"
              bordered={true}
              colon={false}
              size="small"
              column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="ORDEM">
                <b>Id: </b> {record.id}
                <br />
                {/* <b>Ordem Id: </b> {record.order_id}
              <br /> */}
                <b>Criada em </b>{' '}
                {`${new Date(record.created_at).toLocaleDateString()} às ${new Date(
                  record.created_at
                ).toLocaleTimeString()}`}
                <br />
                <b>Alterada em: </b>{' '}
                {`${new Date(record.updated_at).toLocaleDateString()} às ${new Date(
                  record.updated_at
                ).toLocaleTimeString()}`}
              </Descriptions.Item>
              <Descriptions.Item label="ITEM">
                <b>Nome: </b> {record?.package_data?.name}
                <br />
                <b>Créditos: </b> {record?.package_data?.credits}
                <br />
                <b>Descrição: </b> {record?.package_data?.description}
                <br />
                <b>Dias: </b> {record?.package_data?.days}
                <br />
                <b>Valor original: </b> {formatPrice(record?.package_data?.price)}
                <br />
                {record?.coupon?.code && (
                  <>
                    <b>Cupom: </b> {record.coupon.code}
                    <br />
                    <b>Desconto:</b> {record.coupon.discount}%
                    <br />
                    <b>Valor final</b> {formatPrice(record.coupon.package_disconted_value)}
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="CLIENTE">
                <b>Nome: </b> {record?.user?.name}
                <br />
                <b>Tipo: </b> {t('screens:users.data.type.' + record?.user?.type)}
                <br />
                <b>E-mail: </b> {record?.user?.email}
                <br />
                <b>CPF/CNPJ: </b> {record?.user?.cpf || record?.user?.cnpj}
              </Descriptions.Item>
              {record.user.type !== 'ADMIN' ? (
                <Descriptions.Item label="ENDEREÇO E TELEFONE">
                  <b>Telefone: </b> {record.user?.address?.telefone} <br />
                  <b>Logradouro: </b>
                  {record.user?.address?.endereco} <br />
                  <b>Número: </b> {record.user?.address?.numero} <br />
                  <b>Localidade: </b> {record.user?.address?.bairro} <br />
                  <b>Cidade: </b> {record.user?.address?.cidade}-{record.user?.address?.estado} <br />
                  <b>CEP: </b> {record.user?.address?.cep}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            {/* </Space> */}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>COBRANÇAS</Col>
          {record.charges !== null ? (
            <>
              {record.charges.map(charge => (
                <>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    {/* CHARGES */}
                    <Card
                      title={<Text style={{ fontSize: 14 }}>ID {charge.id}</Text>}
                      bordered
                      bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                      style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                    >
                      <Descriptions
                        layout="horizontal"
                        bordered
                        column={1}
                        contentStyle={{ backgroundColor: '#ffffff' }}
                        labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, width: 260, fontWeight: 'bold' }}
                        size="small"
                      >
                        <Descriptions.Item label="Id">{charge?.txid}</Descriptions.Item>
                        <Descriptions.Item label="Status">{getProjectStatusTag(charge?.status)}</Descriptions.Item>
                        <Descriptions.Item label="Devedor CNPJ/CPF">{charge?.devedor.cnpj}</Descriptions.Item>
                        <Descriptions.Item label="Devedor Nome">{charge?.devedor.nome}</Descriptions.Item>
                        <Descriptions.Item label="Paga em">
                          {charge?.pix[0]?.horario
                            ? `${new Date(charge?.pix[0]?.horario).toLocaleDateString()} às ${new Date(
                                charge?.pix[0]?.horario
                              ).toLocaleTimeString()}`
                            : 'N/A'}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Paga em">{`${new Date(charge?.pix?.horario).toLocaleDateString()} às ${new Date(charge?.pix?.horario).toLocaleTimeString()}`}</Descriptions.Item> */}
                        <Descriptions.Item label="Criada em">{`${new Date(
                          charge?.loc?.criacao
                        ).toLocaleDateString()} às ${new Date(
                          charge?.loc?.criacao
                        ).toLocaleTimeString()}`}</Descriptions.Item>

                        {charge?.pix[0]?.devolucoes ? (
                          <>
                            {charge?.pix[0]?.devolucoes.map((devolucao, index) => (
                              <Descriptions.Item label={`Devolução ${index + 1}`} key={devolucao.id}>
                                <b>Id: </b> {devolucao.id}
                                <br />
                                <b>Valor: </b> {devolucao.valor}
                                <br />
                                <b>Status: </b> {devolucao.status}
                                <br />
                                <b>Horário: </b> {devolucao.horario.solicitacao}
                                <br />
                                <b>Natureza: </b> {devolucao.natureza}
                                <br />
                                <b>Descrição: </b> {devolucao.descricao}
                              </Descriptions.Item>
                            ))}
                          </>
                        ) : null}

                        {/* <Descriptions.Item label="Descrição">{charge?.description}</Descriptions.Item>
                      <Descriptions.Item label="Reference Id">{charge?.reference_id}</Descriptions.Item> */}
                        <Descriptions.Item label="Quantia">
                          <b>Valor:</b> {formatPrice(charge?.pix[0]?.componentesValor.original.valor)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Método de Pagamento">
                          <>
                            <Tag color="blue">PIX</Tag>
                          </>
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  </Col>
                </>
              ))}
            </>
          ) : null}
        </Row>
      </Space>
    );
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/credits">{t('menus:credits')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:finances')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:finances.title')} style={style.pageTitle} />
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                exportVenda(filters);
              }}
              style={style.btnNew.btn}
            >
              <DownloadOutlined />
              {t('screens:customers.btnExport')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          expandable={{ expandedRowRender }}
          onChange={handleTableChange}
          scroll={{ x: 1000 }}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
    </DefautLayout>
  );
}
