import React, { useEffect, useRef, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button as ButtonAntd, Checkbox } from 'antd';
import * as Yup from 'yup';
import { rememberCredentials, signInRequest } from '~/store/modules/auth/actions';
import {
  Container,
  Box,
  Copyright,
  Layout,
  Body,
  ContainerAll,
  ContainerLeft,
  ContainerImg,
  Title,
  DefaultParag,
  DefaultList,
  ContainerVideo,
  ContainerRight,
  ContainerContacts,
  ContainerLogin,
  ContainerIcons,
  ContainerText,
  UnionContacts,
} from './styles';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import { CopyrightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import FooterCustom from '~/components/Footer';
import history from '~/services/history';
import { Modal } from 'antd';

export default function Welcome() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalKey, setModalKey] = useState(0);

  var username = useSelector(state => state.auth.rememberUsername);
  var password = useSelector(state => state.auth.rememberPassword);
  var checked = useSelector(state => state.auth.checked);

  const schema = Yup.object().shape({
    username: Yup.string()
      .typeError(t('fields:insertYourEmail'))
      .email(t('fields:insertValidEmail'))
      .required(t('fields:insertYourEmail')),
    password: Yup.string(t('fields:insertYourPassword'))
      .typeError(t('fields:insertYourPassword'))
      .required(t('fields:insertYourPassword')),
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  function handleRemember({ target }) {
    var checked = target.checked;
    checked ? (username = document.getElementsByName('username')['0'].value) : (username = '');
    checked ? (password = document.getElementsByName('password')['0'].value) : (password = '');
    dispatch(rememberCredentials(username, password, checked));
  }

  function handleSubmit({ username, password }) {
    dispatch(signInRequest(username, password));
    setTimeout(function() {
      setLoading(false);
    }, 1000);
  }

  const currentYear = new Date().getFullYear();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalKey(prevKey => prevKey + 1); // Incrementa a chave do modal
  };

  const handleWidthModel = () => {
    // Inicializa o objeto sizeModel com as dimensões padrão
    const sizeModel = { width: '1200', height: '600' };

    // Atualiza as dimensões com base na largura da janela
    if (windowWidth <= 2560) {
      sizeModel.width = '1600';
      sizeModel.height = '900';
    }
    if (windowWidth <= 1920) {
      sizeModel.width = '1200';
      sizeModel.height = '700';
    }
    if (windowWidth <= 1600) {
      sizeModel.width = '1000';
      sizeModel.height = '600';
    }
    if (windowWidth <= 1536) {
      sizeModel.width = '800';
      sizeModel.height = '400';
    }
    if (windowWidth <= 1366) {
      sizeModel.width = '800';
      sizeModel.height = '400';
    }

    // Retorna o objeto sizeModel atualizado
    return sizeModel;
  };

  return (
    <>
      <Body>
        <ContainerAll>
          <ContainerLeft>
            <ContainerImg>
              <img src="logo_webvin_x2-removebg-preview.png"></img>
            </ContainerImg>
            <ContainerText>
              <Title>SISTEMA ALOCT - LOCALIZAÇÃO E PADRÃO DE GRAVAÇÕES IDENTIFICADORAS VEICULARES</Title>
              <DefaultParag>
                A WEBVIN soluções apresenta o sistema ALOCT, que facilitará o trabalho de identificação veicular,
                diminuindo o tempo e oferecendo segurança nos processos de vistorias de veículos com até 3.500 kg.
              </DefaultParag>
              <DefaultParag>
                É o maior banco de dados de localizações de agregados do Brasil. Com o sistema ALOCT é possível:{' '}
              </DefaultParag>
              <DefaultList>
                1. Conhecer as localizações de numerações de:
                <li>
                  <FaCheck /> Chassi;
                </li>
                <li>
                  <FaCheck /> Motor;
                </li>
                <li>
                  <FaCheck /> Câmbio;
                </li>
              </DefaultList>
              <DefaultList>
                2. Conhecer os padrões de gravação e impressão de:
                <li>
                  <FaCheck /> Chassi;
                </li>
                <li>
                  <FaCheck /> Motor;
                </li>
                <li>
                  <FaCheck /> Câmbio;
                </li>
                <li>
                  <FaCheck /> Etiquetas do VIS (ETA);
                </li>
                <li>
                  <FaCheck /> Gravações de vidros;
                </li>
                <li>
                  <FaCheck /> Plaquetas de carroçaria;
                </li>
                <li>
                  <FaCheck /> Plaquetas de chassi;
                </li>
              </DefaultList>
              <DefaultParag>
                Tenha agilidade e segurança em suas perícias, vistorias e avaliações, consulte o ALOCT.
              </DefaultParag>
            </ContainerText>
            <ContainerVideo>
              <Button color="primary" onClick={showModal}>
                Clique aqui e veja o vídeo explicativo em tela cheia
              </Button>
              <Modal
                key={modalKey}
                title="Vídeo Explicativo"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <iframe
                  width={handleWidthModel().width}
                  height={handleWidthModel().height}
                  src="https://www.youtube.com/embed/9d_6nIlvWzM?autoplay=1&vq=hd1080&modestbranding=1"
                  title="Sistema AlocT - Localização e Padrão de gravações identificadoras veiculares"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Modal>
              <Button color="primary">
                <a
                  href="/MANUAL-DO-SISTEMA-ALOCT(3).pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none', // Remove sublinhado
                    color: 'inherit', // Usa a cor padrão do texto
                    cursor: 'pointer', // Muda o estilo do cursor ao passar o mouse
                  }}
                >
                  Acesse o manual de uso do sistema
                </a>
              </Button>
            </ContainerVideo>
          </ContainerLeft>
          <ContainerRight>
            <ContainerContacts>
              <UnionContacts>
                <h3>TEL/WHATSAPP: 19 - 99603-6985</h3>
                <h3>EMAIL: contato@webvin.com.br</h3>
              </UnionContacts>
            </ContainerContacts>
            <ContainerLogin>
              <Layout>
                <Box>
                  <Formik
                    initialValues={{ username, password }}
                    onSubmit={values => {
                      handleSubmit(values);
                      setLoading(true);
                    }}
                    validationSchema={schema}
                    style={{ width: '100%' }}
                    className="formik"
                  >
                    {({ errors, touched }) => (
                      <Container>
                        <Logo title={false} width="100%" height="100%" margin="auto" marginBottom="30px" />

                        <FormControl field="username" error={touched.username && errors.username}>
                          <Input
                            type="email"
                            name="username"
                            size="small"
                            prefix={<UserOutlined />}
                            placeholder={t('screens:login.username.placeholder')}
                          />
                        </FormControl>
                        <FormControl field="password" error={touched.password && errors.password}>
                          <Input.Password
                            type="password"
                            name="password"
                            size="small"
                            prefix={<LockOutlined />}
                            placeholder={t('screens:login.password.placeholder')}
                          />
                        </FormControl>
                        <div style={{ textAlign: 'left' }}>
                          <Checkbox
                            id="checkbox"
                            style={{ fontSize: '12px' }}
                            checked={checked}
                            onChange={handleRemember}
                          >
                            <span style={{ color: '#a1a1a1' }}>{t('screens:login.rememberMe')} </span>
                          </Checkbox>
                          <span style={{ float: 'right', marginBottom: '10px' }}>
                            <Link to="/password/link">{t('screens:login.forgotPassword')}</Link>
                          </span>
                        </div>

                        <Button type="submit" id="login-button" loading={loading} color="primary">
                          {t('screens:login.submit')}
                        </Button>

                        <ButtonAntd
                          type="link"
                          style={{ color: '#62a159' }}
                          onClick={() => {
                            history.push(`/signup`);
                          }}
                        >
                          {t('screens:login.signUp')}
                        </ButtonAntd>
                      </Container>
                    )}
                  </Formik>
                </Box>
                <Copyright>
                  <span>{t('fields:copyright.copyright')}</span>
                  <CopyrightOutlined />
                  {` ${currentYear} | `}
                  <span>{t('fields:copyright.produced')}</span>
                </Copyright>
                <FooterCustom />
              </Layout>
            </ContainerLogin>
            <ContainerIcons>
              <a href="https://api.whatsapp.com/send?phone=19996036985" target="_blank">
                <img src="/whatsapp.png" alt="Icone Whatsapp" />
              </a>
              <a href="mailto:contato@webvin.com.br">
                <img src="/e-mail.png" alt="Icone Whatsapp" />
              </a>
            </ContainerIcons>
          </ContainerRight>
        </ContainerAll>
      </Body>
    </>
  );
}
