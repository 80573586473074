import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Col, Breadcrumb, Switch } from 'antd';
import DefaultLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';
import { deletePlan, updatePlan } from '~/services/hooks/plans';
import PlansForm from './form';
import { formatPrice } from '~/Utils/format';
import { paginationPlans } from '~/services/hooks/plans';
import Tour from 'reactour';

export default function Plans() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [showTour, setShowTour] = useState(false);

  const fetchData = async page => {
    setLoading(true);
    const { data, meta } = await paginationPlans(page, {});
    setMeta(meta);
    setTableData(data);
    setLoading(false);
  };

  const handleDelete = async id => {
    await deletePlan(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const handlePause = async record => {
    record.status = !record.status;
    record.status = record.status ? 1 : 0;
    const data = await updatePlan(record);
    onUpdateItem(data);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    if (sorter.field !== sortBy || sortOrder !== sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
    switch: { marginTop: '8px' },
  };

  const tableColumns = [
    {
      title: t('screens:plans.data.name'),
      dataIndex: 'name',
      key: 'name',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('screens:plans.data.days'),
      dataIndex: 'days',
      key: 'days',
      responsive: ['md', 'lg', 'xl', 'xxl'],
      align: 'center',
      render: text => text + ' dias',
    },
    {
      title: t('screens:plans.data.credits'),
      dataIndex: 'credits',
      key: 'credits',
      responsive: ['md', 'lg', 'xl', 'xxl'],
      align: 'center',
    },
    {
      title: t('screens:plans.data.price'),
      dataIndex: 'price',
      key: 'price',
      responsive: ['md', 'lg', 'xl', 'xxl'],
      align: 'center',
      render: text => formatPrice(text),
    },
    {
      title: t('screens:users.data.actions'),
      key: 'actions',
      align: 'center',
      responsive: ['sm'],
      width: '10px',
      render: (text, record) => (
        <TableActions>
          <Switch size="small" style={style.switch} checked={record.status} onChange={() => handlePause(record)} />
          <Button style={style.tableAction} onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button style={style.tableAction} title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
    //Mobile
    {
      title: t('menus:packages'),
      key: 'actions',
      align: 'center',
      width: '10px',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <p>{record.name}</p>
          <p>
            <span style={{ color: 'gray' }}>{record.days} dias</span>
            <br />
            <span>{record.credits} créditos</span>
            <br />
            <span style={{ color: 'gray' }}>{formatPrice(record.price)}</span>
            <br />
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              <Switch size="small" style={style.switch} checked={record.status} onChange={() => handlePause(record)} />
              <Button style={style.tableAction} onClick={() => handleEdit(record)} title={t('messages:edit')}>
                <EditOutlined />
              </Button>

              <Popconfirm
                icon={false}
                title={t('messages:confirmDelete')}
                onConfirm={() => handleDelete(record.id)}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button style={style.tableAction} title={t('messages:delete')}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </TableActions>
          </div>
        </div>
      ),
    },
  ];
  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:packages')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <div>
        <PageTitle title={t('menus:packages')} />
        {/* put margin botton equal to 15px */}
        <Button onClick={() => setShowTour(true)} color="primary" style={{ marginBottom: '15px' }} >Ajuda</Button>
        <Tour
          steps={[
            {
              selector: '[data-tut="reactour__table"]',
              content: 'Está é a lista de planos',
            },
            {
              selector: '[data-tut="reactour__new"]',
              content: 'Clique aqui para adicionar um novo plano',
            },
          ]}
          isOpen={showTour}
          onRequestClose={() => setShowTour(false)}
        />
      </div>

      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              data-tut='reactour__new'
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:plans.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          data-tut='reactour__goTo'
          bordered
          size="small"
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}
        />

        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        <PlansForm
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </DefaultLayout>
  );
}
