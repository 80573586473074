import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, ContainerMobile } from './styles';

export default function Pagination({ children, meta, ...props }) {
  const [windowWidth, setWindowWidth] = useState(null);
  const { t } = useTranslation();
  const { first, last_page, numberOfElements, total, current_page, per_page } = meta;
  const from = first === true ? 1 : (current_page - 1) * per_page + 1;
  const to = first === true ? numberOfElements : last_page === true ? total : current_page * per_page;

  const sizeOfThings = () => {
    var windowWidth = window.visualViewport.width;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', sizeOfThings, { passive: true });

  useEffect(() => {
    sizeOfThings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {windowWidth < 700 ? (
        <>
          {total > 0 && (
            <ContainerMobile
              {...props}
              total={total}
              defaultPageSize={per_page}
              current={current_page}
            >
              {children}
            </ContainerMobile>
          )}
        </>
      ) : (
        <>
          {total > 0 && (
            <Container
              {...props}
              showTotal={() =>
                `${t('screens:pagination.showing')} ${from} ${t('screens:pagination.to')} ${to} ${t(
                  'screens:pagination.of'
                )} ${total} ${t('screens:pagination.records')}`
              }
              total={total}
              defaultPageSize={per_page}
              current={current_page}
            >
              {children}
            </Container>
          )}
        </>)
      }
    </>
  );
}

Pagination.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

Pagination.defaultProps = {
  children: null,
  from: 0,
  to: 0,

  first: false,
  last_page: false,
  numberOfElements: 0,
  total: 0,
  current_page: 1,
  per_page: 10,
};
