import React, { useEffect, useState } from 'react';
import PageTitle from '~/components/PageTitle';
import DefaultLayout from '../_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import { Breadcrumb, Col, Popconfirm, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import { deleteCoupon, paginationCoupons } from '~/services/hooks/coupons';
import Row from '~/components/Row';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, TableActions } from '~/components/Table';
import CouponForm from './form';
import Filter from './filter';

export default function Coupons() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:coupons')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  const fetchData = async page => {
    setLoading(true);

    const data = await paginationCoupons(page, filters);

    setMeta(data.meta);
    setTableData(data.data);
    setLoading(false);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
    fetchData(1);
  };

  const handleDelete = async id => {
    await deleteCoupon(id);
    fetchData(1);
  };

  const handleTableChange = sorter => {
    console.log('Coupons/index.js => handleTableChange', sorter);
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';

    if (sorter.field !== sortBy || sortOrder !== sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortBy]);

  const tableColumns = [
    {
      title: t('screens:coupons.data.id'),
      dataIndex: 'id',
      key: 'id',
      responsive: ['lg', 'xl', 'xxl'],
      width: '50px',
      align: 'center',
      className: 'table-id',
    },
    {
      title: t('screens:coupons.data.code'),
      dataIndex: 'code',
      key: 'code',
      responsive: ['lg', 'xl', 'xxl'],
      width: '300px',
      align: 'left',
      justifyContent: 'center',
    },
    {
      title: t('screens:finances.data.user'),
      key: 'user',
      align: 'left',
      justifyContent: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      ellipsis: { showTitle: true },
      render: record => {
        if (record.user === null || record.user === undefined) {
          return <p style={{ fontStyle: 'italic', color: 'gray', marginTop: '3%' }}>Cupom ainda não utilizado</p>;
        }

        return <p style={{ marginTop: '3%' }}>{record.user.name}</p>;
      },
    },
    {
      title: t('screens:coupons.data.packageName'),
      key: 'packageName',
      align: 'left',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      ellipsis: { showTitle: true },
      render: record => {
        if (record.package === null || record.package === undefined) {
          return <p style={{ fontStyle: 'italic', color: 'gray', marginTop: '3%' }}>Cupom ainda não utilizado</p>;
        }

        return <p style={{ marginTop: '3%' }}>{record.package.name}</p>;
      },
    },
    {
      title: t('screens:coupons.data.discount'),
      key: 'discount',
      align: '',
      justifyContent: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      ellipsis: { showTitle: true },
      width: '100px',
      render: record => {
        return <p style={{ marginTop: '20%' }}>{record.discount}%</p>;
      },
    },
    {
      title: t('screens:customers.data.cadastro'),
      dataIndex: 'register',
      key: 'register',
      align: 'center',
      responsive: ['lg', 'xl', 'xxl'],
      width: '200px',
    },
    {
      title: t('screens:customers.data.actions'),
      key: 'actions',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      width: '110px',
      render: (text, record) => (
        <TableActions>
          <Switch size="small" style={style.switch} checked={record.active} disabled={true} />
          <Button
            style={style.tableAction}
            onClick={() => handleEdit(record)}
            title={t('messages:edit')}
            disabled={record.active === 0}
          >
            <EditOutlined />
          </Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button style={style.tableAction} title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
    {
      dataIndex: 'id',
      align: 'left',
      key: 'id',
      responsive: ['xs'],
      render: (text, record) => (
        <Space size={0.5} direction="vertical" style={{ display: 'flex' }}>
          <span>
            <b>
              {t('screens:coupons.data.id')}
              {': '}
            </b>
            <span>{record.id}</span>
          </span>
          <span>
            <b>
              {t('screens:coupons.data.code')}
              {': '}
            </b>
            <span>{record.code}</span>
          </span>
          <span>
            <b>
              {t('screens:finances.data.user')}
              {': '}
            </b>
            {record.user === null || record.user === undefined ? (
              <p style={{ fontStyle: 'italic', color: 'gray' }}>Cupom ainda não utilizado</p>
            ) : (
              <p>{record.user.name}</p>
            )}
          </span>
          <span>
            <b>
              {t('screens:coupons.data.packageName')}
              {': '}
            </b>
            {record.package === null || record.package === undefined ? (
              <p style={{ fontStyle: 'italic', color: 'gray' }}>Cupom ainda não utilizado</p>
            ) : (
              <p>{record.package.name}</p>
            )}
          </span>
          <span>
            <b>
              {t('screens:coupons.data.discount')}
              {': '}
            </b>
            <span>{record.discount}</span>
          </span>
          <span>
            <b>
              {t('screens:customers.data.cadastro')}
              {': '}
            </b>
            <span>{record.register} </span>
          </span>
          <span>
            <b>
              {t('screens:customers.data.actions')}
              {': '}
            </b>{' '}
            <TableActions>
              <Switch size="small" style={style.switch} checked={record.active} disabled={true} />
              <Button
                style={style.tableAction}
                onClick={() => handleEdit(record)}
                title={t('messages:edit')}
                disabled={record.active === 0}
              >
                <EditOutlined />
              </Button>

              <Popconfirm
                icon={false}
                title={t('messages:confirmDelete')}
                onConfirm={() => handleDelete(record.id)}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button style={style.tableAction} title={t('messages:delete')}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </TableActions>
          </span>
        </Space>
      ),
    },
  ];

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { backgroundColor: '#52c41a' },
    inactive: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
    colAvatar: { paddingRight: '0px', width: '20px' },
    colData: { width: '180px', paddingLeft: '26px' },
    switch: { marginTop: '8px' },
  };

  return (
    <>
      <DefaultLayout breadcrumb={breadcrumb}>
        <PageTitle title={t('screens:coupons.title')} style={style.pageTitle} />
        <Filter isLoading={loading} setFilters={setFilters} />
        <Box>
          <Row>
            <Col span={24} style={style.btnNew.col}>
              <Button
                data-tut="reactour__new"
                loading={loading}
                color="primary"
                onClick={() => {
                  setSelectedRecord(null);
                  setShowForm(true);
                }}
                style={style.btnNew.btn}
              >
                <PlusOutlined />
                {t('screens:coupons.newCoupon')}
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            size="small"
            rowKey="id"
            pagination={false}
            loading={loading}
            columns={tableColumns}
            dataSource={tableData}
            onChange={handleTableChange}
          />
          <Pagination
            onChange={value => {
              fetchData(value);
            }}
            meta={meta}
          />
          <CouponForm
            selectedRecord={selectedRecord}
            visible={showForm}
            onClose={() => setShowForm(false)}
            onInsertItem={item => {
              setTableData([...tableData, item]);
              fetchData(1);
            }}
            onUpdateItem={item => onUpdateItem(item)}
          />
        </Box>
      </DefaultLayout>
    </>
  );
}
