import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Col, Typography } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import { findVenda } from '~/services/hooks/finances';
import { useParams } from 'react-router-dom';
import Row from '~/components/Row';
import { findAddress } from '~/services/hooks/users';

export default function Details() {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { id } = useParams();

  const [data, setData] = useState({
    id: 0,
    pacote_de_Venda: {
      id: 0,
      name: '',
      valor: '',
      descricao: '',
      consultas: 0,
      deleted_at: null,
      created_at: null,
      updated_at: null,
    },
    cliente: {
      id: 0,
      address_id: 0,
      files_id: null,
      name: '',
      email: '',
      email_verified_at: null,
      cpf: '',
      consultas_totais: 0,
      dias_restantes: 0,
      created_at: null,
      updated_at: null,
      deleted_at: null,
    },
    comprovante_venda: null,
  });

  const [address, setAddress] = useState({});

  const fetchData = async () => {

    let data = await findVenda(id);
    setData(data);

    data = await findAddress(data.cliente.address_id);
    setAddress(data);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { color: '#52c41a' },
    inactive: { color: 'red' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:financial')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:finances')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:finances.title')} style={style.pageTitle} />
      <Box>
        <Title level={4} style={{ color: '#4b4b4b' }}>
          Detalhes da transação
        </Title>
        <Row>
          <Col span={8}>
            <Title level={5} style={{ color: '#4b4b4b' }}>
              ORDER
            </Title>
            <p>
              <strong>Order id:</strong> {data.id}
            </p>
            <p>
              <strong>Criado em:</strong>
            </p>
            <p>
              <strong>Alterado em:</strong>
            </p>
            <p>
              <strong>Nome do cliente:</strong> {data.cliente.name}
            </p>
          </Col>
          <Col span={8}>
            <Title level={5} style={{ color: '#4b4b4b' }}>
              ITEM
            </Title>
            <p>
              <strong>Nome:</strong> {data.pacote_de_Venda.name}
            </p>
            <p>
              <strong>Valor unitário:</strong> {data.pacote_de_Venda.valor}
            </p>
          </Col>
          <Col span={8}>
            <Title level={5} style={{ color: '#4b4b4b' }}>
              ENDEREÇO DA COBRANÇA
            </Title>
            <p>
              <strong>Logradouro:</strong> {address.endereco}
            </p>
            <p>
              <strong>Número:</strong> {address.numero}
            </p>
            <p>
              <strong>Localidade:</strong> {address.bairro}
            </p>
            <p>
              <strong>Cidade:</strong> {address.cidade}
            </p>
            <p>
              <strong>País:</strong> BRA
            </p>
            <p>
              <strong>Código postal:</strong> {address.cep}
            </p>
          </Col>
        </Row>

        <Title level={4} style={{ color: '#4b4b4b' }}>
          Cobranças
        </Title>
        <p>
          <strong>Charger id:</strong>
        </p>
        <p>
          <strong>Status:</strong>
        </p>
        <p>
          <strong>Paga em:</strong>
        </p>
        <p>
          <strong>Criada em:</strong>
        </p>
        <p>
          <strong>Descrição:</strong>
        </p>
        <p>
          <strong>Reference id:</strong>
        </p>
        <p>
          <strong>Quantia (valor):</strong>
        </p>
        <p>
          <strong>Total:</strong>
        </p>
        <p>
          <strong>Pago:</strong>
        </p>
        <p>
          <strong>Reembolsado:</strong>
        </p>

        <br />
        <br />
        <br />
        <p>
          <strong>Método de pagamento:</strong> PIX
        </p>
        <p>
          <strong>Nome do titular:</strong>
        </p>
      </Box>
    </DefautLayout>
  );
}
