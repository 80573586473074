import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Switch, Input } from 'formik-antd';
import { Modal, Spin, Col, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile } from '~/store/modules/user/actions';
import { createUser, findByIdUser, updateUser } from '~/services/hooks/users';
import { Container } from './styles';

const initialValues = {
  id: null,
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  newPassword: '',
  password_confirmation: '',
  image: null,
  status: true,
  user_type: 'pf',
  type: 'ADMIN',
  role_id: '',
  newPasswordConfirmReq: false,
  isAdmin: true,
};

export default function UserForm({ selectedRecord, visible, disabledImage, onClose, onUpdateItem, onInsertItem }) {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const type = useSelector(state => state?.user?.profile?.type);

  const fetchRecords = async () => {
    setLoading(true);

    let admin = type && type === 'ADMIN';

    try {
      if (selectedRecord === null) {
        const initValues = {
          id: null,
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          newPassword: '',
          password_confirmation: '',
          newPasswordConfirmReq: false,
          image: null,
          active: true,
          type: 'ADMIN',
          user_type: 'pf',
          isAdmin: admin,
        };
        setRecordData(initValues);
      } else {
        if (selectedRecord === profile.id) {
          profile.isAdmin = admin;
          profile.newPassword = '';
          profile.password_confirmation = '';
          profile.newPasswordConfirmReq = false;
          setRecordData(profile);
        } else {
          const data = await findByIdUser(selectedRecord.id);
          data.isAdmin = data.type === 'ADMIN';
          data.newPassword = '';
          data.password_confirmation = '';
          data.newPasswordConfirmReq = false;
          setRecordData(data);
        }
      }
    } catch(error) {
      message.error(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleSave = async values => {
    setLoading(true);

    let password = values.newPassword.length > 0 ? values.newPassword : values.password;

    let newUser = {
      id: values.id,
      name: values.name,
      email: values.email,
      password: password,
      image: values.image,
      active: values.active,
      type: values.type,
      user_type: values.user_type,
    };

    if (values.id !== null) {
      if (selectedRecord === profile.id) {
        // eslint-disable-next-line 
        const data = await findByIdUser(profile.id);
        // newUser.image = data.image;
      }

      const data = await updateUser(newUser);
      onUpdateItem && onUpdateItem(data);

      if (selectedRecord.toString() === profile.id.toString()) {
        dispatch(updateProfile(newUser));
      }
    } else {
      const data = await createUser(newUser);
      onInsertItem(data);
    }
    onClose();

    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().when('id', { is: null, then: Yup.string().required() }),
    confirmPassword: Yup.string().when('id', {
      is: null,
      then: Yup.string()
        .oneOf([Yup.ref('password'), null], t('differentPasswords'))
        .required(),
    }),
    password_confirmation: Yup.string().when('newPasswordConfirmReq', {
      is: true,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('differentPasswords'))
        .required(),
    }),
    user_type: Yup.string().required(),
    type: Yup.string().required(),
    active: Yup.bool().required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={userSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values }) => (
        <Modal
          width="580px"
          title={selectedRecord !== null ? t('screens:users.btnEdit') : t('screens:users.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Container>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title level={4} style={{ color: '#4b4b4b' }}>
                    {t('screens:users.data.personalInfo')}
                  </Title>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}
                      field="name"
                      label={t('screens:users.data.name')}
                      required
                      error={(touched.name && errors.name) || errors.name}
                    >
                      <Input name="name" disabled={!values.isAdmin && selectedRecord !== null} />
                    </FormControl>

                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                      field="active"
                      label={t('screens:users.data.active')}
                    >
                      <Switch
                        disabled={!values.isAdmin && selectedRecord !== null}
                        name="active"
                        checked={recordData.active === undefined ? true : recordData.active}
                        checkedChildren={t('messages:yes')}
                        unCheckedChildren={t('messages:no')}
                        onChange={e => {
                          recordData.active = e;
                        }}
                      ></Switch>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="email"
                      label={t('screens:users.data.email')}
                      required
                      error={(touched.email && errors.email) || errors.email}
                    >
                      <Input type="email" name="email" disabled={!values.isAdmin && selectedRecord !== null} />
                    </FormControl>
                  </Row>
                  {selectedRecord === null ? (
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="password"
                        label={t('screens:users.data.password')}
                        required
                        error={(touched.password && errors.password) || errors.password}
                      >
                        <Input name="password" type="password" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="confirmPassword"
                        label={t('screens:users.data.confirmPassword')}
                        required
                        error={(touched.confirmPassword && errors.confirmPassword) || errors.confirmPassword}
                      >
                        <Input name="confirmPassword" type="password" />
                      </FormControl>
                    </Row>
                  ) : (
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="newPassword"
                        label={t('screens:users.data.newPassword')}
                        required
                        error={(touched.newPassword && errors.newPassword) || errors.newPassword}
                      >
                        <Input
                          name="newPassword"
                          type="password"
                          onChange={e => {
                            setFieldValue('newPasswordConfirmReq', e.target.value.length > 0 ? true : false);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="password_confirmation"
                        label={t('screens:users.data.newPasswordConfirmation')}
                        required
                        error={
                          (touched.password_confirmation && errors.password_confirmation) ||
                          errors.password_confirmation
                        }
                      >
                        <Input name="password_confirmation" type="password" />
                      </FormControl>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
