import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

const endPoint = 'dashboard';

// FIND GRAFICO PACOTES/CRÈDITOS ANUAIS USER
export const findUsersTodos = async params => {
  try {
    const { data } = await api.get(`/${endPoint}/parausers/todos`, {
      params: {
        ano: params,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND VENDAS ANUAL USER LOGADO
export const findVendasUser = async params => {
  try {
    const { data } = await api.get(`/${endPoint}/totalvendidouser`, {
      params: {
        ano: params,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND GRAFICO PACOTES/CRÈDITOS ANUAIS USER
export const findUsersAtual = async () => {
  try {
    const { data } = await api.get(`/${endPoint}/parausers/hoje`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND CADASTRO DE USUÁRIOS ANUAL
export const findUsers = async params => {
  try {
    const { data } = await api.get(`/${endPoint}/getusers`, {
      params: {
        ano: params,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND VENDAS ANUAL
export const findVendas = async params => {
  try {
    const { data } = await api.get(`/${endPoint}/totalvendido`, {
      params: {
        ano: params,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const findCreditosUsados = async params => {
  try {
    const { data } = await api.get(`/${endPoint}/parausers/creditosusados`, {
      params: {
        ano: params,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};
