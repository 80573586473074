import React, { useEffect, useState } from 'react';
import { Badge, Button as ButtonAntd } from 'antd';
import UserAvatar from '~/components/UserAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signOutRequest } from '~/store/modules/auth/actions';
import { AntMenu, CreditsQtd, CustomAvatar, MenuGlobalStyle } from './styles';
import { BellOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import UserForm from '~/pages/Users/form';
import ClientForm from '~/pages/Users/formClient';
import Notifications from '~/pages/Notifications';
import { findUser } from '~/services/hooks/users';
import { updateProfile } from '~/store/modules/user/actions';

export default function HeaderMenu({ countNotifications, onView }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [showClientForm, setShowClientForm] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const profile = useSelector(state => (state.user.profile ? state.user.profile : null));
  const typeUser = profile?.type;
  const userName = profile?.name;
  const userId = profile?.id;
  const [totalCredits, setTotalCredits] = useState(0);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const prof = await findUser();
    dispatch(updateProfile(prof));
    setTotalCredits(prof.calculo_dias['totals']?.creditsRemainAllPackages || 0);
  };
  const checkAuth = roles => {
    return roles.includes(typeUser);
  };

  function handleNotifications() {
    setShowNotifications(true);
  }

  function handleProfile() {
    setShowForm(true);
  }

  function handleClientProfile() {
    setShowClientForm(true);
  }

  function handleSignOut() {
    dispatch(signOutRequest());
  }

  const renderMenu = item => {
    const { type, role, key } = item;
    if (type === 'divider') {
      return <AntMenu.Divider key={key} />;
    } else if (type === 'notifications' && checkAuth(role)) {
      return (
        checkAuth(item.role) && (
          <AntMenu.Item key={item.key} id="menu-notifications">
            <ButtonAntd type="link" onClick={item.click}>
              <Badge size="small" count={item.count}>
                <BellOutlined id="bell" style={{ fontSize: '20px', color: 'white' }} />
              </Badge>
            </ButtonAntd>
          </AntMenu.Item>
        )
      );
    } else if (type === 'sub') {
      return (
        checkAuth(role) && (
          <AntMenu.SubMenu
            key={key}
            title={
              item.profile !== null ? (
                <span>
                  <CustomAvatar>
                    <li id="avatar" style={{ marginRight: '14px' }}>
                      <UserAvatar style={{ marginRight: '0px' }} user={item.profile} size="default" />
                    </li>
                    <li style={{ color: 'white', width: '100px !important', textAlign: 'left' }}>
                      <span>{item.label}</span>
                      {item.profile?.type !== 'ADMIN' ? (
                        <>
                          <br />
                          <CreditsQtd>
                            {'Quantidade de créditos: '}
                            {totalCredits}
                          </CreditsQtd>
                        </>
                      ) : null}
                    </li>
                  </CustomAvatar>
                </span>
              ) : (
                <span>
                  {item.icon}
                  <span style={{ color: 'white' }}>{item.label}</span>
                </span>
              )
            }
          >
            {item.items.map(subItem => renderMenu(subItem))}
          </AntMenu.SubMenu>
        )
      );
    } else if (type === 'sub-item') {
      return checkAuth(role) ? (
        <AntMenu.Item key={key}>
          <span>{item.label}</span>
          <Link to={item.url} />
        </AntMenu.Item>
      ) : null;
    } else {
      return checkAuth(role) ? (
        <AntMenu.Item key={key}>
          {item.click && key === 'logout' ? (
            <Button id="btnLogout" onClick={item.click}>
              {item.icon}
              {item.label}
            </Button>
          ) : item.click && key === 'profile' ? (
            <Button id="btnProfile" onClick={item.click}>
              {item.icon}
              {item.label}
            </Button>
          ) : (
            <>
              {item.icon}
              <span>{item.label}</span>
              <Link to={item.url} />
            </>
          )}
        </AntMenu.Item>
      ) : null;
    }
  };

  const userMenuItems = [
    {
      type: 'notifications',
      key: 'notifications',
      role: ['CLIENT'],
      url: '/notifications',
      count: countNotifications,
      click: handleNotifications,
    },
    {
      type: 'sub',
      key: 'user',
      role: ['ADMIN', 'CLIENT'],
      label: userName !== null ? userName : 'Username',
      profile: profile,
      items: [
        {
          type: 'item',
          key: 'profile',
          role: ['CLIENT'],
          label: t('menus:user_profile'),
          icon: <UserOutlined style={{ marginLeft: '0px' }} />,
          click: handleClientProfile,
        },
        {
          type: 'item',
          key: 'profile',
          role: ['ADMIN'],
          label: t('menus:user_profile'),
          icon: <UserOutlined style={{ marginLeft: '0px' }} />,
          click: handleProfile,
        },
        {
          type: 'item',
          key: 'logout',
          role: ['ADMIN', 'CLIENT'],
          label: t('menus:user_logout'),
          icon: <LogoutOutlined style={{ marginLeft: '0px' }} />,
          click: handleSignOut,
        },
      ],
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      <AntMenu id="headerMenu" mode="horizontal" theme="light" style={{ marginLeft: 'auto' }}>
        {userMenuItems.map(item => renderMenu(item))}
      </AntMenu>
      <UserForm selectedRecord={userId} visible={showForm} disabledImage={false} onClose={() => setShowForm(false)} />
      <ClientForm
        selectedRecord={userId}
        visible={showClientForm}
        disabledImage={false}
        onClose={() => setShowClientForm(false)}
      />
      <Notifications visible={showNotifications} onClose={() => setShowNotifications(false)} onView={() => onView()} />
    </>
  );
}

HeaderMenu.defaultProps = {
  countNotifications: 0,
};
