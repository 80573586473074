import { Menu } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const AntMenu = styled(Menu)`
  height: 100%;
  border-right: 0;
  background: #e5e7e9;

  #sideMenu {
    background: #e5e7e9;
  }

  .ant-menu-light,
    .ant-layout-header,
    .ant-menu-light .ant-menu-sub, 
    .ant-menu.ant-menu-light .ant-menu-sub,
    .ant-menu.ant-menu-light,
    .ant-menu.ant-menu-light .ant-menu-sub  {
      background: #e5e7e9;
    }

    .ant-layout-sider-light {
      background: #e5e7e9;
    }

    .ant-layout-sider-light .ant-layout-sider-trigger {
      background: #e5e7e9;
    }
`;

export const MenuGlobalStyle = createGlobalStyle`
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d7e0d3;
  }

  .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
    border-right: 3px solid ${props => props.theme.color2};
  }

  .ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: ${props => props.theme.color2};
  }

  ::selection {
    color: #fff;
    background: ${props => props.theme.color2};
  }

  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: ${props => props.theme.color2};
  }

  .ant-menu-item.ant-menu-item-active.ant-menu-item-selected {
    color: #437a3d;
  }
`;