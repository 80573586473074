import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'search';

// FIND ALL SEARCHS BY PERSON
export const findAllPersonConsultas = async page => {
  try {
    const { data } = await api.get(`/${endPoint}/person`, {
      params: {
        ...{ page },
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL SEARCHS BY PERSON ID (CLIENT)
export const findAllPersonIdConsultas = async (page, id) => {
  try {
    const { data } = await api.get(`/${endPoint}/person/${id}`, {
      params: {
        ...{ page },
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createConsulta = async values => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);

    if (data.result?.Resposta?.struct_Resposta?.length > 0) {
      message.success(i18next.t(`messages:${endPoint}.success`));
    } else {
      message.warn(i18next.t(`messages:${endPoint}.badSearch`));
    }
    
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllConsultas = async (page, filters) => {
  try {
    const { data } = await api.get(`/${endPoint}`, {
      params: {
        page,
        ...filters,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

findAllConsultas.propTypes = {
  filters: PropTypes.object.isRequired,
};

createConsulta.propTypes = {
  values: PropTypes.object.isRequired,
};
