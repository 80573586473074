import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { FileDoneOutlined } from '@ant-design/icons';
import history from '~/services/history';

export default function CredMessage() {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <DefautLayout>
      <Box>
        <center>
          <FileDoneOutlined style={{ fontSize: '1500%', color: '#62a159' }} />

          <Title style={{ marginTop: '10px' }} level={2}>
            Obrigado!
          </Title>
          <p>
            Seu pedido foi recebido e está aguardando confirmação do pagamento. Você poderá receber um contato da nossa
            equipe para confirmar sua transação.
          </p>
        </center>

        <Button
          style={{ marginTop: '5%' }}
          color="primary"
          onClick={() => {
            history.push('/dashboard');
          }}
        >
          {t('Voltar para Home')}
        </Button>
      </Box>
    </DefautLayout>
  );
}
