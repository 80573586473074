import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Logo from '~/components/Logo';
import { Container, ContainerMobile } from './styles';

export default function Footer({ isMobile }) {
  const { t } = useTranslation();

  return (
    isMobile ? 
      <ContainerMobile>
        {t('app:poweredBy')} <a href="https://www.w2z.com.br/"><Logo width="20" height='20' w2z /></a> | {t('app:version')}
      </ContainerMobile>
    :
    <Container>
        {t('app:poweredBy')} <a href="https://www.w2z.com.br/"><Logo width="20" height='20' w2z /></a> | {t('app:version')}
    </Container>
  );
}
Footer.propTypes = {
  isMobile: PropTypes.bool
};

Footer.defaultProps = {
  isMobile: false
};
