import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer } = Layout;

export const Container = styled(Footer)`
  background: 'none';
  textAlign: 'bottom';
  color: #999;
  display: flex;
  align-items: center;
  bottom: 0;
  justify-content: center;
  padding: 20px 0;
  .logo {
    margin: 0px 7px 0px 4px;
  }
`;

export const ContainerMobile = styled(Footer)`
  background: 'none';
  textAlign: 'bottom';
  font-size: 10px;
  color: #999;
  display: flex;
  align-items: center;
  bottom: 0;
  justify-content: center;
  padding: 20px 0;
  .logo {
    margin: 0px 7px 0px 4px;
  }
`;