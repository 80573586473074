import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Select, Input, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import { FormFilter } from './styles';
import { paginationPlans } from '~/services/hooks/plans';
import { format } from 'date-fns';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;
  const [packages, setPackages] = useState([]);

  const fetchPackage = async page => {
    const data = await paginationPlans(page, {});
    setPackages(data.data);
  };

  const handleSearch = values => {
    const filter = {};
    filter.type = 'ADMIN';
    try {
      if (values.codeFilter !== undefined && values.codeFilter !== null && values.codeFilter !== '') {
        filter.code = values.codeFilter;
      }
      if (values.userNameFilter !== undefined && values.userNameFilter !== null && values.userNameFilter !== '') {
        filter.user_name = values.userNameFilter;
      }
      if (
        values.packageNameFilter !== undefined &&
        values.packageNameFilter !== null &&
        values.packageNameFilter !== ''
      ) {
        filter.package_name = values.packageNameFilter;
      }
      console.log(values);
      if (values.dataFilter !== undefined && values.dataFilter !== null && values.dataFilter !== '') {
        filter.created_at = format(new Date(values.dataFilter), 'yyyy-MM-dd');
      }
      if (values.statusFilter !== undefined && values.statusFilter !== null && values.statusFilter !== '') {
        filter.active = values.statusFilter;
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  const status = [
    {
      id: 2,
      code: '',
      description: t('screens:status.all'),
    },
    {
      id: 1,
      code: 1,
      description: t('screens:status.active'),
    },
    {
      id: 0,
      code: 0,
      description: t('screens:status.inactive'),
    },
  ];

  useEffect(() => {
    fetchPackage(1);
  }, []);

  return (
    <Box>
      <Formik
        initialValues={{ codeFilter: '', userNameFilter: '', packageNameFilter: '', dataFilter: '', statusFilter: '' }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <FormFilter>
              <Row>
                <FormControl
                  cols={{ xs: 14, sm: 14, md: 6, lg: 7, xl: 6, xxl: 4 }}
                  error={errors.codeFilter}
                  field="codeFilter"
                  label={t('screens:coupons.data.code')}
                >
                  <Input name="codeFilter" placeholder={t('screens:coupons.data.codePlaceholder')} />
                </FormControl>
                <FormControl
                  cols={{ xs: 14, sm: 14, md: 6, lg: 7, xl: 6, xxl: 4 }}
                  error={errors.userNameFilter}
                  field="userNameFilter"
                  label={t('screens:users.data.userName')}
                >
                  <Input name="userNameFilter" placeholder={t('screens:users.name.placeholder')} />
                </FormControl>
                <FormControl
                  cols={{ xs: 18, sm: 18, md: 8, lg: 9, xl: 6, xxl: 4 }}
                  error={errors.packageNameFilter}
                  field="packageNameFilter"
                  label={t('screens:finances.data.name')}
                >
                  <Select name="packageNameFilter" value="Selecione um pacote">
                    {packages.map(item => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  cols={{ xs: 18, sm: 8, md: 8, lg: 5, xl: 4, xxl: 3 }}
                  error={errors.dataFilter}
                  field="dataFilter"
                  label={t('screens:finances.data.date')}
                  placeholder="Seleciona a data"
                >
                  <DatePicker
                    name="dataFilter"
                    format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']}
                    placeholder="Selecione a data"
                  />
                </FormControl>
                <Col xs={18} sm={11} md={8} lg={4} xl={4}>
                  <FormControl error={errors.statusFilter} field="statusFilter" label={t('screens:users.data.status')}>
                    <Select name="statusFilter" defaultValue="">
                      {status.map(item => {
                        return (
                          <Option key={item.id} value={item.code}>
                            {item.description}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </FormFilter>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
