import React, { useState, useEffect } from 'react';
import { Modal, Spin, Popconfirm, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { deleteNotification, paginationNotifications, readNotification, unReadNotification } from '~/services/hooks/notifications';
import { Table, TableActions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import Button from '~/components/Button';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FaRegEnvelope, FaRegEnvelopeOpen } from 'react-icons/fa';

export default function Notifications({ visible, onClose, onView }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [meta, setMeta] = useState({});

    const fetchData = async page => {
        setLoading(true);
        const data = await paginationNotifications(page, 0);
        setMeta(data.meta);
        setTableData(data.data);
        setLoading(false);
    };

    const handleDelete = async id => {
        setLoading(true);
        await deleteNotification(id);
        fetchData(1);
        setLoading(false);
        onView();
    };

    const handleNotView = async notification => {
        setLoading(true);
        notification.viewed = false;
        await unReadNotification(notification);
        setLoading(false);
        onView();
    };

    const handleView = async notification => {
        setLoading(true);
        notification.viewed = true;
        await readNotification(notification);
        setLoading(false);
        onView();
    };

    useEffect(() => {
        if (visible) {
            fetchData(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const style = {
        tableAction: { padding: '0 5px' },
    };

    const tableColumns = [
        //MOBILE
        {
            title: t('screens:notifications.data.message'),
            dataIndex: 'message',
            key: 'message',
            align: 'left',
            responsive: ['xs'],
            render(text, record) {
                return {
                    props: {
                        style: { 
                            backgroundColor: record.viewed ? "#fbfbfa" : "",
                            fontWeight: record.viewed ? '' : 'bold'
                        }
                    },
                    children: 
                        <Space size={0.1} direction="vertical" style={{ width: '100%' }}>
                            <div style={{ fontSize: '10px', color: 'gray' }}>{moment(record.date).format((moment(record.date).format('DD/MM/YYYY')) === moment(new Date()).format('DD/MM/YYYY') ? 'HH:mm' : 'DD/MM/YY')}</div>
                            <div style={{ width: '100%' }}>{record.message}</div>
                        </Space>
                };
            }
        },
        {
            title: t('screens:notifications.data.message'),
            dataIndex: 'message',
            key: 'message',
            align: 'left',
            responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
            render(text, record) {
                return {
                    props: {
                        style: { 
                            backgroundColor: record.viewed ? "#fbfbfa" : "",
                            fontWeight: record.viewed ? '' : 'bold'
                        }
                    },
                    children: <span>{text}</span>
                };
            }
        },
        {
            title: t('screens:notifications.data.date'),
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: '100px',
            responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
            render(text, record) {
                return {
                    props: {
                        style: { 
                            backgroundColor: record.viewed ? "#fbfbfa" : "",
                            fontWeight: record.viewed ? '' : 'bold'
                        }
                    },
                    children:
                        <span>{moment(text).format((moment(text).format('DD/MM/YYYY')) === moment(new Date()).format('DD/MM/YYYY') ? 'HH:mm' : 'DD/MM/YYYY')}</span>
                };
            }
        },
        {
            title: t('screens:notifications.data.actions'),
            key: 'actions',
            align: 'center',
            width: '20px',
            responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
            render: (text, record) => {
                return {
                    props: {
                        style: { 
                            backgroundColor: record.viewed ? "#fbfbfa" : "",
                            fontWeight: record.viewed ? '' : 'bold'
                        }
                    },
                    children:
                        <TableActions>
                            { record.viewed ? 
                                <Button
                                    style={style.tableAction}
                                    title={t('screens:notifications.data.markAsUnread')}
                                    onClick={() => handleNotView(record)}
                                >
                                    <FaRegEnvelope />
                                </Button> : 
                                <Button
                                    style={style.tableAction}
                                    title={t('screens:notifications.data.markAsRead')}
                                    onClick={() => handleView(record)}
                                >
                                    <FaRegEnvelopeOpen />
                                </Button>
                            }
                            
                            <Popconfirm
                                icon={false}
                                title={t('messages:confirmDelete')}
                                onConfirm={() => handleDelete(record.id)}
                                okText={t('messages:yes')}
                                cancelText={t('messages:no')}
                            >
                                <Button style={style.tableAction} title={t('messages:delete')}>
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        </TableActions>
                };
            },
        },
    ];


    return (
        <Modal
            width="580px"
            title={t('screens:notifications.notifications')}
            open={visible}
            onOk={onClose} onCancel={onClose}
            loading={loading}
            footer={null}
        >
            <Spin spinning={loading}>
                <Table
                    bordered
                    size="small"
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                    columns={tableColumns}
                    dataSource={tableData}
                />

                {meta && (
                    <Pagination
                        onChange={value => {
                            fetchData(value);
                        }}
                        meta={meta}
                    />
                )}
            </Spin>
        </Modal>
    );
}
