import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Input, InputNumber } from 'formik-antd';
import { Modal, Spin, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { createPlan, updatePlan } from '~/services/hooks/plans';
import { Container } from './styles';

const initialValues = {
  id: null,
  name: '',
  days: 0,
  credits: 0,
  price: 0,
};

export default function PlansForm({ selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);
    setRecordData({ ...initialValues, ...selectedRecord });
    setLoading(false);
  };

  const handleSave = async values => {
    setLoading(true);

    let newPackage = {
      id: values.id,
      name: values.name,
      days: values.days,
      credits: values.credits,
      price: values.price,
      description: values.description,
    };

    if (values.id !== null) {
      const data = await updatePlan(newPackage);
      onUpdateItem(data);
    } else {
      const data = await createPlan(newPackage);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    days: Yup.number('Campo deve ser um número').required('Campo obrigatório'),
    credits: Yup.number('Campo deve ser um número').required('Campo obrigatório'),
    price: Yup.number('Campo deve ser um número').required('Campo obrigatório'),
  });

  return (
    <Formik
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={userSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched }) => (
        <Modal
          width="580px"
          title={selectedRecord !== null ? t('screens:plans.btnEdit') : t('screens:plans.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Container>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title level={4} style={{ color: '#4b4b4b' }}>
                    {t('screens:plans.data.personalInfo')}
                  </Title>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                      field="name"
                      label={t('screens:plans.data.name')}
                      required
                      error={(touched.name && errors.name) || errors.name}
                    >
                      <Input name="name" />
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="days"
                      label={t('screens:plans.data.days')}
                      required
                      error={(touched.days && errors.days) || errors.days}
                    >
                      <Input type="number" name="days" addonAfter="dias" />
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="credits"
                      label={t('screens:plans.data.credits')}
                      required
                      error={(touched.credits && errors.credits) || errors.credits}
                    >
                      <Input type="number" name="credits" />
                    </FormControl>

                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="price"
                      label={t('screens:plans.data.price')}
                      required
                      error={(touched.price && errors.price) || errors.price}
                    >
                      <InputNumber decimalSeparator="," step={0.01} addonBefore="R$" name="price" />
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="description"
                      label={t('screens:plans.data.description')}
                      error={(touched.description && errors.description) || errors.description}
                    >
                      <Input.TextArea type="number" name="description" />
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
