import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'orders';

// PAGINATION
export const paginationVendas = async (page, filters) => {
  try {
    const { data } = await api.get(`/${endPoint}`, {
      params: {
        page,
        ...filters,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllVendas = async filters => {
  try {
    const { data } = await api.get(`/${endPoint}`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ONE
export const findVenda = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createVenda = async values => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success(i18next.t(`messages:orders.success`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicatePlans') {
      message.error(i18next.t('messages:duplicatedPlan'));
    } else {
      errorHandler(error);
    }
  }
};

// EXPORT
export const exportVenda = async filters => {
  try {
    await api
      .get(`/${endPoint}/export`, {
        method: 'get',
        responseType: 'blob',
        params: {
          ...filters,
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `transacoes_${new Date().toLocaleDateString()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
  } catch (error) {
    if (error.response.data.errors[0].sqlState === 'foreignKeyViolation') {
      message.warning(i18next.t(`messages:${endPoint}.foreignKeyViolation`));
    } else {
      errorHandler(error);
    }
  }
};

paginationVendas.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllVendas.propTypes = {
  filters: PropTypes.object.isRequired,
};
