import React from 'react';
import history from '~/services/history';
import BaseLayout from '~/pages/_layouts/base';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

export default function Errors() {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <Container>
        <div className="error-code">{t('screens:error404.error')}</div>
        <h1>{t('screens:error404.error')}</h1>
        <h2>{t('screens:error404.pageNotFound')}</h2>

        <Button onClick={() => history.push('/')} color="primary">
          {t('screens:error404.backToHome')}
        </Button>
      </Container>
    </BaseLayout>
  );
}
