import React, { useEffect, useState } from 'react';
import { Formik, isEmptyArray } from 'formik';
import { Input, Radio, Select, Switch } from 'formik-antd';
import { Breadcrumb, Button as ButtonAntd, Col, Spin, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import { findByIdUser, updateCustomer } from '~/services/hooks/customers';
import ReactInputMask from 'react-input-mask';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro/dist/TelefoneBrasileiroInput';
import cep from 'cep-promise';
import DefaultLayout from '../_layouts/full';
import cities from '~/data/cities.json';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { SaveOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { validateCnpj, validateCpf, validatePhone } from '~/Utils';
import { formatPrice } from '~/Utils/format';
import { updateProfile } from '~/store/modules/user/actions';
import { Table } from '~/components/Table';
import moment from 'moment';
import history from '~/services/history';
import { paginationVendas } from '~/services/hooks/finances';
import { findAllConsultas } from '~/services/hooks/consultas';
import { Container } from './styles';

export default function CustomerDetails() {
  const { id } = useParams();
  const { Title } = Typography;
  const { Option } = Select;
  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [telefone, setTelefone] = useState('');
  const [states, setStates] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [lastPayments, setLastPayments] = useState([]);
  const [lastConsults, setLastConsults] = useState([]);

  const sizeOfThings = () => {
    var windowWidth = window.visualViewport.width;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', sizeOfThings, { passive: true });

  const fetchRecords = async () => {
    setLoading(true);
    const data = await findByIdUser(id);
    data.cep = data.address.cep;
    data.neighborhood = data.address.bairro;
    data.address_number = data.address.numero;
    data.city = data.address.cidade;
    data.state = data.address.estado;
    data.telephone = data.address.telefone;
    setTelefone(data.address.telefone);
    data.address = data.address.endereco;
    data.cpf = data.cpf !== null ? data.cpf : '';
    data.cnpj = data.cnpj !== null ? data.cnpj : '';
    setRecordData(data);
    setLoading(false);
  };

  const fetchLastPayments = async () => {
    const { data } = await paginationVendas(1, { userName: recordData.name });
    setLastPayments(data);
  };

  const fetchLastConsults = async () => {
    const { data } = await findAllConsultas(1, { userName: recordData.name });
    setLastConsults(data);
  };

  const handleFindAddress = async (value, values) => {
    setLoading(true);
    try {
      const zipcode = value.replace(/[^0-9,]*/g, '');
      const address = await cep(zipcode);
      setRecordData({
        ...values,
        cep: zipcode,
        city: address.city,
        state: address.state,
        neighborhood: address.neighborhood,
        address: address.street,
      });
    } catch (error) {
      message.error('Endereço não localizado!');
    }
    setLoading(false);
  };

  const selecionaCidade = estado => {
    states.map(item => {
      if (item.sigla === estado) {
        setCidades(item.cidades);
      }
      return null;
    });
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    if (values.user_type === 'pf' ? validateCpf(values.cpf) : validateCnpj(values.cnpj)) {
      if (validatePhone(telefone)) {
        let result = JSON.parse(JSON.stringify(values));
        result.address = {};
        result.address.id = values.addressid;
        result.address.telefone = telefone;
        result.address.cep = values.cep;
        result.address.bairro = values.neighborhood;
        result.address.numero = values.address_number;
        result.address.cidade = values.city;
        result.address.estado = values.state;
        result.address.endereco = values.address;

        await updateCustomer(result);

        if (values.id.toString() === profile.id.toString()) {
          dispatch(updateProfile(result));
        }
        setLoading(false);
      } else {
        setErrors({ telephone: 'Telefone inválido!' });
        setLoading(false);
        return;
      }
    } else {
      setErrors(values.user_type === 'pf' ? { cpf: 'CPF inválido!' } : { cnpj: 'CNPJ inválido!' });
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setStates(cities.estados);
    fetchRecords();
    sizeOfThings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(recordData).length !== 0) {
      fetchLastPayments();
      fetchLastConsults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData]);

  const lastPaymentsTableColumns = [
    {
      title: t('screens:customers.data.plan'),
      dataIndex: ['package_data', 'name'],
      align: 'center',
      ellipsis: { showTitle: true },
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('screens:customers.data.payment'),
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 100,
      ellipsis: { showTitle: true },
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: text => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
    {
      title: t('screens:customers.data.value'),
      dataIndex: ['package_data', 'price'],
      align: 'center',
      width: 100,
      ellipsis: { showTitle: true },
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: text => <span>{formatPrice(text)}</span>,
    },
    {
      dataIndex: ['package_data', 'name'],
      align: 'left',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <span>
            <b>{t('screens:customers.data.plan')}</b>
            {': '}
            {record.package_data.name}
          </span>
          <br />
          <span>
            <b>{t('screens:customers.data.payment')}</b>
            {': '}
            {moment(record.created_at).format('DD/MM/YYYY')}
          </span>
          <br />
          <span>
            <b>{t('screens:customers.data.value')}</b>
            {': '}
            {formatPrice(record.package_data.price)}
          </span>
        </div>
      ),
    },
  ];

  const lastConsultsTableColumns = [
    {
      title: t('screens:consultas.data.nrtransaction'),
      dataIndex: 'id',
      key: 'nr',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('screens:consultas.data.data'),
      dataIndex: 'data',
      key: 'data',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: text => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
    {
      title: t('screens:consultas.data.chassi'),
      dataIndex: 'chassis',
      key: 'chassi',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('screens:consultas.data.placa'),
      dataIndex: 'license_plate',
      key: 'placa',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('screens:consultas.data.credits'),
      dataIndex: 'credits_used',
      key: 'credits',
      align: 'center',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      dataIndex: 'nr',
      key: 'nr',
      align: 'left',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <span>
            <b>{t('screens:consultas.data.nrtransaction')}</b>
            {': '}
            {record.nr}
          </span>
          <br />
          <span>
            <b>{t('screens:consultas.data.data')}</b>
            {': '}
            {moment(record.data).format('DD/MM/YYYY')}
          </span>
          <br />
          <span>
            <b>{t('screens:consultas.data.chassi')}</b>
            {': '}
            {record.chassi}
          </span>
          <br />
          <span>
            <b>{t('screens:consultas.data.placa')}</b>
            {': '}
            {record.placa}
          </span>
          <br />
          <span>
            <b>{t('screens:consultas.data.credits')}</b>
            {': '}
            {record.credits}
          </span>
        </div>
      ),
    },
  ];

  const customerSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    user_type: Yup.string().required(),
    type: Yup.string().required(),
    active: Yup.bool().required(),
    cpf: Yup.string().when('user_type', { is: 'pf', then: Yup.string().required() }),
    cnpj: Yup.string().when('user_type', { is: 'pj', then: Yup.string().required() }),
    telephone: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    cep: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    neighborhood: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    address: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    address_number: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    state: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    city: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
  });

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { backgroundColor: '#52c41a' },
    inactive: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
    colSeeAll: { display: 'flex', justifyContent: 'flex-end' },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/customers">{t('menus:customers')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:details')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:customers.title')} style={style.pageTitle} />
      <Box>
        <Formik
          initialValues={recordData}
          enableReinitialize
          onSubmit={handleSave}
          validationSchema={customerSchema}
        >
          {({ errors, isSubmitting, submitForm, touched, values, setFieldValue }) => (
            <Spin spinning={loading || isSubmitting}>
              <Container>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title level={4} style={{ color: '#4b4b4b' }}>
                    {t('screens:customers.data.data')}
                  </Title>
                </Col>
                <Row>
                  <Radio.Group
                    name="user_type"
                    onChange={() => {}}
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <Radio.Button value="pf">Física</Radio.Button>
                    <Radio.Button value="pj">Jurídica</Radio.Button>
                  </Radio.Group>
                </Row>
                <Row>
                  <FormControl
                    cols={{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}
                    field="name"
                    label={
                      values.user_type === 'pf' ? t('screens:users.data.name') : t('screens:users.data.companyName')
                    }
                    required
                    error={(touched.name && errors.name) || errors.name}
                  >
                    <Input name="name" />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 17, sm: 7, md: 6, lg: 4, xl: 3 }}
                    field="type"
                    label={t('screens:users.data.role')}
                    required
                    error={(touched.type && errors.type) || errors.type}
                  >
                    <Select disabled={!values.isAdmin} showSearch name="type" label={t('screens:users.data.role')}>
                      <Option key={0} value={'ADMIN'}>
                        Administrador
                      </Option>
                      <Option key={1} value={'CLIENT'}>
                        Cliente
                      </Option>
                    </Select>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    required
                    cols={{ xs: 7, sm: 11, md: 16, lg: 7, xl: 8 }}
                    field="active"
                    label={t('screens:users.data.active')}
                  >
                    <Switch
                      disabled={!values.isAdmin}
                      name="active"
                      // checked={recordData.active === undefined ? true : recordData.active}
                      checkedChildren={t('messages:yes')}
                      unCheckedChildren={t('messages:no')}
                      onChange={e => {
                        recordData.active = e;
                      }}
                    ></Switch>
                  </FormControl>

                  <FormControl
                    cols={{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}
                    field="email"
                    label={t('screens:users.data.email')}
                    required
                    error={(touched.email && errors.email) || errors.email}
                  >
                    <Input type="email" name="email" disabled={!values.isAdmin} />
                  </FormControl>
                  {values.user_type === 'pf' ? (
                    <FormControl
                      cols={{ xs: 17, sm: 12, md: 7, lg: 5, xl: 4 }}
                      field="cpf"
                      required
                      error={(touched.cpf && errors.cpf) || errors.cpf}
                      label={t('screens:signUp.data.cpf')}
                    >
                      <ReactInputMask
                        className="ant-input"
                        mask={'999.999.999-99'}
                        name="cpf"
                        value={values.cpf}
                        onChange={e => {
                          const formattedCpf = e.target.value.replace(/\D/g, '');
                          setFieldValue('cpf', formattedCpf);
                        }}
                        placeholder={'___.___.___-__'}
                        id="cpf"
                      />
                    </FormControl>
                  ) : (
                    <FormControl
                      cols={{ xs: 17, sm: 12, md: 7, lg: 5, xl: 4 }}
                      field="cnpj"
                      required
                      error={(touched.cnpj && errors.cnpj) || errors.cnpj}
                      label={t('screens:signUp.data.cnpj')}
                    >
                      <ReactInputMask
                        className="ant-input"
                        mask={'99.999.999/9999-99'}
                        name="cnpj"
                        value={values.cnpj}
                        onChange={e => {
                          const formattedcnpj = e.target.value.replace(/\D/g, '');
                          setFieldValue('cnpj', formattedcnpj);
                        }}
                        placeholder={'__.___.___/____-__'}
                        id="cnpj"
                      />
                    </FormControl>
                  )}

                  <FormControl
                    required
                    cols={{ xs: 17, sm: 12, md: 7, lg: 5, xl: 4 }}
                    field="telephone"
                    error={(touched.telephone && errors.telephone) || errors.telephone}
                    label={t('screens:customers.data.telephone')}
                  >
                    <TelefoneBrasileiroInput
                      temDDD
                      value={telefone}
                      onChange={e => {
                        setFieldValue('telephone', e.target.value);
                        setTelefone(e.target.value);
                      }}
                      className="ant-input"
                    />
                  </FormControl>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Title level={4} style={{ color: '#4b4b4b' }}>
                      {t('screens:customers.data.address')}
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <FormControl
                    required
                    cols={{ xs: 15, sm: 7, md: 6, lg: 4, xl: 3 }}
                    field="cep"
                    error={(touched.cep && errors.cep) || errors.cep}
                    label={t('screens:customers.data.cep')}
                  >
                    <ReactInputMask
                      className="ant-input"
                      mask="99.999-999"
                      name="cep"
                      onChange={e => setFieldValue('cep', e.target.value)}
                      value={values.cep}
                      onBlur={e => {
                        handleFindAddress(e.target.value, values);
                      }}
                      placeholder="__.___-___"
                      id="cep"
                    />
                  </FormControl>
                  <FormControl
                    required
                    cols={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 18 }}
                    field="address"
                    error={(touched.address && errors.address) || errors.address}
                    label={t('screens:customers.data.addressLine')}
                  >
                    <Input name="address" style={{ textTransform: 'uppercase' }} value={values.address} />
                  </FormControl>
                  <FormControl
                    required
                    cols={{ xs: 15, sm: 7, md: 5, lg: 4, xl: 3 }}
                    error={errors.address_number}
                    field="address_number"
                    label={t('screens:customers.data.number')}
                  >
                    <Input name="address_number" type="number" style={{ textTransform: 'uppercase' }} />
                  </FormControl>
                  <FormControl
                    required
                    cols={{ xs: 24, sm: 24, md: 19, lg: 11, xl: 6 }}
                    field="neighborhood"
                    error={(touched.neighborhood && errors.neighborhood) || errors.neighborhood}
                    label={t('screens:customers.data.neighborhood')}
                  >
                    <Input name="neighborhood" value={values.neighborhood} />
                  </FormControl>
                  <FormControl
                    required
                    cols={{ xs: 24, sm: 13, md: 13, lg: 9, xl: 7 }}
                    field="city"
                    error={(touched.city && errors.city) || errors.city}
                    label={t('screens:customers.data.city')}
                  >
                    <Select id="city" name="city" component="select" disabled={isEmptyArray(cidades)}>
                      {cidades.map(item => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    required
                    cols={{ xs: 24, sm: 13, md: 11, lg: 9, xl: 6 }}
                    field="state"
                    error={(touched.state && errors.state) || errors.state}
                    label={t('screens:customers.data.state')}
                  >
                    <Select id="state" name="state" onChange={selecionaCidade}>
                      {states &&
                        states.map(item => (
                          <Select.Option key={item.sigla} value={item.sigla}>
                            {item.nome}
                          </Select.Option>
                        ))}
                    </Select>
                  </FormControl>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Button type="submit" loading={loading || isSubmitting} color="primary" onClick={submitForm}>
                      <SaveOutlined />
                      {t('screens:customers.btnSave')}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Spin>
          )}
        </Formik>
        <br />
        <br />
        <br />

        {windowWidth < 700 ? (
          <>
            <ButtonAntd
              type="link"
              onClick={() => {
                history.push(`/payments`); //filtrar por cliente
              }}
            >
              {t('screens:customers.data.lastPayments')}
            </ButtonAntd>
            <ButtonAntd
              type="link"
              onClick={() => {
                history.push(`/consultas`); //filtrar por cliente
              }}
            >
              {t('screens:customers.data.lastConsults')}
            </ButtonAntd>
          </>
        ) : (
          <Row>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Title level={5} style={{ color: '#4b4b4b' }}>
                {t('screens:customers.data.lastPayments')}
              </Title>
              <Table
                bordered
                size="small"
                rowKey="id"
                pagination={false}
                loading={loading}
                columns={lastPaymentsTableColumns}
                dataSource={lastPayments}
              />
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={style.colSeeAll}>
                  <ButtonAntd
                    type="link"
                    onClick={() => {
                      history.push(`/payments-client/${recordData.name}`); //filtrar por cliente
                    }}
                  >
                    {t('screens:customers.data.seeAll')}
                  </ButtonAntd>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <Title level={5} style={{ color: '#4b4b4b' }}>
                {t('screens:customers.data.lastConsults')}
              </Title>
              <Table
                bordered
                size="small"
                rowKey="id"
                pagination={false}
                loading={loading}
                columns={lastConsultsTableColumns}
                dataSource={lastConsults}
              />
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={style.colSeeAll}>
                  <ButtonAntd
                    type="link"
                    onClick={() => {
                      history.push(`/consultas/${recordData.id}`); //filtrar por cliente
                    }}
                  >
                    {t('screens:customers.data.seeAll')}
                  </ButtonAntd>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Box>
    </DefaultLayout>
  );
}
