import React from 'react';
import 'antd/dist/antd.min.css';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';

function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

export default function UserAvatar({ user, userInitials, size, ...rest }) {

  const active = false;

  return user?.image ? (
    <Avatar
      src={user?.image}
      size={ size }
      // size={64}
      gap={2}
    />
  ) : (
    <Avatar
      style={{ backgroundColor: active ? stringToHslColor(user?.name, 50, 50) : '#aaaaaa', verticalAlign: 'middle' }}
      size={ size }
      // size={64}
      gap={2}
    >
      {userInitials? user?.name?.substring(0, 3).toUpperCase() : <UserOutlined /> }
    </Avatar>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.string,
  userInitials: PropTypes.bool.isRequired,
};

UserAvatar.defaultProps = {
  size: 'default',
  userInitials: false
};
