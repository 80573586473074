import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function HomeLayout() {
  const [windowWidth, setWindowWidth] = useState(null);

  const sizeOfThings = () => {
    var windowWidth = window.visualViewport.width;
    setWindowWidth(windowWidth);
  };

  window.addEventListener('resize', sizeOfThings, { passive: true });

  useEffect(() => {
    sizeOfThings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <h1>Inútil</h1>;
}

HomeLayout.propTypes = {
  breadcrumb: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
