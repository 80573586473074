import React, { useEffect, useState } from 'react';
import { findCreditosUsados } from '~/services/hooks/dashboard';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export default function TotalCreditsAdmin() {
  const [highchartData, setHighchartData] = useState();

  const getRecord = async () => {
    const response = await findCreditosUsados();
    const options = {
      chart: {
        type: 'column',
      },
      tooltip: {
        useHTML: true,
        enabled: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        formatter: function() {
          const pointIndex = this.point.index;
          const dataset = response.datasets[0];
          const totalCredits = dataset.values[0][pointIndex];
          const tooltipHTML = `
                  <div style="background-color: #efefef; padding: 10px;">
                      <span>
                          <b>Créditos</b>
                          <span style='padding-left: 50px'>${this.x}</span>
                      </span>
                      <br/>
                      ${totalCredits} créditos usados
                  </div>
              `;
          return tooltipHTML;
        },
      },
      title: {
        text: 'Créditos Usados',
        align: 'center',
      },
      subtitle: {
        text: 'Valores dos últimos 12 meses',
      },
      xAxis: {
        categories: response.labels,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: 'Quantidade Créditos',
        },
      },
      plotOptions: {
        series: {
          borderRadius: 3,
          pointPadding: 0.3,
          groupPadding: 0.09,
          states: {
            hover: {
              color: '#75A65D',
            },
          },
        },
      },
      legend: {
        enabled: true,
        position: 'right',
      },
      series: [
        {
          name: response.datasets[0].values[0].name,
          pointWidth: 20,
          color: '#CFE2CD',
          data: response.datasets[0].values[0],
        },
      ],
    };
    setHighchartData(options);
  };
  console.log('Dashboard/totalCreditsAdmin => highchartData', highchartData);
  useEffect(() => {
    getRecord();
  }, []);

  return (
    <HighchartsReact
      // containerProps={{ style: { width: '100%' } }}
      highcharts={Highcharts}
      options={highchartData}
    ></HighchartsReact>
  );
}
