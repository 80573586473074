import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';

const { Footer } = Layout;

export default function FooterCustom() {
    const { t } = useTranslation();

    return (
        <Footer style={{ background: 'none', textAlign: 'center', color: '#000', fontSize: '12px' }}>
            <span>{t('app:poweredBy')}</span>
            <span> W2Z </span>
            <p style={{ fontSize: '10px' }}>
                <span>{t('app:version')}</span>
            </p>
        </Footer> 
    );
}
