import React, { useEffect, useState } from 'react';
import { findUsers } from '~/services/hooks/dashboard';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export default function UsersAdmin() {
  const [highchartData, setHighchartData] = useState();

  const getRecord = async () => {
    const response = await findUsers();
    const options = {
      chart: {
        type: 'column',
      },
      tooltip: {
        useHTML: true,
        enabled: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        formatter: function() {
          var value =
            '<div style="background-color: #efefef;padding:10px"><span><b>' +
            this.series.name +
            "<span style='padding-left:50px'>" +
            this.x +
            '</span></b><br/> ' +
            this.y +
            '</span><div>';
          return value;
        },
      },
      title: {
        text: 'Usuários Cadastrados',
        align: 'center',
      },
      xAxis: {
        categories: response.labels,
        crosshair: true,
      },
      plotOptions: {
        series: {
          borderRadius: 3,
          pointPadding: 0.3,
          groupPadding: 0.09,
          states: {
            hover: {
              color: '#75A65D',
            },
          },
        },
      },
      legend: {
        enabled: true,
        position: 'right',
      },
      series: [
        {
          pointWidth: 20,
          name: response.datasets[0].name,
          color: '#75A65D',
          data: response.datasets[0].values[0],
        },
        // {
        //   name: response.datasets[0].name,
        //   pointWidth: 20,
        //   color: 'lightgrey',
        //   data: response.datasets[0].values[0],
        // },
        // {
        //   name: response.datasets[2].name,
        //   color: '#CFE2CD',
        //   pointWidth: 20,
        //   data: response.datasets[2].values[0],
        // },
      ],
    };
    setHighchartData(options);
  };

  useEffect(() => {
    getRecord();
  }, []);

  return (
    <HighchartsReact
      // containerProps={{ style: { width: '100%' } }}
      highcharts={Highcharts}
      options={highchartData}
    ></HighchartsReact>
  );
}
