import { Badge, Card, Col, Typography } from 'antd';
import styled from 'styled-components';
import Button from '~/components/Button';
const { Text } = Typography;

export const ColCredits = styled(Col)`
  margin: 10px;
`;

export const CardCredits = styled(Card)`
  width: 100%;
  background: #F6FAF6;
  border-radius: 5px;
`;

export const SpanCredits = styled.span`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  width: 100%;
  color: #62a159;
`;

export const Title = styled(Text)`
  line-height: 33px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  height: 40px;
  width: 100%;
  padding: 4px 15px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #4F4F4F;
  border-color: #4F4F4F;
  background: #4F4F4F;
  font-weight: bold;
`;

export const DivBuy = styled.div`
  text-align: center;
`;

export const ButtonBuy = styled(Button)`
  display: inline-block;
`;

export const Ribbon = styled(Badge.Ribbon)`
  text-align: center;
  display: inline-block;
  background: #4F4F4F;
  line-height: 40px;
  height: 40px;
  border-radius: 10px 0px;
  font-weight: bold;
`;
