import React, { useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Layout, message } from 'antd';
import * as Yup from 'yup';
import { Container, Box } from './styles';
import FormControl from '~/components/Form/FormControl';
import api from '~/services/api';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import history from '~/services/history';
import { CopyrightOutlined, LockOutlined } from '@ant-design/icons';
import { Copyright } from '../Login/styles';
import FooterCustom from '~/components/Footer';

export default function Reset(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { token } = props.match.params;

  const schema = Yup.object().shape({
    password: Yup.string().required(),
    newPassword: Yup.string().oneOf([Yup.ref('password'), null], t('messages:differentPasswords')),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await api.put(`/password/reset/${token}`, values);
      message.success(t('messages:resetPasswordSuccess'));
      history.push('/');
    } catch (error) {
      message.error(t('messages:passwordError'));
    }
    setLoading(false);
  };

  const currentYear = new Date().getFullYear();
  const myStyleLayout = { height: '100%', background: '#e5e7e9' };

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Box>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ errors, touched }) => (
            <Container>
              <Logo title={true} width="100%" height="100%" margin="auto" marginTop="10px" marginBottom="30px" resetPassword={true} />
              <FormControl field="email" error={touched.email && errors.email}>
                <Input
                  type="email"
                  name="email"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('screens:reset.email.placeholder')} />
              </FormControl>
              <FormControl field="password" error={touched.password && errors.password}>
                <Input
                  type="password"
                  name="password"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('screens:reset.password.placeholder')} />
              </FormControl>
              <FormControl field="newPassword" error={touched.newPassword && errors.newPassword}>
                <Input
                  type="password"
                  name="newPassword"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('screens:reset.passwordConfirmation.placeholder')} />
              </FormControl>
              <Button size="middle" block type="submit" loading={loading} color="primary">
                {t('screens:reset.submit')}
              </Button>
              <Link to="/">{t('screens:resetPassword.back')}</Link>
            </Container>
          )}
        </Formik>
      </Box>
      <Copyright>
        <span>{t('fields:copyright.copyright')}</span>
        <CopyrightOutlined />{` ${currentYear} | `}
        <span>{t('fields:copyright.produced')}</span>
      </Copyright>
      <FooterCustom />
    </Layout>
  );
}
