import styled from 'styled-components';
import { Layout } from 'antd';

export const Container = styled(Layout)`
  min-height: 100%;

  > .ant-layout-content {
    padding: 30px;
  }

  .ant-layout-header {
    background: #4f4f4f;
  }

  .ant-layout-sider-light .ant-layout-sider-trigger {
    background: #e5e7e9;
  }
`;

export const Content = styled(Layout.Content)`
  padding: 24px;
  margin: 0;
  min-height: 280px;
`;

export const EnvironmentHeader = styled.header`
  display: flex;
  background: #75a65d;
  padding: 0 30px;
  height: 88px;

  h1 {
    padding-top: 1.2rem;
    font-size: 40px;
    font-weight: 600;
    margin: 0 auto;
    color: rgb(255 255 255);
    justify-content: center;
  }

  h2 {
    padding-top: 1.2rem;
    font-size: 26px;
    font-weight: 600;
    margin: 0 auto;
    color: rgb(255 255 255);
    justify-content: center;
  }
`;
