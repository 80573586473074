import { Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout.Header)`
display: flex; 
padding: 0 14px 0 24px;
  height: 64px;
`;

export const ContainerMobile = styled(Layout.Header)`
  display: flex;
  padding: 0 14px 0 24px;
  height: 64px;
`;
