import React, { useState, useEffect } from 'react';
import { Formik, isEmptyArray } from 'formik';
import { Switch, Input, Select, Radio } from 'formik-antd';
import { Modal, Spin, Col, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile } from '~/store/modules/user/actions';
import cep from 'cep-promise';
import { createUser, findByIdUser, updateUser } from '~/services/hooks/users';
import ReactInputMask from 'react-input-mask';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro';
import cities from '~/data/cities.json';
import { validateCpf } from '~/Utils';
import { Container } from './styles';

const initialValues = {
  id: null,
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  newPassword: '',
  password_confirmation: '',
  image: null,
  status: true,
  user_type: 'pf',
  cpf: null,
  cnpj: null,
  role_id: '',
  newPasswordConfirmReq: false,
  isAdmin: false,
};

export default function FormClient({ selectedRecord, visible, disabledImage, onClose, onUpdateItem, onInsertItem }) {
  const { Title } = Typography;
  const { Option } = Select;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [states, setStates] = useState({});
  const [cidades, setCidades] = useState([]);
  const type = useSelector(state => state?.user?.profile?.type);

  const fetchRecords = async () => {
    setLoading(true);

    let admin = type && type === 'ADMIN';

    if (selectedRecord === null) {
      const initValues = {
        id: null,
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        user_type: 'pf',
        cpf: null,
        cnpj: null,
        newPassword: '',
        password_confirmation: '',
        newPasswordConfirmReq: false,
        image: null,
        active: true,
        type: 'ADMIN',
        isAdmin: admin,
      };
      setRecordData(initValues);
    } else {
      if (selectedRecord === profile.id) {
        profile.cep = profile?.address?.cep;
        profile.neighborhood = profile?.address?.bairro;
        profile.address_number = profile?.address?.numero;
        profile.city = profile?.address?.cidade;
        profile.state = profile?.address?.estado;
        setTelefone(profile?.address?.telefone);
        profile.address = profile?.address?.endereco;
        profile.isAdmin = admin;
        profile.newPassword = '';
        profile.password_confirmation = '';
        profile.newPasswordConfirmReq = false;
        setRecordData(profile);
      } else {
        const data = await findByIdUser(selectedRecord.id);

        data.isAdmin = data.type === 'ADMIN';
        data.newPassword = '';
        data.password_confirmation = '';
        data.newPasswordConfirmReq = false;
        setRecordData(data);
      }
    }
    setLoading(false);
  };

  const handleSave = async values => {
    setLoading(true);

    let password = values.newPassword.length > 0 ? values.newPassword : values.password;

    let newUser = {
      id: values.id,
      name: values.name,
      email: values.email,
      password: password,
      image: values.image,
      active: values.active,
      type: values.type,
    };
    if (values.type === 'CLIENT') {
      if (validateCpf(values.cpf)) {
        if (telefone !== '') {
          newUser.cpf = values.cpf;
          newUser.address = {};
          newUser.address.endereco = values.address;
          newUser.address.cep = values.cep;
          newUser.address.bairro = values.neighborhood;
          newUser.address.numero = values.address_number;
          newUser.address.cidade = values.city;
          newUser.address.estado = values.state;
          newUser.address.telefone = telefone;
        } else {
          message.error('Telefone é obrigatório!');
          return;
        }
      } else {
        message.error('CPF inválido!');
        return;
      }
    }

    if (values.id !== null) {
      if (selectedRecord === profile.id) {
        const data = await findByIdUser(profile.id);
        newUser.image = data.image;
      }

      const data = await updateUser(newUser);
      onUpdateItem && onUpdateItem(data);

      if (selectedRecord.toString() === profile.id.toString()) {
        dispatch(updateProfile(newUser));
      }
    } else {
      const data = await createUser(newUser);
      onInsertItem(data);
    }
    onClose();

    setLoading(false);
  };

  const handleFindAddress = async (value, values) => {
    setLoading(true);
    try {
      const zipcode = value.replace(/[^0-9,]*/g, '');
      const address = await cep(zipcode);
      setRecordData({
        ...values,
        cep: zipcode,
        city: address.city,
        state: address.state,
        neighborhood: address.neighborhood,
        address: address.street,
      });
    } catch (error) {
      message.error('Endereço não localizado!');
    }
    setLoading(false);
  };

  const selecionaCidade = estado => {
    states.map(item => {
      if (item.sigla === estado) {
        setCidades(item.cidades);
      }
      return null;
    });
  };

  useEffect(() => {
    if (visible) {
      fetchRecords();
      setStates(cities.estados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().when('id', { is: null, then: Yup.string().required() }),
    confirmPassword: Yup.string().when('id', {
      is: null,
      then: Yup.string()
        .oneOf([Yup.ref('password'), null], t('differentPasswords'))
        .required(),
    }),
    password_confirmation: Yup.string().when('newPasswordConfirmReq', {
      is: true,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('differentPasswords'))
        .required(),
    }),
    user_type: Yup.string().required(),
    type: Yup.string().required(),
    active: Yup.bool().required(),
    cpf: Yup.string().when('type', {
      is: 'CLIENT',
      then: Yup.string().when('user_type', { is: 'pf', then: Yup.string().required() }),
    }),
    cnpj: Yup.string().when('type', {
      is: 'CLIENT',
      then: Yup.string().when('user_type', { is: 'pj', then: Yup.string().required() }),
    }),
    // telephone: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    cep: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    neighborhood: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    address: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    address_number: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    state: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
    city: Yup.string().when('type', { is: 'CLIENT', then: Yup.string().required() }),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={userSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values }) => (
        <Modal
          width="580px"
          title={selectedRecord !== null ? t('screens:users.btnEdit') : t('screens:users.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Container>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title level={4} style={{ color: '#4b4b4b' }}>
                    {t('screens:users.data.personalInfo')}
                  </Title>
                  <Row>
                    <Radio.Group
                      name="user_type"
                      onChange={() => {}}
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <Radio.Button value="pf">Física</Radio.Button>
                      <Radio.Button value="pj">Jurídica</Radio.Button>
                    </Radio.Group>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 18, lg: 18, xl: 18 }}
                      field="name"
                      label={
                        values.user_type === 'pf' ? t('screens:users.data.name') : t('screens:users.data.companyName')
                      }
                      required
                      error={(touched.name && errors.name) || errors.name}
                    >
                      <Input name="name" disabled={!values.isAdmin && selectedRecord !== null} />
                    </FormControl>

                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                      field="active"
                      label={t('screens:users.data.active')}
                    >
                      <Switch
                        disabled={!values.isAdmin && selectedRecord !== null}
                        name="active"
                        checked={recordData.active === undefined ? true : recordData.active}
                        checkedChildren={t('messages:yes')}
                        unCheckedChildren={t('messages:no')}
                        onChange={e => {
                          recordData.active = e;
                        }}
                      ></Switch>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="email"
                      label={t('screens:users.data.email')}
                      required
                      error={(touched.email && errors.email) || errors.email}
                    >
                      <Input type="email" name="email" disabled={!values.isAdmin && selectedRecord !== null} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                      field="type"
                      label={t('screens:users.data.role')}
                      required
                      error={(touched.type && errors.type) || errors.type}
                    >
                      <Select
                        disabled={!values.isAdmin && selectedRecord !== null}
                        showSearch
                        name="type"
                        label={t('screens:users.data.role')}
                      >
                        <Option key={0} value={'ADMIN'}>
                          Administrador
                        </Option>
                        <Option key={1} value={'CLIENT'}>
                          Cliente
                        </Option>
                      </Select>
                    </FormControl>
                  </Row>
                  {values.type === 'CLIENT' && (
                    <>
                      <Row>
                        {values.user_type === 'pf' ? (
                          <FormControl
                            cols={{ xs: 13, sm: 9, md: 8, lg: 8, xl: 8 }}
                            field="cpf"
                            required
                            error={(touched.cpf && errors.cpf) || errors.cpf}
                            label={t('screens:signUp.data.cpf')}
                          >
                            <ReactInputMask
                              className="ant-input"
                              mask={'999.999.999-99'}
                              name="cpf"
                              value={values.cpf}
                              onChange={e => {
                                const formattedCpf = e.target.value.replace(/\D/g, '');
                                setFieldValue('cpf', formattedCpf);
                              }}
                              placeholder={'___.___.___-__'}
                              id="cpf"
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            cols={{ xs: 13, sm: 9, md: 8, lg: 8, xl: 8 }}
                            field="cnpj"
                            required
                            error={(touched.cnpj && errors.cnpj) || errors.cnpj}
                            label={t('screens:signUp.data.cnpj')}
                          >
                            <ReactInputMask
                              className="ant-input"
                              mask={'99.999.999/9999-99'}
                              name="cnpj"
                              value={values.cnpj}
                              onChange={e => {
                                const formattedcnpj = e.target.value.replace(/\D/g, '');
                                setFieldValue('cnpj', formattedcnpj);
                              }}
                              placeholder={'__.___.___/____-__'}
                              id="cnpj"
                            />
                          </FormControl>
                        )}
                        <FormControl
                          required
                          cols={{ xs: 12 }}
                          field="telephone"
                          error={(touched.telephone && errors.telephone) || errors.telephone}
                          label={t('screens:customers.data.telephone')}
                        >
                          <TelefoneBrasileiroInput
                            temDDD
                            value={telefone}
                            onChange={event => setTelefone(event.target.value)}
                            className="ant-input"
                          />
                        </FormControl>
                      </Row>
                      <Row>
                        <FormControl
                          required
                          cols={{ xs: 12 }}
                          field="cep"
                          error={(touched.cep && errors.cep) || errors.cep}
                          label={t('screens:customers.data.cep')}
                        >
                          <ReactInputMask
                            className="ant-input"
                            mask="99.999-999"
                            name="cep"
                            value={values.cep}
                            onChange={e => setFieldValue('cep', e.target.value)}
                            onBlur={e => {
                              handleFindAddress(e.target.value, values);
                            }}
                            placeholder="__.___-___"
                            id="cep"
                          />
                        </FormControl>

                        <FormControl
                          required
                          cols={{ xs: 12 }}
                          field="neighborhood"
                          error={(touched.neighborhood && errors.neighborhood) || errors.neighborhood}
                          label={t('screens:customers.data.neighborhood')}
                        >
                          <Input name="neighborhood" value={values.neighborhood} />
                        </FormControl>
                      </Row>
                      <Row>
                        <FormControl
                          required
                          cols={{ xs: 18 }}
                          field="address"
                          error={(touched.address && errors.address) || errors.address}
                          label={t('screens:customers.data.address')}
                        >
                          <Input name="address" style={{ textTransform: 'uppercase' }} value={values.address} />
                        </FormControl>
                        <FormControl
                          required
                          error={errors.address_number}
                          cols={{ xs: 6 }}
                          field="address_number"
                          label={t('screens:customers.data.number')}
                        >
                          <Input name="address_number" type="number" style={{ textTransform: 'uppercase' }} />
                        </FormControl>
                      </Row>
                      <Row>
                        <FormControl
                          cols={{ xs: 12 }}
                          field="state"
                          required
                          error={(touched.state && errors.state) || errors.state}
                          label={t('screens:customers.data.state')}
                        >
                          <Select style={{ width: '90%' }} id="state" name="state" onChange={selecionaCidade}>
                            {states &&
                              states.map(item => (
                                <Select.Option key={item.sigla} value={item.sigla}>
                                  {item.nome}
                                </Select.Option>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl
                          cols={{ xs: 12 }}
                          field="city"
                          required
                          error={(touched.city && errors.city) || errors.city}
                          label={t('screens:customers.data.city')}
                        >
                          <Select
                            style={{ width: '90%' }}
                            id="city"
                            name="city"
                            component="select"
                            disabled={isEmptyArray(cidades)}
                          >
                            {cidades.map(item => (
                              <Select.Option key={item} value={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormControl>
                      </Row>
                    </>
                  )}
                  {selectedRecord === null ? (
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="password"
                        label={t('screens:users.data.password')}
                        required
                        error={(touched.password && errors.password) || errors.password}
                      >
                        <Input name="password" type="password" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="confirmPassword"
                        label={t('screens:users.data.confirmPassword')}
                        required
                        error={(touched.confirmPassword && errors.confirmPassword) || errors.confirmPassword}
                      >
                        <Input name="confirmPassword" type="password" />
                      </FormControl>
                    </Row>
                  ) : (
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="newPassword"
                        label={t('screens:users.data.newPassword')}
                        required
                        error={(touched.newPassword && errors.newPassword) || errors.newPassword}
                      >
                        <Input
                          name="newPassword"
                          type="password"
                          onChange={e => {
                            setFieldValue('newPasswordConfirmReq', e.target.value.length > 0 ? true : false);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                        field="password_confirmation"
                        label={t('screens:users.data.newPasswordConfirmation')}
                        required
                        error={
                          (touched.password_confirmation && errors.password_confirmation) ||
                          errors.password_confirmation
                        }
                      >
                        <Input name="password_confirmation" type="password" />
                      </FormControl>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
