import React, { useEffect, useState } from 'react';
import { findVendasUser } from '~/services/hooks/dashboard';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { formatPrice } from '~/Utils/format';


export default function SellsUser() {

  const [highchartData, setHighchartData] = useState();

  const getRecord = async () => {
    const response = await findVendasUser();
    const options = {
      chart: {
        type: 'column',
      },
      tooltip: {
        useHTML: true,
        enabled: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        formatter: function() {
          const pointIndex = this.point.index;
          const dataset = response.datasets[0];
          const totalPacks = dataset.values[0][pointIndex];
          const totalValue = this.y;
          const formattedPrice = totalValue !== undefined ? formatPrice(totalValue) : 'N/A';
          const tooltipHTML = `
                  <div style="background-color: #efefef; padding: 10px;">
                      <span>
                          <b>Vendas</b>
                          <span style='padding-left: 50px'>${this.x}</span>
                      </span>
                      <br/>
                      ${totalPacks} pacotes
                      <br/>
                      Total: ${formattedPrice}
                  </div>
              `;
          return tooltipHTML;
        },
      },
      title: {
        text: 'Compras Mensais',
        align: 'center',
      },
      subtitle: {
        text: 'Valores dos últimos 12 meses',
      },
      xAxis: {
        categories: response.labels,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: 'Quantidade Vendida',
        },
      },
      plotOptions: {
        series: {
          borderRadius: 3,
          pointPadding: 0.3,
          groupPadding: 0.09,
          states: {
            hover: {
              color: '#75A65D',
            },
          },
        },
      },
      legend: {
        enabled: true,
        position: 'right',
      },
      series: [
        {
          name: response.datasets[0].values[0].name,
          pointWidth: 20,
          color: '#CFE2CD',
          data: response.datasets[0].values[1],
        },
      ],
    };
    setHighchartData(options);
  };

  useEffect(() => {
    getRecord();
  }, []);

  return (
    <HighchartsReact
      containerProps={{ style: { width: '100%' } }}
      highcharts={Highcharts}
      options={highchartData}
    ></HighchartsReact>
  );
}

//   const [highchartData, setHighchartData] = useState();
//   const getRecord = async () => {
//     const response = await findVendasUser();
//     const options = {
//       chart: {
//         type: 'column',
//       },
//       tooltip: {
//         useHTML: true,
//         enabled: true,
//         backgroundColor: null,
//         borderWidth: 0,
//         shadow: false,
//         formatter: function() {
//           var value =
//             '<div style="background-color: lightgrey;padding:10px"><span>' + formatPrice(this.y) + '</span><div>';
//           return value;
//         },
//       },
//       title: {
//         text: 'Valor gasto por mês',
//         align: 'left',
//       },
//       xAxis: {
//         categories: response.labels,
//         crosshair: true,
//       },
//       yAxis: [
//         {
//           min: 0,
//         },
//       ],
//       plotOptions: {
//         series: {
//           borderRadius: 3,
//           pointPadding: 0.3,
//           groupPadding: 0.09,
//           states: {
//             hover: {
//               color: '#75A65D',
//             },
//           },
//         },
//       },
//       legend: {
//         enabled: true,
//         position: 'right',
//       },
//       series: [
//         {
//           pointWidth: 40,
//           name: 'Valor gasto',
//           color: 'lightgrey',
//           data: response.datasets[0].values[0],
//         },
//       ],
//       // responsive: {
//       //   rules: [
//       //     {
//       //       condition: {
//       //         maxWidth: 1500,
//       //       },
//       //       chartOptions: {
//       //         legend: {
//       //           enabled: false,
//       //         },
//       //       },
//       //     },
//       //   ],
//       // },
//     };
//     setHighchartData(options);
//   };
//   useEffect(() => {
//     getRecord();
//   }, []);
//   return (
//     <HighchartsReact
//       containerProps={{ style: { width: '100%' } }}
//       highcharts={Highcharts}
//       options={highchartData}
//     ></HighchartsReact>
//   );
// }
