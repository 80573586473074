import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

const endPoint = 'env';

export const envType = async () => {
  try {
    const { data } = await api.get(`/${endPoint}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};