import React, { useState } from 'react';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { Layout, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import api from '~/services/api';
import { Container, Box, Copyright } from './styles';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import { CopyrightOutlined, MailOutlined } from '@ant-design/icons';
import FooterCustom from '~/components/Footer';
import history from '~/services/history';

export default function Remember() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('screens:invalidEmailFormat'))
      .required(),
  });

  const handleRemember = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const email = values.email;
      await api.post('/password/link', {
        ...{ email },
      });
      message.success(t('messages:successNewPassword'));
      history.push('/');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const data = JSON.parse(error.response.data);
        setErrors(data.errors);
      } else {
        message.error(t('messages:randomError'));
        resetForm();
      }
    }
    setLoading(false);
  };

  const currentYear = new Date().getFullYear();
  const myStyleLayout = { height: '100%', background: '#e5e7e9' };

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Box>
        <Formik initialValues={{}} onSubmit={handleRemember} validationSchema={schema}>
          {({ errors, touched }) => (
            <Container>
              <Logo
                title={true}
                width="100%"
                height="100%"
                margin="auto"
                marginTop="10px"
                marginBottom="30px"
                forgetPassword={true}
              />
              <FormControl field="email" error={touched.email && errors.email}>
                <Input
                  type="email"
                  name="email"
                  size="small"
                  prefix={<MailOutlined />}
                  placeholder={t('screens:remember.email.placeholder')}
                />
              </FormControl>
              <Button size="middle" block type="submit" loading={loading} color="primary">
                {t('screens:remember.submit')}
              </Button>
              <Link to="/">{t('screens:remember.back')}</Link>
            </Container>
          )}
        </Formik>
      </Box>
      <Copyright>
        <span>{t('fields:copyright.copyright')}</span>
        <CopyrightOutlined />
        {` ${currentYear} | `}
        <span>{t('fields:copyright.produced')}</span>
      </Copyright>
      <FooterCustom />
    </Layout>
  );
}
