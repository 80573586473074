import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AntMenu, MenuGlobalStyle } from './styles';
import {
  DashboardOutlined,
  TeamOutlined,
  DollarOutlined,
  ContactsOutlined,
  ContainerOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

export default function SideMenu() {
  const { t } = useTranslation();
  const type = useSelector(state => state?.user?.profile?.type);
  const openKeys = [];
  const selectedKey = [];

  const checkAuth = roles => {
    return roles && roles.includes(type);
  };

  const renderMenu = item => {
    const { type } = item;

    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    } else if (type === 'sub') {
      if (item.items[0].url === window.location.pathname || item.items[1].url === window.location.pathname) {
        openKeys.push(item.key);
      }

      return (
        checkAuth(item.role) && (
          <AntMenu.SubMenu
            key={item.key}
            title={
              <>
                {item.icon} {item.label}
              </>
            }
          >
            {item.items.map(subItem => renderMenu(subItem))}
          </AntMenu.SubMenu>
        )
      );
    } else if (type === 'sub-item') {
      if (item.url === window.location.pathname) {
        selectedKey.push(item.key);
      }
      return checkAuth(item.role) ? (
        <AntMenu.Item key={item.key}>
          {item.label}
          <Link to={item.url} />
        </AntMenu.Item>
      ) : null;
    } else {
      if (item.url === window.location.pathname) {
        selectedKey.push(item.key);
      }
      return checkAuth(item.role) ? (
        <AntMenu.Item key={item.key}>
          <>
            {item.icon} {item.label}
          </>
          <Link to={item.url} />
        </AntMenu.Item>
      ) : null;
    }
  };

  const sideMenuItems = [
    {
      type: 'item',
      key: 'dashboard',
      role: ['ADMIN', 'CLIENT'],
      label: t('menus:main'),
      icon: <DashboardOutlined />,
      url: '/dashboard',
    },
    {
      type: 'item',
      key: 'plans',
      role: ['ADMIN'],
      label: t('menus:packages'),
      icon: <ContainerOutlined />,
      url: '/plans',
    },
    {
      type: 'item',
      key: 'customers',
      role: ['ADMIN'],
      label: t('menus:customers'),
      icon: <ContactsOutlined />,
      url: '/customers',
    },
    {
      type: 'item',
      key: 'financial',
      role: ['ADMIN'],
      label: t('menus:finances'),
      url: '/payments',
      icon: <DollarOutlined />,
    },
    {
      type: 'sub',
      key: 'financial',
      role: ['CLIENT'],
      label: t('menus:financial'),
      icon: <DollarOutlined />,
      items: [
        {
          type: 'sub-item',
          key: 'credits',
          role: ['CLIENT'],
          label: t('menus:credits'),
          url: '/credits',
        },
        {
          type: 'sub-item',
          key: 'payments',
          role: ['CLIENT'],
          label: t('menus:payments'),
          url: '/payments',
        },
      ],
    },
    {
      type: 'item',
      key: 'users',
      role: ['ADMIN'],
      label: t('menus:users'),
      icon: <TeamOutlined />,
      url: '/users',
    },
    {
      type: 'item',
      key: 'coupons',
      role: ['ADMIN'],
      label: t('menus:coupons'),
      icon: <ProfileOutlined />,
      url: '/coupons',
    },
    {
      type: 'sub',
      key: 'consultas',
      role: ['CLIENT'],
      label: t('menus:consultas'),
      icon: <ContactsOutlined />,
      items: [
        {
          type: 'sub-item',
          key: 'new-consulta',
          role: ['CLIENT'],
          label: t('menus:new-consulta'),
          url: '/consultas/new',
        },
        {
          type: 'sub-item',
          key: 'consultas-reports',
          role: ['CLIENT'],
          label: t('menus:consultas-reports'),
          url: '/consultas',
        },
      ],
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      {/* the code cause Warning: [antd: Menu] `children` will be removed in next major version. Please use `items` instead. */}
      <AntMenu
        id="sideMenu"
        mode="inline"
        theme="light"
        defaultOpenKeys={openKeys}
        defaultSelectedKeys={['dashboard']}
        selectedKeys={selectedKey}
      >
        {sideMenuItems.map(item => renderMenu(item))}
      </AntMenu>
    </>
  );
}
