import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'users';

// PAGINATION
export const paginationCustomers = async (page, filters) => {
  try {
    const { data } = await api.get(`/${endPoint}`, {
      params: {
        ...{ page },
        ...filters,
        type: 'CLIENT',
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findByIdUser = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/${id}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        type: 'CLIENT',
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateCustomer = async values => {
  try {
    values.type = 'CLIENT';
    const { data } = await api.put(`/${endPoint}/${values.id}`, values);
    message.success(i18next.t(`messages:customers.successEdit`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateUsers') {
      message.error(i18next.t('messages:duplicatedEmail'));
    } else {
      errorHandler(error);
    }
  }
};

// DELETE
export const deleteCustomer = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:customers.successDelete`));
    return data;
  } catch (error) {
    if (error.response.data.errors[0].sqlState === 'foreignKeyViolation') {
      message.warning(i18next.t(`messages:${endPoint}.foreignKeyViolation`));
    } else {
      errorHandler(error);
    }
  }
};

// EXPORT
export const exportCustomer = async filters => {
  try {
    await api
      .get(`/${endPoint}/export`, {
        method: 'get',
        responseType: 'blob',
        params: {
          ...filters,
          type: 'CLIENT',
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `clientes_${new Date().toLocaleDateString()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });

    message.success(i18next.t(`messages:customers.successExport`));
    return null;
  } catch (error) {
    if (error.response.data.errors[0].sqlState === 'foreignKeyViolation') {
      message.warning(i18next.t(`messages:${endPoint}.foreignKeyViolation`));
    } else {
      errorHandler(error);
    }
  }
};

paginationCustomers.propTypes = {
  params: PropTypes.object.isRequired,
};

updateCustomer.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteCustomer.propTypes = {
  id: PropTypes.string.isRequired,
};
