import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';
import { Formik } from 'formik';
import { Select, Input, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import ReactInputMask from 'react-input-mask';
import { FormFilter } from './styles';
import { format } from 'date-fns';
import { validateCnpj, validateCpf } from '~/Utils';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;
  const [document, setDocument] = useState('CPF');
  
  const onChangeDocument = document => {
    setDocument(document);
  };

  const handleSearch = (values, { setErrors }) => {
    const filter = {};
    try {
      if (values.nameFilter !== undefined && values.nameFilter !== null && values.nameFilter !== '') {
        filter.name = values.nameFilter;
      }

      if (values.cpfFilter !== undefined && values.cpfFilter !== null && values.cpfFilter !== '') {
        if (validateCpf(values.cpfFilter)) {
          filter.cpf = values.cpfFilter;
        } else {
          setErrors({ cpfFilter: 'CPF inválido!' });
          throw (new Error('Documento inválido!'));
        }
      }

      if (values.cnpjFilter !== undefined && values.cnpjFilter !== null && values.cnpjFilter !== '') {
        if (validateCnpj(values.cnpjFilter)) {
          filter.cnpj = values.cnpjFilter;
        } else {
          setErrors({ cnpjFilter: 'CNPJ inválido!' });
          throw (new Error('Documento inválido!'));
        } 
      }

      if (values.dateFilter !== undefined && values.dateFilter !== null && values.dateFilter !== '') {
        filter.date = format(new Date(values.dateFilter), 'yyyy-MM-dd');
      }

      if (values.statusFilter !== undefined && values.statusFilter !== null && values.statusFilter !== 'Todos') {
        filter.active = values.statusFilter;
      }
      setFilters(filter);
    } catch (error) {
      if (error.message !== 'Documento inválido!') {
        errorHandler(error);
      }
    }
  };

  const status = [
    {
      id: 2,
      code: 'Todos',
      description: t('screens:status.all'),
    },
    {
      id: 1,
      code: 1,
      description: t('screens:status.active'),
    },
    {
      id: 0,
      code: 0,
      description: t('screens:status.inactive'),
    },
  ];

  const documents = [
    {
      label: 'CPF',
      value: 'CPF',
    },
    {
      label: 'CNPJ',
      value: 'CNPJ',
    },
  ];

  return (
    <Box>
      <Formik
        initialValues={{ nameFilter: '', cpfFilter: '', cnpjFilter: '', dateFilter: '', statusFilter: 'Todos' }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm, values, setFieldValue }) => (
          <Spin spinning={isLoading}>
            <FormFilter>
              <Row>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 11, lg: 10, xl: 10, xxl: 8 }}
                  error={errors.nameFilter}
                  field="nameFilter"
                  label={t('screens:customers.data.name')}
                >
                  <Input name="nameFilter" placeholder={t('screens:customers.name.placeholder')} />
                </FormControl>
                <FormControl
                  cols={{ xs: 24, sm: 14, md: 13, lg: 9, xl: 7, xxl: 5 }}
                  field={ document === 'CPF' ? "cpfFilter" : "cnpjFilter" }
                  error={ document === 'CPF' ? errors.cpfFilter : errors.cnpjFilter }
                  label={t('screens:customers.document')}
                >
                  <Space.Compact block>
                    <Select
                      id="document"
                      name="document"
                      value={document}
                      onChange={value => {
                          onChangeDocument(value);
                          value === 'CPF' ? setFieldValue('cnpjFilter', '') : setFieldValue('cpfFilter', '');
                        }
                      }
                    >
                      { documents && documents.map(item => (
                        <Option key={item.value} value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                    { document === 'CPF' ?
                      <ReactInputMask
                        className="ant-input"
                        mask="999.999.999-99"
                        name="cpfFilter"
                        value={values.cpfFilter}
                        onChange={e => {
                          const formattedCpf = e.target.value.replace(/\D/g, '');
                          setFieldValue('cpfFilter', formattedCpf);
                        }}
                        placeholder="___.___.___-__"
                        id="cpf"
                      />
                    :
                      <ReactInputMask
                        className="ant-input"
                        mask={'99.999.999/9999-99'}
                        name="cnpjFilter"
                        value={values.cnpjFilter}
                        onChange={e => {
                          const formattedCnpj = e.target.value.replace(/\D/g, '');
                          setFieldValue('cnpjFilter', formattedCnpj);
                        }}
                        placeholder={'__.___.___/____-__'}
                        id="cnpj"
                      />
                    }
                  </Space.Compact>
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 8, md: 7, lg: 5, xl: 4, xxl: 3 }}
                  label={t('screens:customers.data.cadastro')}
                  field="dateFilter"
                  error={errors.dateFilter}
                >
                  <DatePicker name="dateFilter" format={['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12, sm: 6, md: 5, lg: 4, xl: 3, xxl: 2 }}
                  error={errors.statusFilter}
                  field="statusFilter"
                  label={t('screens:customers.data.status')}
                >
                  <Select name="statusFilter">
                    {status.map(item => {
                      return (
                        <Option key={item.id} value={item.code}>
                          {item.description}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </FormFilter>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
