import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Col } from 'antd';
import { Formik } from 'formik';
import { Select, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import { FormFilter } from './styles';

const style = {
  btnSubmit: { marginLeft: 10, marginRight: 10, marginTop: 25 },
  btnClear: { marginLeft: 'auto', marginTop: 25 },
};

export default function Filter({ isLoading, setFilters }) {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleSearch = values => {
    const filter = {};
    filter.type = "ADMIN";
    try {
      if (values.nameFilter !== undefined && values.nameFilter !== null && values.nameFilter !== '') {
        filter.name = values.nameFilter;
      }
      if (values.roleFilter !== undefined && values.roleFilter !== null && values.roleFilter !== '') {
        filter.type = values.roleFilter;
      }
      if (values.statusFilter !== undefined && values.statusFilter !== null && values.statusFilter !== '') {
        filter.active = values.statusFilter;
      }
      setFilters(filter);
    } catch (error) {
      errorHandler(error);
    }
  };

  const status = [
    {
      id: 2,
      code: '',
      description: t('screens:status.all'),
    },
    {
      id: 1,
      code: 1,
      description: t('screens:status.active'),
    },
    {
      id: 0,
      code: 0,
      description: t('screens:status.inactive'),
    },
  ];

  return (
    <Box>
      <Formik
        initialValues={{ nameFilter: '', roleFilter: '', statusFilter: '' }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {({ errors, resetForm }) => (
          <Spin spinning={isLoading}>
            <FormFilter>
              <Row>
                <Col xs={24} sm={12} md={12} lg={7} xl={8}>
                  <FormControl error={errors.nameFilter} field="nameFilter" label={t('screens:users.data.name')}>
                    <Input name="nameFilter" placeholder={t('screens:users.name.placeholder')} />
                  </FormControl>
                </Col>
                {/* <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <FormControl error={errors.roleFilter} field="roleFilter" label={t('screens:users.data.role')}>
                    <Select name="roleFilter" defaultValue="">
                      <Option key={2} value="">
                        Todos
                      </Option>
                      <Option key={0} value="ADMIN">
                        {t('screens:users.data.type.ADMIN')}
                      </Option>
                      <Option key={1} value="CLIENT">
                        {t('screens:users.data.type.CLIENT')}
                      </Option>
                    </Select>
                  </FormControl>
                </Col> */}
                <Col xs={24} sm={11} md={8} lg={4} xl={4}>
                  <FormControl error={errors.statusFilter} field="statusFilter" label={t('screens:users.data.status')}>
                    <Select name="statusFilter" defaultValue="">
                      {status.map(item => {
                        return (
                          <Option key={item.id} value={item.code}>
                            {item.description}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Button
                  color="default"
                  style={style.btnClear}
                  onClick={() => {
                    resetForm();
                    setFilters([]);
                  }}
                >
                  {t('screens:dateSearch.clearButton')}
                </Button>
                <Button type="submit" color="primary" style={style.btnSubmit}>
                  <SearchOutlined />
                  {t('screens:dateSearch.button')}
                </Button>
              </Row>
            </FormFilter>
          </Spin>
        )}
      </Formik>
    </Box>
  );
}
