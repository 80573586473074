import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button as ButtonAntd, Checkbox, Layout } from 'antd';
import * as Yup from 'yup';
import { rememberCredentials, signInRequest } from '~/store/modules/auth/actions';
import { Container, Box, Copyright } from './styles';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import { CopyrightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import FooterCustom from '~/components/Footer';
import history from '~/services/history';

export default function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var username = useSelector(state => state.auth.rememberUsername);
  var password = useSelector(state => state.auth.rememberPassword);
  var checked = useSelector(state => state.auth.checked);

  const schema = Yup.object().shape({
    username: Yup.string()
      .typeError(t('fields:insertYourEmail'))
      .email(t('fields:insertValidEmail'))
      .required(t('fields:insertYourEmail')),
    password: Yup.string(t('fields:insertYourPassword'))
      .typeError(t('fields:insertYourPassword'))
      .required(t('fields:insertYourPassword')),
  });

  function handleRemember({ target }) {
    var checked = target.checked;
    checked ? (username = document.getElementsByName('username')['0'].value) : (username = '');
    checked ? (password = document.getElementsByName('password')['0'].value) : (password = '');
    dispatch(rememberCredentials(username, password, checked));
  }

  function handleSubmit({ username, password }) {
    dispatch(signInRequest(username, password));
    setTimeout(function() {
      setLoading(false);
    }, 1000);
  }

  const currentYear = new Date().getFullYear();
  const myStyleLayout = { height: '100%', background: '#e5e7e9' };

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Box>
        <Formik
          initialValues={{ username, password }}
          onSubmit={values => {
            handleSubmit(values);
            setLoading(true);
          }}
          validationSchema={schema}
        >
          {({ errors, touched }) => (
            <Container>
              <Logo title={false} width="100%" height="100%" margin="auto" marginTop="10px" marginBottom="30px" />

              <FormControl field="username" error={touched.username && errors.username}>
                <Input
                  type="email"
                  name="username"
                  size="small"
                  prefix={<UserOutlined />}
                  placeholder={t('screens:login.username.placeholder')}
                />
              </FormControl>
              <FormControl field="password" error={touched.password && errors.password}>
                <Input.Password
                  type="password"
                  name="password"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('screens:login.password.placeholder')}
                />
              </FormControl>
              <div style={{ textAlign: 'left' }}>
                <Checkbox id="checkbox" style={{ fontSize: '12px' }} checked={checked} onChange={handleRemember}>
                  <span style={{ color: '#a1a1a1' }}>{t('screens:login.rememberMe')} </span>
                </Checkbox>
                <span style={{ float: 'right', marginBottom: '10px' }}>
                  <Link to="/password/link">{t('screens:login.forgotPassword')}</Link>
                </span>
              </div>

              <Button size="middle" block type="submit" loading={loading} color="primary">
                {t('screens:login.submit')}
              </Button>

              <ButtonAntd
                type="link"
                style={{ color: '#62a159' }}
                onClick={() => {
                  history.push(`/signup`);
                }}
              >
                {t('screens:login.signUp')}
              </ButtonAntd>
            </Container>
          )}
        </Formik>
      </Box>
      <Copyright>
        <span>{t('fields:copyright.copyright')}</span>
        <CopyrightOutlined />
        {` ${currentYear} | `}
        <span>{t('fields:copyright.produced')}</span>
      </Copyright>
      <FooterCustom />
    </Layout>
  );
}
