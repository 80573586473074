import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'users';

// PAGINATION
export const paginationUsers = async (page, filters) => {
  try {
    const { data } = await api.get(`/${endPoint}`, {
      params: {
        ...{ page },
        ...filters,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND
export const findUser = async () => {
  try {
    const { data } = await api.get(`/me`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND ALL
export const findAllUsers = async filters => {
  try {
    const { data } = await api.get(`/${endPoint}`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findByIdUser = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/${id}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createUser = async values => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateUsers') {
      message.error(i18next.t('messages:duplicatedEmail'));
    } else {
      errorHandler(error);
    }
  }
};

// CREATE LOGIN
export const createLoginUser = async values => {
  try {
    const { data } = await api.post(`/${endPoint}/client`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateUsers') {
      message.error(i18next.t('messages:duplicatedEmail'));
    } else {
      errorHandler(error);
    }
  }
};

// UPDATE
export const updateUser = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/${values.id}`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateUsers') {
      message.error(i18next.t('messages:duplicatedEmail'));
    } else {
      errorHandler(error);
    }
  }
};

// UPDATE PASSWORD
export const updatePassword = async values => {
  try {
    const { data } = await api.put(`users/changepassword`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateUsers') {
      message.error(i18next.t('messages:duplicatedEmail'));
    } else {
      errorHandler(error);
    }
  }
};

// DELETE
export const deleteUser = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    if (error.response.data.errors[0].sqlState === 'foreignKeyViolation') {
      message.warning(i18next.t(`messages:${endPoint}.foreignKeyViolation`));
    } else {
      errorHandler(error);
    }
  }
};

// FIND ADDRESS
export const findAddress = async id => {
  try {
    const { data } = await api.get(`/address/${id}`);

    return data;
  } catch (error) {
    errorHandler(error);
  }
};

paginationUsers.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllUsers.propTypes = {
  filters: PropTypes.object.isRequired,
};

findByIdUser.propTypes = {
  id: PropTypes.string.isRequired,
};

createUser.propTypes = {
  values: PropTypes.object.isRequired,
};

updateUser.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteUser.propTypes = {
  id: PropTypes.string.isRequired,
};
