import styled from 'styled-components';
import { Form } from 'formik-antd';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .form-control {
    margin-bottom: 15px;
    
    .error-label {
      display: flex;
      justify-content: start;
      width: 100%;
      color: ${props => props.theme.colors.error.labelColor};
    }
    
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${props => props.theme.colors.primary.hover.borderColor};
    }

    .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
      border-color: ${props => props.theme.colors.primary.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.focus.boxShadow};
    }
  }

  .form-control.error > .ant-input-affix-wrapper-sm {
    border-color: ${props => props.theme.colors.error.borderColor};
  }

  .form-control.error > .ant-input-affix-wrapper:hover,
  .form-control.error > .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${props => props.theme.colors.error.hover.borderColor};
  }

  .form-control.error > .ant-input-affix-wrapper-focused,
  .form-control.error > .ant-input-affix-wrapper:focus {
      border-color: ${props => props.theme.colors.error.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.error.focus.boxShadow};
  }

  .ant-input-affix-wrapper-sm {
    background: #fafafa;
  }

  input {
    background: #fafafa;
    height: 32px;
    padding: 0 15px;
    color: #000;

    &::placeholder {
      color: #a1a1a1;
    }
  }

  span {
    color: #a1a1a1;
  }

  a {
    color: #a1a1a1;
    font-size: 12px;

    &:hover {
      opacity: 1;
      color: #6B6B6B;
      text-decoration: underline;
    }
  }

  button {
    margin-top: 15px;
    margin-bottom: 30px;
  }
`;

export const Box = styled.div`
  width: 90%;
  max-width: 390px;
  margin: auto;
  margin-bottom: 0%;
  background: #fff;
  border-radius: 5px;
  padding: 0 60px 20px;
`;

export const Copyright = styled.div`
  margin-top: 16px;
  margin-bottom: auto;
  color: #000;
  text-align: center;
  font-size: 12px;
`;
