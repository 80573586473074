import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button as ButtonAntd, Menu as AntMenu, Badge } from 'antd';
import {
  DashboardOutlined,
  TeamOutlined,
  DollarOutlined,
  ContactsOutlined,
  ContainerOutlined,
  SettingOutlined,
  BellOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import UserForm from '~/pages/Users/form';
import ClientForm from '~/pages/Users/formClient';
import { FaBars } from 'react-icons/fa';
import { MenuGlobalStyle, Nav } from './styles';
import { signOutRequest } from '~/store/modules/auth/actions';
import Notifications from '~/pages/Notifications';
import Button from '~/components/Button';

export default function SmallMenu({ countNotifications, onView }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profile = useSelector(state => (state.user.profile ? state.user.profile : null));
  const type = profile?.type;
  const [showForm, setShowForm] = useState(false);
  const [showClientForm, setShowClientForm] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const userName = useSelector(state => (state.user.profile ? state.user.profile.name : null));
  const userId = useSelector(state => (state.user.profile ? state.user.profile.id : null));
  const openKeys = [];
  const selectedKey = [];

  function handleNotifications() {
    setShowNotifications(true);
  }

  function handleProfile() {
    setShowForm(true);
  }

  function handleClientProfile() {
    setShowClientForm(true);
  }

  function handleSignOut() {
    dispatch(signOutRequest());
  }

  const checkAuth = roles => {
    return roles && roles.includes(type);
  };

  const renderMenu = item => {
    const { type } = item;

    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    } else if (type === 'notifications') {
      return (
        checkAuth(item.role) && (
            <ButtonAntd id="menu-notifications" type="link" onClick={item.click} style={{ marginTop: '15px', padding: '0'}}>
              <Badge size="small" count={item.count}>
                <BellOutlined id="bell" style={{ fontSize: '20px', color: 'white' }} />
              </Badge>
            </ButtonAntd>
        )
      );
    } else if (type === 'sub') {
      if (item.items[0].url === window.location.pathname || item.items[1].url === window.location.pathname) {
        openKeys.push(item.key);
      }

      return (
        checkAuth(item.role) && (
          <AntMenu.SubMenu
            id={item.key}
            key={item.key}
            title={
              <>
                {item.icon}
                {item.label}
              </>
            }
          >
            {item.items.map(subItem => renderMenu(subItem))}
          </AntMenu.SubMenu>
        )
      );
    } else if (type === 'sub-item') {
      if (item.url === window.location.pathname) {
        selectedKey.push(item.key);
      }
      return checkAuth(item.role) ? (
        <AntMenu.Item key={item.key}>
          {item.label}
          <Link to={item.url} />
        </AntMenu.Item>
      ) : null;
    } else {
      if (!item.key === 'logout' && !item.key === 'profile' && item.url === window.location.pathname) {
        selectedKey.push(item.key);
      }
      return checkAuth(item.role) ? (
        <AntMenu.Item key={item.key}>
          {item.click && item.key === 'logout' ? (
            <Button id="btnLogout" style={{ background: 'transparent' }} onClick={item.click}>
              {item.icon}
              {item.label}
            </Button>
          ) : item.click && item.key === 'profile' ? (
            <Button id="btnProfile" style={{ background: 'transparent' }} onClick={item.click}>
              {item.icon}
              {item.label}
            </Button>
          ) : (
            <>
              <>
                {item.icon}
                {item.label}
              </>
              <Link to={item.url} />
            </>
          )}
        </AntMenu.Item>
      ) : null;
    }
  };

  const menuSubItems = [
    {
      type: 'item',
      key: 'profile',
      role: ['ADMIN'],
      label: `${userName}`,
      icon: <SettingOutlined />,
      click: handleProfile,
    },
    {
      type: 'item',
      key: 'profile',
      role: ['CLIENT'],
      label: `${userName}`,
      icon: <SettingOutlined />,
      click: handleClientProfile,
    },
    {
      type: 'item',
      key: 'dashboard',
      role: ['ADMIN', 'CLIENT'],
      label: t('menus:main'),
      icon: <DashboardOutlined />,
      url: '/dashboard',
    },
    {
      type: 'item',
      key: 'plans',
      role: ['ADMIN'],
      label: t('menus:packages'),
      icon: <ContainerOutlined />,
      url: '/plans',
    },
    {
      type: 'item',
      key: 'customers',
      role: ['ADMIN'],
      label: t('menus:customers'),
      icon: <ContactsOutlined />,
      url: '/customers',
    },
    {
      type: 'item',
      key: 'financial',
      role: ['ADMIN'],
      label: t('menus:finances'),
      url: '/payments',
      icon: <DollarOutlined />,
    },
    {
      type: 'sub',
      key: 'financial',
      role: ['CLIENT'],
      label: t('menus:financial'),
      icon: <DollarOutlined />,
      items: [
        {
          type: 'sub-item',
          key: 'credits',
          role: ['CLIENT'],
          label: t('menus:credits'),
          url: '/credits',
        },
        {
          type: 'sub-item',
          key: 'payments',
          role: ['CLIENT'],
          label: t('menus:payments'),
          url: '/payments',
        },
      ],
    },
    {
      type: 'item',
      key: 'users',
      role: ['ADMIN'],
      label: t('menus:users'),
      icon: <TeamOutlined />,
      url: '/users',
    },
    {
      type: 'item',
      key: 'coupons',
      role: ['ADMIN'],
      label: t('menus:coupons'),
      icon: <ProfileOutlined />,
      url: '/coupons',
    },
    {
      type: 'sub',
      key: 'consultas',
      role: ['CLIENT'],
      label: t('menus:consultas'),
      icon: <ContactsOutlined />,
      items: [
        {
          type: 'sub-item',
          key: 'new-consulta',
          role: ['CLIENT'],
          label: t('menus:new-consulta'),
          url: '/consultas/new',
        },
        {
          type: 'sub-item',
          key: 'consultas-reports',
          role: ['CLIENT'],
          label: t('menus:consultas-reports'),
          url: '/consultas',
        },
      ],
    },
    {
      type: 'divider',
      key: 'set_div_2',
    },
    {
      type: 'item',
      key: 'logout',
      role: ['ADMIN', 'CLIENT'],
      label: t('menus:user_logout'),
      click: handleSignOut,
    },
  ];

  const menuItems = [
    {
      type: 'notifications',
      key: 'notifications',
      role: ['CLIENT'],
      url: '/notifications',
      count: countNotifications,
      click: handleNotifications,
    },
    {
      type: 'sub',
      key: 'smallmenu',
      role: ['ADMIN', 'CLIENT'],
      label: <FaBars />,
      items: menuSubItems,
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      <Nav>

        <AntMenu
          mode="horizontal"
          theme="light"
          defaultOpenKeys={openKeys}
          defaultSelectedKeys={['']}
          selectedKeys={selectedKey}
          style={{ marginLeft: 'auto', background: 'transparent' }}
        >
          {menuItems.map(item => renderMenu(item))}
        </AntMenu>
      </Nav>
      <UserForm selectedRecord={userId} visible={showForm} disabledImage={false} onClose={() => setShowForm(false)} />
      <ClientForm
        selectedRecord={userId}
        visible={showClientForm}
        disabledImage={false}
        onClose={() => setShowClientForm(false)}
      />
      <Notifications visible={showNotifications} onClose={() => setShowNotifications(false)} onView={() => onView()} />
    </>
  );
}
