import React from 'react';
import 'antd/dist/antd.min.css';
import Icon, { MailOutlined, IdcardOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Space, Typography, Popover } from 'antd';
import Row from '~/components/Row';
import PropTypes from 'prop-types';
import UserAvatar from '~/components/UserAvatar';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const CompanySvg = () => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22,7H13V2a1,1,0,0,0-1-1H2A1,1,0,0,0,1,2V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V8A1,1,0,0,0,22,7ZM11,13H3V11h8Zm0-5V9H3V7h8ZM3,15h8v2H3ZM11,3V5H3V3ZM3,19h8v2H3Zm18,2H13V9h8Zm-5-5H14V14h2Zm0,4H14V18h2Zm4-4H18V14h2Zm-4-4H14V10h2Zm4,0H18V10h2Zm0,8H18V18h2Z" />
  </svg>
);
const CompanyIcon = props => <Icon component={CompanySvg} {...props} />;

export default function UserInfo({ user }) {
  const { t } = useTranslation();

  return (
    <Popover
      key={user.id}
      content={
        <>
          <Row wrap={false} type="flex">
            <Col flex="14px" style={{ paddingRight: '0px' }}>
              <IdcardOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Col>
            <Col flex="auto">
              <Text ellipsis={{ tooltip: user?.type }} style={{ width: 240 }}>
                {t('screens:users.data.type.' + user?.type)}
              </Text>
            </Col>
          </Row>
          { user?.user_type === 'pj' ? (
          <Row>
            <Col flex="14px" style={{ paddingRight: '0px' }}>
              <CompanyIcon style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Col>
            <Col flex="auto">
              <Text ellipsis={{ tooltip: user?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') }} style={{ width: 240 }}>
                {user?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
              </Text>
            </Col>
          </Row>
          ) : (
            <Row>
            <Col flex="14px" style={{ paddingRight: '0px' }}>
              <UserOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Col>
            <Col flex="auto">
              <Text ellipsis={{ tooltip: user?.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') }} style={{ width: 240 }}>
                {user?.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}
              </Text>
            </Col>
          </Row>
          )}
          <Row>
            <Col flex="14px" style={{ paddingRight: '0px' }}>
              <MailOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Col>
            <Col flex="auto">
              <Text ellipsis={{ tooltip: user?.email }} style={{ width: 240 }}>
                {user?.email}
              </Text>
            </Col>
          </Row>
        </>
      }
      title={user?.name}
    >
      <>
        <Row key={user.id} wrap={false} type="flex">
          <Col style={{ paddingRight: '0px', width: '20px' }}>
            <UserAvatar user={user} active={true} size="default" />
          </Col>
          <Col style={{ width: '180px', paddingLeft: '26px' }}>
            <Space size={0.5} direction="vertical">
              <Text ellipsis={true} style={{ width: 180, color: user.active ? '#444444' : '#a9a9a9' }}>
                {user?.name} {user?.type === 'ADMIN' ? <p style={{ display: 'inline', fontSize: '10px' }}>(Admin)</p>
                 : null}
              </Text>
              <Text
                type="secondary"
                ellipsis={true}
                style={{
                  width: 180,
                  whiteSpace: 'nowrap',
                  fontSize: '12px',
                  position: 'relative',
                  top: '-7px',
                }}
              >
                {user?.email}
              </Text>
            </Space>
          </Col>
        </Row>
      </>
    </Popover>
  );
}

UserInfo.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};
