import styled from 'styled-components';
import { Form } from 'formik-antd';

export const Body = styled.body`
  display: flex;
  align-items: start;
  justify-content: center;
  margin: 0;
  background-color: rgb(255, 255, 255);
`;

export const Layout = styled.div`
  height: 100%;
  background: #e5e7e9;
  width: 24vw;
  border: 1px solid black;

  @media screen and (max-width: 768px) {
    height: 80%;
    width: 90%;
	margin-bottom: 20%;
    // background-color: red;
  }
`;

export const ContainerAll = styled.div`
  display: flex;
  align-items: center;
  width: 80vw;
  height: 46vw;
  margin: 1vw;
  padding-left: 3%;
  // background-color: blue;

  @media screen and (max-width: 768px) {
    /* Estilização específica para largura menor ou igual a 768 pixels */
    flex-direction: column;
    width: 75vw;
    height: auto;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 130%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 3%; /* Espaço em cima */
    bottom: 3%; /* Espaço em baixo */
    right: -1.5vw;
    width: 0.01vw; /* Espessura da borda */
    background-color: black; /* Cor da borda */
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ContainerImg = styled.div`
  display: flex;
  justify-content: flex-start;
    // background-color: pink;

  img {
    width: 10vw;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 40vw;
    }
  }
`;

export const ContainerText = styled.div``;

export const Title = styled.h3`
  color: red;
  font-weight: bold;
  font-size: 1.2vw;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
  }
`;

export const DefaultParag = styled.p`
  color: black;
  font-weight: 1000;
  font-size: 1.1vw;
  margin-top: 1%;

  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
`;

export const DefaultList = styled.ul`
  color: black;
  font-weight: bold;
  font-size: 1.1vw;
  margin-top: 1%;

  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
`;

export const ContainerVideo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vw;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0.4vw;

  button {
    background-color: rgb(85, 85, 255);
    width: 20vw;
    height: 10%;
    font-size: 0.75vw;
    padding: 10px;
  }

  button:hover {
    background-color: rgb(152, 152, 255);
  }

  @media screen and (max-width: 768px) {
    margin-top: 6vw;
    margin-bottom: 6vw;

    button {
      background-color: rgb(85, 85, 255);
      width: 80vw;
      height: 10%;
      font-size: 2.5vw;
      padding: 10px;
    }
  }
`;

export const ContainerContacts = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 5.5vw;

  @media screen and (max-width: 768px) {
    height: 20vw;
    // background-color: blue;
  }
`;

export const UnionContacts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 75%;

  h3 {
    color: black;
    font-weight: bold;
    font-size: 1vw;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    width: 100%;

    h3 {
      font-size: 4.4vw;
    }
  }
`;

export const ContainerLogin = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    height: 120vw;
    // background-color: red;
  }
`;

export const ContainerIcons = styled.div`
  position: absolute;
  top: 42.5vw;
  left: 31vw;
  display: flex;
  z-index: 1000;
  flex-direction: column;
  transform: translate(-50%, -50%);
  gap: 0.5vw;

  a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;

    img {
      width: 3vw;
    }
  }

  @media screen and (max-width: 768px) {
    left: 92%;
    top: 91%;
    flex-direction: column;
    position: fixed;

    a {
      margin-bottom: 30%;

      img {
        width: 9vw;
      }
    }
  }
`;

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .form-control {
    .error-label {
      display: flex;
      justify-content: start;
      width: 100%;
      color: ${props => props.theme.colors.error.labelColor};
    }

    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${props => props.theme.colors.primary.hover.borderColor};
    }

    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:focus {
      border-color: ${props => props.theme.colors.primary.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.focus.boxShadow};
    }
  }

  .form-control.error > .ant-input-affix-wrapper-sm {
    border-color: ${props => props.theme.colors.error.borderColor};
  }

  .form-control.error > .ant-input-affix-wrapper:hover,
  .form-control.error > .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${props => props.theme.colors.error.hover.borderColor};
  }

  .form-control.error > span.ant-input-affix-wrapper-focused,
  .form-control.error > span.ant-input-affix-wrapper:focus {
    border-color: ${props => props.theme.colors.error.focus.borderColor};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.error.focus.boxShadow};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${props => props.theme.colors.primary.hover.borderColor};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.primary.checked.bg};
    border-color: ${props => props.theme.colors.primary.checked.borderColor};
  }

  .ant-checkbox-checked:after {
    border: 1px solid ${props => props.theme.colors.primary.checked.after};
  }

  .ant-input-affix-wrapper-sm {
    background: #fafafa;
  }

  input {
    background: #fafafa;
    height: 1.6vw;
    padding: 0 10rem;
    color: #000;

    &::placeholder {
      color: #a1a1a1;
    }
  }

  a {
    font-size: 0.7vw;
    color: ${props => props.theme.color2};

    &:hover {
      opacity: 1;
      color: #071d3d;
    }
  }

  #login-button {
    margin-top: 1em;
    margin-bottom: 0.4em;
    width: 100%;
    height: 1vw;
  }

  img {
    margin: auto;
    width: 17vw;
  }

  @media screen and (max-width: 768px) {
    input {
      background: #fafafa;
      height: 5.3vw;
      padding: 0 10rem;
      color: #000;

      &::placeholder {
        color: #a1a1a1;
      }
    }

    a {
      font-size: 3vw;
    }

    #login-button {
      margin-bottom: 1em;
    }

    img {
      width: 45vw;
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 75%;
  margin-bottom: 0%;
  background: #fff;
  border-radius: 5px;
  padding: 0 3vw;
`;

export const Copyright = styled.div`
  margin-top: 1vw;
  color: #000;
  text-align: center;
  font-size: 0.6vw;

  @media screen and (max-width: 768px) {
    font-size: 2.5vw;
  }
`;
