import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'alerts';

// GET ALL
export const findAllNotifications = async () => {
  try {
    const { data } = await api.get(`/${endPoint}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// COUNT NOTIFICATIONS NO VIEWED
export const countNotViewed = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/count-not-viewed/0`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// PAGINATION
export const paginationNotifications = async (page, id) => {
  try {
    const { data } = await api.get(`/${endPoint}/user/${id}`, {
      params: {
        ...{ page },
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// FIND BY ID
export const findByIdNotification = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/${id}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createNotification = async values => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success(i18next.t(`messages:notifications.success`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateNotifications') {
      message.error(i18next.t('messages:duplicatedNotification'));
    } else {
      errorHandler(error);
    }
  }
};

// UPDATE
export const updateNotification = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/${values.id}`, values);
    message.success(i18next.t(`messages:notifications.successEdit`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateNotifications') {
      message.error(i18next.t('messages:duplicatedNotification'));
    } else {
      errorHandler(error);
    }
  }
};

// DELETE
export const deleteNotification = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:notifications.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const readNotification = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/${values.id}`, values);
    message.success(i18next.t(`messages:notifications.successView`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateNotifications') {
      message.error(i18next.t('messages:duplicatedNotification'));
    } else {
      errorHandler(error);
    }
  }
};

export const unReadNotification = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/${values.id}`, values);
    message.success(i18next.t(`messages:notifications.successNotView`));
    return data;
  } catch (error) {
    const { response } = error;
    if (response.data.errors && response.data.errors[0].sqlState === 'duplicateNotifications') {
      message.error(i18next.t('messages:duplicatedNotification'));
    } else {
      errorHandler(error);
    }
  }
};

countNotViewed.propTypes = {
  id: PropTypes.string.isRequired,
};

findByIdNotification.propTypes = {
  id: PropTypes.string.isRequired,
};

paginationNotifications.propTypes = {
  page: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

createNotification.propTypes = {
  values: PropTypes.object.isRequired,
};

updateNotification.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteNotification.propTypes = {
  id: PropTypes.string.isRequired,
};

readNotification.propTypes = {
  values: PropTypes.object.isRequired,
};

unReadNotification.propTypes = {
  values: PropTypes.object.isRequired,
};
