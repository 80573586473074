/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, SubTitle, Title } from './styles';

export default function Logo({
  logoName,
  margin,
  marginTop,
  marginBottom,
  height,
  width,
  w2z,
  title,
  forgetPassword,
  resetPassword,
}) {
  const logo = `/assets/images/${logoName}`;
  const logoW2Z = `/assets/images/w2z.png`;
  const { t } = useTranslation();

  return (
    <>
      <Container className="logo" margin={margin} marginTop={marginTop} marginBottom={marginBottom}>
        {w2z ? (
          <img height={height} width={width} src={logoW2Z} alt="W2Z" />
        ) : (
          <img height={height} width={width} src={logo} alt="ALOCT" />
        )}
      </Container>
      {title === true ? (
        forgetPassword === true ? (
          <>
            <Title>{t('screens:remember.forgotPassword')}</Title>
            <SubTitle>{t('app:logo.redefinePasswordText')}</SubTitle>
          </>
        ) : resetPassword === true ? (
          <Title>{t('app:logo.resetPassword')}</Title>
        ) : (
          <Title>{t('app:appName')}</Title>
        )
      ) : (
        ''
      )}
    </>
  );
}

Logo.propTypes = {
  logoName: PropTypes.string,
  margin: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  w2z: PropTypes.bool,
  title: PropTypes.bool,
  forgetPassword: PropTypes.bool,
  resetPassword: PropTypes.bool,
};

Logo.defaultProps = {
  logoName: 'logo-light.png',
  margin: '0',
  marginTop: '0px',
  marginBottom: '0px',
  height: '232',
  width: '232',
  w2z: false,
  title: false,
  forgetPassword: false,
  resetPassword: false,
};
