import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'coupons';

// PAGINATION
export const paginationCoupons = async (page, filters) => {
  try {
    const { data } = await api.get(`/${endPoint}`, {
      params: {
        ...{ page },
        ...filters,
      },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const findByIdCoupon = async id => {
  try {
    const { data } = await api.get(`/${endPoint}/${id}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const createCoupon = async values => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success(i18next.t(`messages:${endPoint}.successCreate`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const updateCoupons = async values => {
  try {
    const { data } = await api.put(`/${endPoint}/${values.id}`, values);
    message.success(i18next.t(`messages:coupons.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const deleteCoupon = async id => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:coupons.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const findByCodeCoupon = async code => {
  try {
    const data = await api.get(`/${endPoint}/by-code/${code}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    console.log('Services -> Hooks -> Coupons -> findByCodeCoupon', data.data);
    message.success(i18next.t(`messages:coupons.successApply`));
    return data.data;
  } catch (error) {
    if (error.response.status == 417) {
      return 417;
    } else {
      errorHandler(error);
    }
  }
};
