import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeLayout from '../_layouts/home';

export default function Home() {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <HomeLayout>
      <div style={{paddingTop: '24px'}}><span>{t('screens:home.companyInformation')}</span></div>
    </HomeLayout>
  );
}
