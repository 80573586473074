import React, { useEffect, useState } from 'react';
import DefaultLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Row, Col } from 'antd';
import { BreadcrumbCustom } from '~/styles/global';
import { findUser } from '~/services/hooks/users';
// import Row from '~/components/Row';
import { isEmptyArray } from 'formik';
import { useSelector } from 'react-redux';
import SellsAdmin from './charts/sellsAdmin';
import UsersAdmin from './charts/usersAdmin';
import SellsUser from './charts/sellsUser';
import TotalCreditsAdmin from './charts/totalCreditsAdmin';

export default function Dashboard() {
  const { t } = useTranslation();
  const type = useSelector(state => state?.user?.profile?.type);
  const [record, setRecord] = useState({ credits: 0 });
  console.log('TYPE DASHBOARD/', type);
  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:main')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  const getRecord = async () => {
    let data = await findUser();
    const x = {};
    if (data?.type === undefined) {
      data = data.me.original;
    }

    x.type = data?.type;
    if (typeof x.type !== 'undefined') {
      if (type === 'CLIENT') {
        x.credits = data?.calculo_dias['totals']?.creditsRemainAllPackages || 0;
        x.expire = [];
        for (let i = 0; i < data.calculo_dias.length - 1; i++) {
          if (data.calculo_dias[i].remainDays <= 7) {
            x.expire.push(data.calculo_dias[i]);
            break;
          }
        }
        setRecord(x);
      }
    }
  };

  useEffect(() => {
    getRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <Box>
        <PageTitle style={{ marginBottom: 0 }} title={t('menus:dashboard')} />
        {type === 'ADMIN' ? (
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <UsersAdmin />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ borderLeft: '1px solid #efefef', height: '100%' }}>
              <SellsAdmin />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ borderLeft: '1px solid #efefef', height: '100%' }}>
              <TotalCreditsAdmin />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <div style={{ marginLeft: '1%' }}>
                <PageTitle size={2} title={`Seus créditos: ${record.credits}`} />
              </div>
            </Row>
            <Row>
              {record.expire && !isEmptyArray(record.expire) && (
                <>
                  <PageTitle size={2} title="Créditos a expirar:" />{' '}
                  {record.expire &&
                    record.expire.map(item => (
                      <p key={item.packageName}>
                        <b style={{ marginRight: '30px' }}>{item.packageName}</b>
                        {item.creditsRemain} créditos em {item.remainDays} dias
                      </p>
                    ))}
                </>
              )}
            </Row>
            <Row>{type !== undefined && <SellsUser />}</Row>
          </>
        )}
      </Box>
      <div style={{ overflow: 'hidden' }}>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: '#cfe2cd', width: '100%', height: 105, transform: 'rotate(180deg)' }}
        >
          <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />
        </svg>
      </div>
    </DefaultLayout>
  );
}
