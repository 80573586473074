import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import SideMenu from '~/pages/_partials/SideMenu';
import Footer from '~/pages/_partials/Footer';
import { Container, EnvironmentHeader } from './styles';
import Header from '~/pages/_partials/Header';
import Logo from '~/components/Logo';
import SmallMenu from '~/pages/_partials/SmallMenu';
import HeaderMenu from '~/pages/_partials/Header/HeaderMenu';
import { countNotViewed } from '~/services/hooks/notifications';
import { useTranslation } from 'react-i18next';
import { envType } from '~/services/hooks/env';

const { Sider, Content } = Layout;

export default function DefaultLayout({ breadcrumb, children }) {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [countNotifications, setCountNotifications] = useState(0);
  const [enviroment, setEnviroment] = useState('');

  const sizeOfThings = () => {
    var windowWidth = window.visualViewport.width;
    setWindowWidth(windowWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', sizeOfThings); // Adiciona um listener para redimensionamento da tela
    return () => {
      window.removeEventListener('resize', sizeOfThings); // Remove o listener quando o componente é desmontado
    };
  }, []);

  async function getCountNotifications() {
    const data = countNotViewed(0);
    let promise = Promise.resolve(data);
    promise.then(function (val) {
      setCountNotifications(val);
    });
  }

  const fetchEnv = async () => {
    let data = await envType();
    setEnviroment(data);
  };

  useEffect(() => {
    sizeOfThings(); // Chama a função para definir a largura inicialmente
    getCountNotifications();
    fetchEnv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getCountNotifications();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  return (
    <Container id="fullLayout" className="layout">
      <link rel="manifest" href="/manifest.json" />
      {windowWidth < 700 ? (
        <>
          {enviroment.env !== 'prod' &&
            enviroment.env !== '' &&
            enviroment.env !== null &&
            enviroment.env !== undefined && (
              <EnvironmentHeader>
                <h2>{t('messages:envHeader')}</h2>
              </EnvironmentHeader>
            )}
          <Header isMobile>
            <Logo logoName="logo.png" width="auto" height="45" />
            <SmallMenu countNotifications={countNotifications} onView={() => getCountNotifications()} />
          </Header>
          <Layout style={{ padding: '0 24px 24px' }}>
            {breadcrumb}
            <Content className="site-layout-background" style={{ padding: 0, margin: 0, minHeight: 280 }}>
              {children}
            </Content>
            <Footer isMobile />
          </Layout>
        </>
      ) : (
        <>
          {enviroment.env !== 'prod' &&
            enviroment.env !== '' &&
            enviroment.env !== null &&
            enviroment.env !== undefined && (
              <EnvironmentHeader>
                <h1>{t('messages:envHeader')}</h1>
              </EnvironmentHeader>
            )}
          <Header>
            <Logo logoName="logo.png" width="auto" height="50" />
            <HeaderMenu countNotifications={countNotifications} onView={() => getCountNotifications()} />
          </Header>
          <Layout>
            <Sider
              theme="light"
              collapsible
              collapsed={collapsed}
              onCollapse={onCollapse}
              width={200}
              className="site-layout-background"
            >
              <div className="logo" />
              <SideMenu />
            </Sider>
            <Layout style={{ padding: '0 24px 24px' }}>
              {breadcrumb}
              <Content className="site-layout-background" style={{ padding: 0, margin: 0, minHeight: 280 }}>
                {children}
              </Content>
              <Footer />
            </Layout>
          </Layout>
        </>
      )}
    </Container>
  );
}

DefaultLayout.propTypes = {
  breadcrumb: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
