import styled from 'styled-components';
import { Form } from 'formik-antd';
import { Button } from 'antd';

export const ButtonAntd = styled(Button)`
  color: ${props => props.theme.color2};
  width: 0;
`;

export const Terms = styled.a`
  color: #3d3d3d;
  text-decoration: underline;

  :hover { 
    color: #${props => props.theme.color2};
    text-decoration: underline;
  }
`;

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .form-control {
    margin-bottom: 15px;
    
    .error-label {
      display: flex;
      justify-content: start;
      width: 100%;
      color: ${props => props.theme.colors.error.labelColor};
    }

    .ant-input:hover,
    .ant-input:not(.ant-input-disabled):hover,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:hover,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
      border-color: ${props => props.theme.colors.primary.hover.borderColor};
    }

    .ant-input-focused, .ant-input:focus,
    .ant-select-focused, ant-select:focus,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:focus {
      border-color: ${props => props.theme.colors.primary.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.focus.boxShadow};
    }
  }

  .form-control.error > input.ant-input,
  .form-control.error > .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .form-control.error > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector {
    border-color: ${props => props.theme.colors.error.borderColor};
  }

  .form-control.error > input.ant-input:hover,
  .form-control.error > input.ant-input:not(.ant-input-disabled):hover,
  .form-control.error > .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
  .form-control.error > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:hover {
    border-color: ${props => props.theme.colors.error.hover.borderColor};
  }

  .form-control.error > input.ant-input-focused,
  .form-control.error > input.ant-input:focus,
  .form-control.error > .ant-select-focused, ant-select:focus,
  .form-control.error > .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
  .form-control.error > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:focus {
    border-color: ${props => props.theme.colors.error.focus.borderColor};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.error.focus.boxShadow};
  }
  
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: ${props => props.theme.colors.primary.hover.borderColor};
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.primary.checked.bg};
    border-color: ${props => props.theme.colors.primary.checked.borderColor};
  }

  .ant-checkbox-checked:after {
    border: 1px solid ${props => props.theme.colors.primary.checked.after};
  }

  .ant-input-affix-wrapper-sm {
    background: #fafafa;
  }

  input {
    background: #fafafa;
    height: 32px;
    padding: 0 15px;
    color: #000;

    &::placeholder {
      color: #a1a1a1;
    }
  }

   #btnsubmit {
    margin-top: 15px;
    margin-bottom: 30px;
    width: 100px;
  }

  #btnlogin {
    padding-left: 0px;
    text-align: left;
  }

  #btnforgotpassword {
    padding-left: 0px;
    text-align: left;
  }
`;

export const Box = styled.div`
  max-width: 600px;
  margin: auto;
  margin-bottom: 0%;
  background: #fff;
  border-radius: 5px;
  padding: 0 20px 20px;
`;

export const Copyright = styled.div`
  margin-top: 16px;
  margin-bottom: auto;
  color: #000;
  text-align: center;
  font-size: 12px;
`;
