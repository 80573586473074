import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Col, Breadcrumb, Space, Switch } from 'antd';
import UserForm from './form';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import UserInfo from '~/components/UserInfo';
import { Table, TableActions } from '~/components/Table';
import { BreadcrumbCustom } from '~/styles/global';
import Filter from '../Users/filter';
import { deleteUser, paginationUsers, updateUser } from '~/services/hooks/users';
import { Email, Name, Type } from './styles';
import UserAvatar from '~/components/UserAvatar';

export default function Users() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [filters, setFilters] = useState({type:"ADMIN"});

  const fetchData = async page => {
    setLoading(true);

    const data = await paginationUsers(page, filters);
    setMeta(data.meta);
    setTableData(data.data);
    setLoading(false);
  };

  const handleDelete = async id => {
    await deleteUser(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const handleTableChange = sorter => {
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    if (sorter.field !== sortBy || sortOrder !== sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  const handlePause = async record => {
    setLoading(true);
    let active = !record.active;
    record.active = active ? 1 : 0;

    const userUpdate = {
      id: record.id,
      name: record.name,
      email: record.email,
      active: record.active,
      type: record.type,
      user_type: record.user_type,
    };

    const data = await updateUser(userUpdate);
    onUpdateItem(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortBy]);

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    active: { backgroundColor: '#52c41a' },
    inactive: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
    colAvatar: { paddingRight: '0px', width: '20px' },
    colData: { width: '180px', paddingLeft: '26px' },
    switch: { marginTop: '8px' }
  };

  const tableColumns = [
    {
      title: t('screens:users.data.user'),
      key: 'user',
      align: 'left',
      ellipsis: { showTitle: true },
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: record => {
        return <UserInfo user={record} />;
      },
    },
    {
      title: t('screens:users.data.actions'),
      key: 'actions',
      align: 'center',
      width: '110px',
      responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
      render: (text, record) => (
        <TableActions>
          <Switch size="small" style={style.switch} checked={record.active} onChange={() => handlePause(record)} />

          <Button style={style.tableAction} onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>

          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button style={style.tableAction} title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
    {
      // Mobile',
      dataIndex: 'name',
      align: 'left',
      key: 'name',
      responsive: ['xs'],
      render: (text, record) => (
        <div>
          <Row key={record.id} wrap={false} type="flex">
            <Col style={style.colAvatar}>
              <UserAvatar user={record} size="default" />
            </Col>
            <Col style={style.colData}>
              <Space size={0.5} direction="vertical">
                <Name ellipsis={true} strong>
                  {record?.name}
                </Name>
                <Type ellipsis={true} type="secondary">
                  {t('screens:users.data.type.' + record?.type)}
                </Type>
              </Space>
            </Col>
          </Row>
          <span>
            <MailOutlined style={{ color: 'gray' }} />
            <Email>{record.email}</Email>
          </span>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              <Switch size="small" style={style.switch} checked={record.active} onChange={() => handlePause(record)} />

              <Button style={style.tableAction} onClick={() => handleEdit(record)} title={t('messages:edit')}>
                <EditOutlined />
              </Button>

              <Popconfirm
                icon={false}
                title={t('messages:confirmDelete')}
                onConfirm={() => handleDelete(record.id)}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button style={style.tableAction} title={t('messages:delete')}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </TableActions>
          </div>
        </div>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:users')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('screens:users.title')} subtitle="Administradores do sistema" style={style.pageTitle} />
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:users.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}
        />

        {meta && (
          <Pagination
            onChange={value => {
              fetchData(value);
            }}
            meta={meta}
          />
        )}
        <UserForm
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </DefautLayout>
  );
}
