import styled from 'styled-components';
import { Form } from 'formik-antd';

export const FormFilter = styled(Form)`
  .form-control {
    .ant-input:hover,
    .ant-input:not(.ant-input-disabled):hover,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:hover {
      border-color: ${props => props.theme.colors.primary.hover.borderColor};
    }

    .ant-input-focused, .ant-input:focus,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:focus {
      border-color: ${props => props.theme.colors.primary.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.focus.boxShadow};
    }
  }
`;

export const Container = styled(Form)`
  .form-control { 
    
    .error-label {
      display: flex;
      justify-content: start;
      width: 100%;
      color: ${props => props.theme.colors.error.labelColor};
    }

    .ant-input:hover,
    .ant-input:not(.ant-input-disabled):hover {
      border-color: ${props => props.theme.colors.primary.hover.borderColor};
    }

    .ant-input-focused, .ant-input:focus {
      border-color: ${props => props.theme.colors.primary.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.focus.boxShadow};
    }
  }

  .form-control.error > input.ant-input {
    border-color: ${props => props.theme.colors.error.borderColor};
  }

  .form-control.error > input.ant-input:hover,
  .form-control.error > input.ant-input:not(.ant-input-disabled):hover {
      border-color: ${props => props.theme.colors.error.hover.borderColor};
  }

  .form-control.error > input.ant-input-focused,
  .form-control.error > input.ant-input:focus {
      border-color: ${props => props.theme.colors.error.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.error.focus.boxShadow};
  }
`;
