import { Badge, Typography } from 'antd';
import styled from 'styled-components';
import { Form } from 'formik-antd';

export const CustomAvatar = styled.ul`
  li {
    display: inline-block;
  }
`;

export const Name = styled(Typography.Text)`
  width: 180px;
`;

export const Type = styled(Typography.Text)`
  width: 180px;
  white-space: nowrap;
  font-size: 12px;
  position: relative;
  top: -7px;
`;

export const Email = styled.span`
  margin-left: 8px;
`;

export const BadgeStatus = styled(Badge)`
  .ant-badge-status-dot {
    width: 14px;
    height: 14px;
  }
`;

export const FormFilter = styled(Form)`
  .form-control {
    .ant-input:hover,
    .ant-input:not(.ant-input-disabled):hover,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:hover {
      border-color: ${props => props.theme.colors.primary.hover.borderColor};
    }

    .ant-input-focused, .ant-input:focus,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:focus {
      border-color: ${props => props.theme.colors.primary.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.focus.boxShadow};
    }
  }
`;

export const Container = styled(Form)`
  .form-control {
    
    .error-label {
      display: flex;
      justify-content: start;
      width: 100%;
      color: ${props => props.theme.colors.error.labelColor};
    }

    .ant-input:hover,
    .ant-input:not(.ant-input-disabled):hover,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:hover {
      border-color: ${props => props.theme.colors.primary.hover.borderColor};
    }

    .ant-input-focused, .ant-input:focus,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:focus {
      border-color: ${props => props.theme.colors.primary.focus.borderColor};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.focus.boxShadow};
    }
  }

  .form-control.error > .ant-input,
  .form-control.error > .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .form-control.error > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector {
    border-color: ${props => props.theme.colors.error.borderColor};
  }

  .form-control.error > .ant-input:hover,
  .form-control.error > .ant-input:not(.ant-input-disabled):hover,
  .form-control.error > .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
  .form-control.error > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:hover {
    border-color: ${props => props.theme.colors.error.hover.borderColor};
  }

  .form-control.error > .ant-input-focused,
  .form-control.error > .ant-input:focus,
  .form-control.error > .ant-select-focused, ant-select:focus,
  .form-control.error > .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
  .form-control.error > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector:focus {
    border-color: ${props => props.theme.colors.error.focus.borderColor};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.error.focus.boxShadow};
  }
`;
