import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Breadcrumb, Spin } from 'antd';
import DefaultLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Row from '~/components/Row';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import { BreadcrumbCustom } from '~/styles/global';
import { Formik } from 'formik';
import FormControl from '~/components/Form/FormControl';
import { Input } from 'formik-antd';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '~/services/hooks/users';
import { updateProfile } from '~/store/modules/user/actions';
import { Container } from './styles';

export default function Password() {
  const { t } = useTranslation();
  const { profile } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [recordData, setRecordData] = useState(profile);

  const handleSave = async values => {
    setLoading(true);
    values.oldPassword = values.password;

    values.newPassword = values.newPassword.length > 0 ? values.newPassword : values.password;

    await updatePassword(values);

    dispatch(updateProfile(values));

    setLoading(false);
  };

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    tableAction: { padding: '0 5px' },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:profile')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:password')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  const userSchema = Yup.object().shape({
    password: Yup.string(),
    newPassword: Yup.string()
      // .oneOf([Yup.ref('password'), null], t('differentPasswords'))
      .required(),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('differentPasswords'))
      .required(),
  });

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:personal')} />
      <Box>
        <Row>
          <Col span={24} style={style.btnNew.col}></Col>
        </Row>
        <Formik initialValues={recordData} enableReinitialize validationSchema={userSchema}>
          {({ errors, isSubmitting, touched, values }) => (
            <Spin spinning={loading || isSubmitting}>
              <Container>
                <Row>
                  <FormControl
                    cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                    field="password"
                    label={t('screens:users.data.password')}
                    required
                    error={(touched.password && errors.password) || errors.password}
                  >
                    <Input name="password" type="password" />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                    field="newPassword"
                    label={t('screens:users.data.newPassword')}
                    required
                    error={(touched.newPassword && errors.newPassword) || errors.newPassword}
                  >
                    <Input name="newPassword" type="password" />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    cols={{ xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }}
                    field="password_confirmation"
                    label={t('screens:users.data.newPasswordConfirmation')}
                    required
                    error={
                      (touched.password_confirmation && errors.password_confirmation) || errors.password_confirmation
                    }
                  >
                    <Input name="password_confirmation" type="password" />
                  </FormControl>
                </Row>
                <Button
                  loading={loading}
                  onClick={() => {
                    handleSave(values);
                  }}
                  color="primary"
                  type="submit"
                  style={style.btnNew.btn}
                >
                  <PlusOutlined />
                  {t('screens:profile.btnEdit')}
                </Button>
              </Container>
            </Spin>
          )}
        </Formik>
      </Box>
    </DefaultLayout>
  );
}
