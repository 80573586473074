import React, { useState, useEffect } from 'react';
import { Formik, isEmptyArray } from 'formik';
import { Checkbox, Input, Radio, Select } from 'formik-antd';
import { Spin, Col, message, Layout, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import cities from '~/data/cities.json';
import { Container, Box, Copyright, ButtonAntd, Terms } from './styles';
import Button from '~/components/Button';
import Logo from '~/components/Logo';
import { CopyrightOutlined } from '@ant-design/icons';
import FooterCustom from '~/components/Footer';
import cep from 'cep-promise';
import { createLoginUser } from '~/services/hooks/users';
import ReactInputMask from 'react-input-mask';
import TelefoneBrasileiroInput from 'react-telefone-brasileiro';
import { validateCnpj, validateCpf, validatePhone } from '~/Utils';
import history from '~/services/history';

const initValues = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  newPassword: '',
  password_confirmation: '',
  newPasswordConfirmReq: false,
  active: true,
  type: 'CLIENT',
  user_type: 'pf',
};

export default function SignUp() {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initValues);
  const [loading, setLoading] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [states, setStates] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [terms_accepted, setTerms_accepted] = useState(false);

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    let password = values.newPassword.length > 0 ? values.newPassword : values.password;

    if (values.user_type === 'pf' ? validateCpf(values.cpf) : validateCnpj(values.cnpj)) {
      if (validatePhone(telefone)) {
        let newClient = {
          id: null,
          name: values.name,
          email: values.email,
          password: password,
          active: true,
          type: 'CLIENT',
          user_type: values.user_type,
          terms_accepted: terms_accepted,
          cpf: values.cpf,
          cnpj: values.cnpj,
          address: {
            endereco: values.address,
            cep: values.cep,
            bairro: values.neighborhood,
            numero: values.address_number,
            cidade: values.city,
            estado: values.state,
            telefone: telefone,
          },
        };

        await createLoginUser(newClient);
        history.push(`/`);
        setLoading(false);
      } else {
        setErrors({ telephone: 'Telefone é inválido!' });
        setLoading(false);
        return;
      }
    } else {
      setErrors(values.user_type === 'pf' ? { cpf: 'CPF inválido!' } : { cnpj: 'CNPJ inválido!' });
      setLoading(false);
      return;
    }
  };

  const handleFindAddress = async (value, values) => {
    setLoading(true);
    try {
      const zipcode = value.replace(/[^0-9,]*/g, '');
      const address = await cep(zipcode);
      setRecordData({
        ...values,
        cep: zipcode,
        city: address.city,
        state: address.state,
        neighborhood: address.neighborhood,
        address: address.street,
      });
    } catch (error) {
      message.error('Endereço não localizado!');
    }
    setLoading(false);
  };

  const selecionaCidade = estado => {
    states.map(item => {
      if (item.sigla === estado) {
        setCityList(item.cidades);
      }
      return null;
    });
  };

  useEffect(() => {
    setLoading(true);
    setRecordData(initValues);
    setStates(cities.estados);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('differentPasswords'))
      .required(),
    user_type: Yup.string().required(),
    type: Yup.string().required(),
    active: Yup.bool().required(),
    cpf: Yup.string().when('user_type', { is: 'pf', then: Yup.string().required() }),
    cnpj: Yup.string().when('user_type', { is: 'pj', then: Yup.string().required() }),
    telephone: Yup.string().required(),
    cep: Yup.string().required(),
    neighborhood: Yup.string().required(),
    address: Yup.string().required(),
    address_number: Yup.string().required(),
    state: Yup.string().required(),
    city: Yup.string().required(),
    terms_accepted: Yup.bool()
      .oneOf([true], t('signUp.termsAndConditionsAccept'))
      .required(t('signUp.termsAndConditionsAccept')),
  });

  const currentYear = new Date().getFullYear();
  const myStyleLayout = { height: 'auto', background: '#e5e7e9' };

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={1} />
        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
          <Box>
            <Formik
              enableReinitialize
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={recordData}
              onSubmit={handleSave}
              validationSchema={userSchema}
            >
              {({ errors, isSubmitting, touched, setFieldValue, values }) => (
                <Spin spinning={loading || isSubmitting}>
                  <Container>
                    <Logo title={false} width="100%" height="100%" margin="auto" marginTop="40px" marginBottom="30px" />
                    <br />
                    <Title level={4} style={{ color: '#4b4b4b' }}>
                      {t('screens:signUp.personalInfo')}
                    </Title>
                    <Row>
                      <Radio.Group
                        name="user_type"
                        onChange={() => {}}
                        style={{
                          marginBottom: 16,
                        }}
                      >
                        <Radio.Button value="pf">Física</Radio.Button>
                        <Radio.Button value="pj">Jurídica</Radio.Button>
                      </Radio.Group>
                    </Row>
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}
                        field="name"
                        label={
                          values.user_type === 'pf'
                            ? t('screens:signUp.data.name')
                            : t('screens:signUp.data.companyName')
                        }
                        required
                        error={(touched.name && errors.name) || errors.name}
                      >
                        <Input name="name" />
                      </FormControl>
                      {values.user_type === 'pf' ? (
                        <FormControl
                          cols={{ xs: 13, sm: 9, md: 8, lg: 8, xl: 8 }}
                          field="cpf"
                          required
                          error={(touched.cpf && errors.cpf) || errors.cpf}
                          label={t('screens:signUp.data.cpf')}
                        >
                          <ReactInputMask
                            className="ant-input"
                            mask={'999.999.999-99'}
                            name="cpf"
                            value={values.cpf}
                            onChange={e => {
                              const formattedCpf = e.target.value.replace(/\D/g, '');
                              setFieldValue('cpf', formattedCpf);
                            }}
                            placeholder={'___.___.___-__'}
                            id="cpf"
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          cols={{ xs: 13, sm: 9, md: 8, lg: 8, xl: 8 }}
                          field="cnpj"
                          required
                          error={(touched.cnpj && errors.cnpj) || errors.cnpj}
                          label={t('screens:signUp.data.cnpj')}
                        >
                          <ReactInputMask
                            className="ant-input"
                            mask={'99.999.999/9999-99'}
                            name="cnpj"
                            value={values.cnpj}
                            onChange={e => {
                              const formattedcnpj = e.target.value.replace(/\D/g, '');
                              setFieldValue('cnpj', formattedcnpj);
                            }}
                            placeholder={'__.___.___/____-__'}
                            id="cnpj"
                          />
                        </FormControl>
                      )}

                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}
                        field="email"
                        label={t('screens:signUp.data.email')}
                        required
                        error={(touched.email && errors.email) || errors.email}
                      >
                        <Input type="email" name="email" />
                      </FormControl>
                      <FormControl
                        required
                        cols={{ xs: 13, sm: 9, md: 8, lg: 8, xl: 8 }}
                        field="telephone"
                        error={(touched.telephone && errors.telephone) || errors.telephone}
                        label={t('screens:signUp.data.telephone')}
                      >
                        <TelefoneBrasileiroInput
                          temDDD
                          value={telefone}
                          onChange={e => {
                            setFieldValue('telephone', e.target.value);
                            setTelefone(e.target.value);
                          }}
                          className="ant-input"
                        />
                      </FormControl>
                    </Row>
                    <Title level={4} style={{ color: '#4b4b4b' }}>
                      {t('screens:signUp.data.address')}
                    </Title>
                    <Row>
                      <FormControl
                        required
                        cols={{ xs: 13, sm: 7, md: 6, lg: 6, xl: 6 }}
                        field="cep"
                        error={(touched.cep && errors.cep) || errors.cep}
                        label={t('screens:signUp.data.cep')}
                      >
                        <ReactInputMask
                          className="ant-input"
                          mask="99.999-999"
                          name="cep"
                          value={values.cep}
                          onChange={e => setFieldValue('cep', e.target.value)}
                          onBlur={e => {
                            handleFindAddress(e.target.value, values);
                          }}
                          placeholder="__.___-___"
                          id="cep"
                        />
                      </FormControl>
                      <FormControl
                        required
                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                        field="address"
                        error={(touched.address && errors.address) || errors.address}
                        label={t('screens:signUp.data.addressLine')}
                      >
                        <Input name="address" style={{ textTransform: 'uppercase' }} value={values.address} />
                      </FormControl>
                      <FormControl
                        required
                        cols={{ xs: 24, sm: 17, md: 18, lg: 18, xl: 18 }}
                        field="neighborhood"
                        error={(touched.neighborhood && errors.neighborhood) || errors.neighborhood}
                        label={t('screens:signUp.data.neighborhood')}
                      >
                        <Input name="neighborhood" value={values.neighborhood} />
                      </FormControl>
                      <FormControl
                        required
                        cols={{ xs: 13, sm: 7, md: 6, lg: 6, xl: 6 }}
                        field="address_number"
                        error={errors.address_number}
                        label={t('screens:signUp.data.number')}
                      >
                        <Input name="address_number" type="number" style={{ textTransform: 'uppercase' }} />
                      </FormControl>
                      <FormControl
                        required
                        cols={{ xs: 24, sm: 13, md: 12, lg: 12, xl: 12 }}
                        field="city"
                        error={(touched.city && errors.city) || errors.city}
                        label={t('screens:signUp.data.city')}
                      >
                        <Select id="city" name="city" component="select" disabled={isEmptyArray(cityList)}>
                          {cityList.map(item => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        required
                        cols={{ xs: 24, sm: 11, md: 12, lg: 12, xl: 12 }}
                        field="state"
                        error={(touched.state && errors.state) || errors.state}
                        label={t('screens:signUp.data.state')}
                      >
                        <Select id="state" name="state" onChange={selecionaCidade}>
                          {states &&
                            states.map(item => (
                              <Select.Option key={item.sigla} value={item.sigla}>
                                {item.nome}
                              </Select.Option>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }}
                        field="password"
                        label={t('screens:signUp.data.password')}
                        required
                        error={(touched.password && errors.password) || errors.password}
                      >
                        <Input name="password" type="password" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }}
                        field="confirmPassword"
                        label={t('screens:signUp.data.confirmPassword')}
                        required
                        error={(touched.confirmPassword && errors.confirmPassword) || errors.confirmPassword}
                      >
                        <Input name="confirmPassword" type="password" />
                      </FormControl>

                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                        field="terms_accepted"
                        required
                        error={(touched.terms_accepted && errors.terms_accepted) || errors.terms_accepted}
                      >
                        {t('screens:signUp.data.checkbox')}
                        <Checkbox
                          id="terms_accepted"
                          checked={terms_accepted}
                          onChange={e => {
                            setFieldValue('terms_accepted', e.target.checked);
                            setTerms_accepted(e.target.checked);
                          }}
                        >
                          {t('screens:signUp.data.iAcceptThe')}{' '}
                          <Terms
                            href="/assets/terms/terms-and-conditions.pdf"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {t('screens:signUp.data.termsAndConditions')}
                          </Terms>{' '}
                          {t('screens:signUp.data.website')}
                        </Checkbox>
                      </FormControl>
                    </Row>
                    <Button
                      id="btnsubmit"
                      size="middle"
                      block
                      type="submit"
                      loading={loading}
                      color="primary"
                      disabled={!terms_accepted}
                    >
                      {t('screens:signUp.submit')}
                    </Button>
                    <ButtonAntd id="btnlogin" type="link" onClick={() => history.push(`/`)}>
                      {t('screens:signUp.login')}
                    </ButtonAntd>
                    <ButtonAntd id="btnforgotpassword" type="link" onClick={() => history.push(`/password/link`)}>
                      {t('screens:signUp.forgotPassword')}
                    </ButtonAntd>
                  </Container>
                </Spin>
              )}
            </Formik>
          </Box>
        </Col>
      </Row>
      <Copyright>
        {` `}
        <span>{t('fields:copyright.copyright')}</span>
        {` `}
        <CopyrightOutlined />
        {` ${currentYear} | `}
        <span>{t('fields:copyright.produced')}</span>
      </Copyright>
      <FooterCustom />
    </Layout>
  );
}
