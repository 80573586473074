import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';
import Login from '~/pages/Login';
import Remember from '~/pages/Password/Remember.js';
import Reset from '~/pages/Password/Reset.js';
import Dashboard from '~/pages/Dashboard';
import Users from '~/pages/Users';
import Consultas from '~/pages/Consultas';
import e404 from '~/pages/Errors/e404.js';
import Plans from '~/pages/Plans';
import Customers from '~/pages/Customers';
import ConsultasNew from '~/pages/Consultas/new';
import Finances from '~/pages/Finances';
import Details from '~/pages/Finances/details';
import Profile from '~/pages/Profile';
import Password from '~/pages/Profile/senha';
import Credits from '~/pages/Credits';
import CredDetails from '~/pages/Credits/details';
import CustomerDetails from '~/pages/Customers/details';
import CredMessage from '~/pages/Credits/message';
import Home from '~/pages/Home';
import SignUp from '~/pages/Login/SignUp';
import Welcome from '~/pages/Welcome';
import Coupons from '~/pages/Coupons';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/welcome" component={Welcome} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/" component={Login} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/password/link" component={Remember} />
      <Route exact path="/password/reset/:token" component={Reset} />

      {/*isPrivate*/}
      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/plans" component={Plans} isPrivate />
      <Route exact path="/customers" component={Customers} isPrivate />
      <Route exact path="/customers/:id" component={CustomerDetails} isPrivate />
      <Route exact path="/users" component={Users} isPrivate />
      <Route exact path="/consultas" component={Consultas} isPrivate />
      <Route exact path="/consultas/new" component={ConsultasNew} isPrivate />
      <Route exact path="/consultas/:id" component={Consultas} isPrivate />
      <Route exact path="/payments" component={Finances} isPrivate />
      <Route exact path="/credits" component={Credits} isPrivate />
      <Route exact path="/credits/message" component={CredMessage} isPrivate />
      <Route exact path="/credits/:id" component={CredDetails} isPrivate />
      <Route exact path="/coupons" component={Coupons} isPrivate />
      <Route exact path="/payments/:id" component={Details} isPrivate />
      <Route exact path="/payments-client/:name" component={Finances} isPrivate />
      <Route exact path="/profile" component={Profile} isPrivate />
      <Route exact path="/password" component={Password} isPrivate />
      <Route exact path="/404" component={e404} isFree />
      <Redirect to="/404" />
    </Switch>
  );
}
