import { takeLatest, call, put, all } from 'redux-saga/effects';
import { message } from 'antd';
import qs from 'qs';
import i18next from 'i18next';
import api from '~/services/api';
import history from '~/services/history';
import { signInSuccess, signFailure, signOutSuccess } from './actions';
import { updateProfile } from '~/store/modules/user/actions';

export function* signIn({ payload }) {
  try {
    var data = qs.stringify({
      grant_type: 'password',
      email: payload.username,
      password: payload.password,
    });

    var client_id = 'client';
    var client_secret = '123';
    var basicAuth = 'Basic ' + btoa(client_id + ':' + client_secret);

    const response = yield call(api.post, '/auth', data, { headers: { Authorization: basicAuth } });
    const { token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token));

    response.data.user.token = token;
    const { user } = response.data;

    yield put(updateProfile(user));

    history.push('/dashboard');
  } catch (error) {
    const { response } = error;
    const { status } = response;

    switch (status) {
      case 400:
        message.error(i18next.t('messages:authError'));
        break;
      case 401:
        message.error(i18next.t('messages:authPasswordError'));
        break;
      case 422:
        message.error(i18next.t('messages:authUserError'));
        break;
      default:
        break;
    }
    yield put(signFailure());
  }
}

export function* signOut() {
  try {
    yield call(api.post, 'auth/logout');
    localStorage.clear();  // clear all data from local storage

  } catch (err) {
    console.error('Erro ao fazer logout:', err);
  }

  yield put(signOutSuccess());
  history.push('/');
}

// export function* signOut() {
//   try {
//     yield call(api.post, 'auth/logout');
//   } catch (err) {}

//   yield put(signOutSuccess());
//   history.push('/');
// }

export function* tokenExpired() {
  try {
    yield call(api.post, 'auth/logout');
    localStorage.clear();  // clear all data from local storage

  } catch (err) {
    console.error('Erro ao fazer logout:', err);
  }

  yield put(signOutSuccess()); // call signOutSuccess action
  history.push('/');
  message.success('Sua sessão expirou! Faça o login novamente.');
}

function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT_REQUEST', signOut),
  takeLatest('@auth/TOKEN_EXPIRED', tokenExpired),
]);
